import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
// utils
import axios from '../../utils/axios';
import { EditBankType } from '../../@types/editBankType';


type EditBankState = {
    isLoading: boolean;
    error: boolean;
    editBank: EditBankType;
}

const initialState: EditBankState = {
    isLoading: false,
    error: false,
    editBank: {
        "senderBankId": 0,
        "senderId": 0,
        "senderSwiftCode": '',
        "senderSwiftBIC8Code": '',
        "senderBankIdentifier": "",
        "senderBankName": "",
        "senderBankCode": "",
        "senderBranchName": "",
        "senderBranchCode": "",
        "senderIBAN": "",
        "senderIFSC": "",
        "senderAccountNumber": "",
        "senderAccountType": "",
        "senderVirtualPaymentAccount": "",
        "createTime": new Date(),
        "updateTime": new Date(),
        "senderAdditional": [
            {
                "katakanaName": "",
                "senderAdditionalId": 0
            }
        ]

    },
}

const slice = createSlice({
    name: 'editBank',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        editBankSuccess(state, action) {
            state.isLoading = false;
            state.editBank = action.payload;
        },
    }
});

// Reducer
export default slice.reducer;

export function editBankInfo(userId: number, values: any) {
    return async () => {
        console.log(values);
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.put(`https://6jfjr9vffh.execute-api.ap-south-1.amazonaws.com/staging/mcus/bo/customer/${userId}/bank`,
                {
                    "senderSwiftCode": values.senderSwiftCode,
                    "senderSwiftBIC8Code": values.senderSwiftBIC8Code,
                    "senderBankIdentifier": values.senderBankIdentifier,
                    "senderBankName": values.senderBankName,
                    "senderBankCode": values.senderBankCode,
                    "senderBranchName": values.senderBranchName,
                    "senderBranchCode": values.senderBranchCode,
                    "senderIBAN": values.senderIBAN,
                    "senderIFSC": values.senderIFSC,
                    "senderAccountNumber": values.senderAccountNumber,
                    "senderAccountType": values.senderAccountType,
                    "senderVirtualPaymentAccount": values.senderVirtualPaymentAccount,
                    "senderAdditionalKey": values.senderAdditionalKey,
                    "senderAdditionalValue": values.senderAdditionalValue,
                }
            );
            dispatch(slice.actions.editBankSuccess(response.data.data));
        } catch (error :any) {
            dispatch(slice.actions.hasError(error));
            return {message : error?.message, hasError : true} 
        }
    };
}