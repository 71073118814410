import { useMemo, ReactNode } from "react";
// material
import { CssBaseline } from "@material-ui/core";
import {
	ThemeProvider,
	ThemeOptions,
	createTheme,
} from "@material-ui/core/styles";
import StyledEngineProvider from "@material-ui/core/StyledEngineProvider";
// hooks

//
import shape from "./shape";
import palette from "./palette";
import typography from "./typography";
import breakpoints from "./breakpoints";
import GlobalStyles from "./globalStyles";
import componentsOverride from "./overrides";
import shadows, { customShadows } from "./shadows";

// ----------------------------------------------------------------------

type ThemeConfigProps = {
	children: ReactNode;
};

export default function ThemeConfig({ children }: ThemeConfigProps) {
	const { themeMode, themeDirection } = {
		themeMode: "light",
		themeDirection: "ltr",
	};

	const isLight = themeMode === "light";

	const themeOptions: ThemeOptions = useMemo(
		() => ({
			palette: isLight
				? { ...palette.light, mode: "light" }
				: { ...palette.dark, mode: "dark" },
			shape,
			typography,
			breakpoints,
			direction: "ltr",
			shadows: isLight ? shadows.light : shadows.dark,
			customShadows: isLight ? customShadows.light : customShadows.dark,
		}),
		[isLight, themeDirection]
	);

	const theme = createTheme(themeOptions);
	console.log(theme);
	theme.components = componentsOverride(theme);

	return (
		<StyledEngineProvider injectFirst>
			<ThemeProvider theme={theme}>
				<CssBaseline />
				<GlobalStyles />
				{children}
			</ThemeProvider>
		</StyledEngineProvider>
	);
}
