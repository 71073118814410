import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
// utils
import axios from '../../utils/axios';
import { RateType } from '../../@types/rateType';

type RateState = {
    isLoading: boolean;
    error: boolean;
    rateJPY: RateType;
}

const initialState: RateState = {
    isLoading: false,
    error: false,
    rateJPY: {
        "baseCurrency": "",
        "date": "",
        "rates": {
          value: 0
        }
    },
}

const slice = createSlice({
    name: 'rateJPY',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        // GET RATE
        getRateJPYSuccess(state, action) {
            state.isLoading = false;
            state.rateJPY = action.payload;
        },
    }
});

// Reducer
export default slice.reducer;


export function getJPYRates() {
    var params = {
        "fromCurrency":'JPY',
      }
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
        const response = await axios.get(`https://6jfjr9vffh.execute-api.ap-south-1.amazonaws.com/staging/mcps/api/rates/system`,{params});
        dispatch(slice.actions.getRateJPYSuccess(response.data.data));
      } catch (error) {
        dispatch(slice.actions.hasError(error));
      }
    };
  }

