import { useTranslation } from "react-i18next";

// ----------------------------------------------------------------------

const LANGS = [
	{
		value: "en",
		label: "English",
		icon: "/static/flags/gbp.png",
	},
	{
		value: "de",
		label: "Japanese",
		icon: "/static/flags/jpy.png",
	},
	{
		value: "fr",
		label: "Chinese",
		icon: "/static/flags/cny.png",
	},
];

export default function useLocales() {
	const { i18n, t: translate } = useTranslation();
	console.log(i18n, translate);
	const langStorage = localStorage.getItem("i18nextLng");
	const currentLang =
		LANGS.find((_lang) => _lang.value === langStorage) || LANGS[1];

	const handleChangeLanguage = (newlang: string) => {
		i18n.changeLanguage(newlang);
	};

	return {
		onChangeLang: handleChangeLanguage,
		translate,
		currentLang,
		allLang: LANGS,
	};
}
