import { map, filter } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
// utils
import axios from '../../utils/axios';
import { routeSpecificReceiverData } from '../../@types/routeSpecificReceivers';
import URLConstants from 'constants/urlConstants';

type createRouteSpecificReceiverState = {
    isLoading: boolean;
    error: boolean;
    createRouteSpecificReceiver: routeSpecificReceiverData;
}

const initialState: createRouteSpecificReceiverState = {
    isLoading: false,
    error: false,
    createRouteSpecificReceiver: {
        "receiverId": 0,
        "senderId": 0,
        "routeId": 0,
        "receiverFirstName": "",
        "receiverMiddleName": null,
        "receiverLastName": "",
        "receiverNickName": null,
        "receiverDOB": null,
        "receiverGender": null,
        "receiverNationality": null,
        "enabled": true,
        "createTime": new Date(),
        "updateTime": new Date(),
        "receiverAddress": [],
        "receiverIdentificationDoc": [],
        "receiverContact": [],
        "receiverBank": [],
        "receiverBankAdditional": [],
    },
}

const slice = createSlice({
    name: 'createRouteSpecificReceiver',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        createRouteSpecificReceiverSuccess(state, action) {
            state.isLoading = false;
            state.createRouteSpecificReceiver = action.payload;
        },
    }
});

// Reducer
export default slice.reducer;

export function getCreateRouteSpecificReceiver(
    routeId: number,
    senderId: string,
    values: any,
    // receiverFirstName: string,
    // receiverLastName: string,
    // receiverAddressLine1: string,
    // receiverAddressLine2: string,
    // receiverCity: string,
    // receiverStateProvince: string,
    // receiverZipCode: string,
    // receiverCountry: string,
    // receiverIdType: string,
    // receiverIdNo: string,
    // receiverIdExpiry: string,
    // receiverIdCountry: string,
    // receiverPhoneNumber: string,
    // receiverEmail: string,
    // receiverSwiftCode: string,
    // receiverSwiftBIC8Code: string,
    // receiverBankIdentifier: string,
    // receiverBankName: string,
    // receiverBankCode: string,
    // receiverBranchName: string,
    // receiverBranchCode: string,
    // receiverIBAN: string,
    // receiverIFSC: string,
    // receiverAccountNumber: string,
    // receiverAccountType: string,
    // receiverVirtualPaymentAccount: string,
) {
    return async () => {
        console.log(routeId, senderId, values);

        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.post(`https://6jfjr9vffh.execute-api.ap-south-1.amazonaws.com/staging/mcus/users/me/sender/${senderId}/receiver`,
                {
                    "routeId": routeId,
                    "receiverFirstName": values.receiverFirstName,
                    "receiverLastName": values.receiverLastName,
                    "receiverAddressLine1": values.receiverAddressLine1,
                    "receiverAddressLine2": values.receiverAddressLine2,
                    "receiverCity": values.receiverCity,
                    "receiverStateProvince": values.receiverStateProvince,
                    "receiverZipCode": values.receiverZipCode,
                    "receiverCountry": values.receiverCountry,
                    "receiverIdType": values.receiverIdType,
                    "receiverIdNo": values.receiverIdNo,
                    "receiverIdExpiry": values.receiverIdExpiry,
                    "receiverIdCountry": values.receiverIdCountry,
                    "receiverPhoneNumber": values.receiverPhoneNumber ? values.receiverPhoneNumber : null,
                    "receiverEmail": values.email ? values.email : null,
                    "receiverSwiftCode": values.swiftCode ? values.swiftCode : null,
                    "receiverSwiftBIC8Code": values.swiftBIC8Code ? values.swiftBIC8Code : null,
                    "receiverBankIdentifier": values.bankIdentifier ? values.bankIdentifier : null,
                    "receiverBankName": values.bankName ? values.bankName : null,
                    "receiverBankCode": values.bankCode ? values.bankCode : null,
                    "receiverBranchName": values.receiverBranchName ? values.receivBranchName : null,
                    "receiverBranchCode": values.branchCode ? values.branchCode : null,
                    "receiverIBAN": values.IBAN ? values.IBAN : null,
                    "receiverIFSC": values.IFSCCode ? values.IFSCCode : null,
                    "receiverAccountNumber": values.accountNumber ? values.accountNumber : null,
                    "receiverAccountType": values.accountType ? values.accountType : null,
                    "receiverVirtualPaymentAccount": values.receiverVirtualPaymentAccount ? values.receiverVirtualPaymentAccount : null,
                    "receiverAdditional": [
                        // {
                        //     "receiverBankAdditionalKey": "additional key 1",
                        //     "receiverBankAdditionalValue": "value 1"
                        // },
                        // {
                        //     "receiverBankAdditionalKey": "additional key 2",
                        //     "receiverBankAdditionalValue": "value 2"
                        // }
                    ]
                }
            );
            dispatch(slice.actions.createRouteSpecificReceiverSuccess(response.data.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}