import { ValidationSchema } from 'contexts/SendMoneyContext'

export const initialValuesSendMoney: ValidationSchema = {
    sendAmount: {
        value: '',
        error: '',
        required: true,
        validate: 'number',
        minLength: 2,
        maxLength: 20,
        helperText: 'Please enter amount'
    },

    receiveAmount: {
        value: '',
        error: '',
        required: true,
        validate: 'number',
        minLength: 2,
        maxLength: 20,
        helperText: 'Custom error message'
    },
    sendCurrency: {
        value: '',
        error: '',
        required: true,
        validate: 'select',
        minLength: 2,
        maxLength: 20
    },
    receiveCurrency: {
        value: '',
        error: '',
        required: true,
        validate: 'select',
        minLength: 2,
        maxLength: 20
    },
    deliverTo: {
        value: '',
        error: '',
        required: true,
        validate: 'select',
        minLength: 2,
        maxLength: 20
    },
    payWith: {
        value: '',
        error: '',
        validate: 'select',
        required: true,
    },
    accountType: {
        value: '',
        error: '',
        validate: 'select',
        required: true,
    },

    //Receiverform

    receiverFirstName: {
        value: '',
        error: '',
        required: true,
        validate: 'text',
        minLength: 2,
        maxLength: 50,
     //   helperText: 'Please enter amount'
    },
    receiverMiddleName: {
        value: '',
        error: '',
        required: true,
        validate: 'text',
        minLength: 2,
        maxLength: 50,
     //   helperText: 'Please enter amount'
    },
    receiverLastName: {
        value: '',
        error: '',
        required: true,
        validate: 'text',
        minLength: 2,
        maxLength: 50,
     //   helperText: 'Please enter amount'
    },
    accountNumber: {
        value: '',
        error: '',
        required: true,
        validate: 'text',
        minLength: 14,
        maxLength: 25,
     //   helperText: 'Please enter amount'
    },
    IFSCCode: {
        value: '',
        error: '',
        required: true,
        validate: 'text',
     //   helperText: 'Please enter amount'
    },
    bankName: {
        value: '',
        error: '',
        required: true,
        validate: 'text',
     //   helperText: 'Please enter amount'
    },
    routeId: {
        value: '',
        error: '',
        required: true,
        validate: 'text',
    },
    receiverId: {
        value: '',
        error: '',
        required: true,
        validate: 'text',
    },
    bankCode: {
        value: '',
        error: '',
        required: true,
        validate: 'text',
    },
    branchCode: {
        value: '',
        error: '',
        required: true,
        validate: 'text',
    },
    branchName: {
        value: '',
        error: '',
        required: true,
        validate: 'text',
    },
    routingNumber: {
        value: '',
        error: '',
        required: true,
        validate: 'text',
    },
    swiftCode: {
        value: '',
        error: '',
        required: true,
        validate: 'text',
    },
    outboundCountryValue: {
        value: '',
        error: '',
        required: true,
        validate: 'text',
    },
    recvCountryValue: {
        value: '',
        error: '',
        required: true,
        validate: 'text',
    },
    purposeValue: {
        value: '',
        error: '',
        required: true,
        validate: 'text',
    },
    outboundQuoteId: {
        value: '',
        error: '',
        required: true,
        validate: 'text',
    },
    fee: {
        value: '',
        error: '',
        required: true,
        validate: 'text',
    },
    total: {
        value: '',
        error: '',
        required: true,
        validate: 'text',
    },
    sate: {
        value: '',
        error: '',
        required: true,
        validate: 'text',
    },
    benefIdNumber: {
        value: '',
        error: '',
        required: true,
        validate: 'text',
    },
    accountTypeValue: {
        value: '',
        error: '',
        required: true,
        validate: 'text',
    },

}
