import { map, filter } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
// utils
import axios from '../../utils/axios';
import { AllCustomers } from '../../@types/allCustomersType';
import URLConstants from 'constants/urlConstants';
import user from './user';

type AllCustomersState = {
    isLoading: boolean;
    error: boolean;
    allCustomers: AllCustomers[];
}

const initialState: AllCustomersState = {
    isLoading: false,
    error: false,
    allCustomers:[ 
        {
        "senderId": 0,
        "userId": 0,
        "senderFirstName": "",
        "senderMiddleName": '',
        "senderLastName": "",
        'senderEmail': "",
        'senderPhoneNumber': '',    
    }
]
}

const slice = createSlice({
    name: 'allCustomers',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        // GET CUSTOMER
        getAllCustomerSuccess(state, action) {
            state.isLoading = false;
            state.allCustomers = action.payload;
        },
    }
});

// Reducer
export default slice.reducer;


export function getAllCustomer(page:number) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(`https://6jfjr9vffh.execute-api.ap-south-1.amazonaws.com/staging/mcus/customers/all/${page}`);
            dispatch(slice.actions.getAllCustomerSuccess(response.data.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}
export function searchCustomers(userId : any,page:number, senderName :string,senderEmail :string ,senderPhoneNumber :string) {
   if (userId==''){
       userId=null
   }
    var params = {
        "userId":userId,
        'senderName':senderName,
        "senderEmail":senderEmail,
        "senderPhoneNumber":senderPhoneNumber
      }
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(`https://6jfjr9vffh.execute-api.ap-south-1.amazonaws.com/staging/mcus/customers/${page}`,{params});
            dispatch(slice.actions.getAllCustomerSuccess(response.data.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}
export function searchCustomerById(senderId : any,page:number) {
    var params = {
        "senderId":senderId,
        'senderName':'',
        "senderEmail":'',
        "senderPhoneNumber":''

      }
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(`https://6jfjr9vffh.execute-api.ap-south-1.amazonaws.com/staging/mcus/customers/${page}`,{params});
            dispatch(slice.actions.getAllCustomerSuccess(response.data.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function searchCustomerByEmail(senderId : any,page:number, senderEmail :string) {
    var params = {
        "senderId":senderId,
        'senderName':'',
        "senderEmail":senderEmail,
        "senderPhoneNumber":''

      }
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(`https://6jfjr9vffh.execute-api.ap-south-1.amazonaws.com/staging/mcus/customers/${page}`,{params});
            dispatch(slice.actions.getAllCustomerSuccess(response.data.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function searchCustomerByPhoneNumber(senderId : any,page:number, senderPhoneNumber :string) {
    var params = {
        "senderId":senderId,
        'senderName':'',
        "senderEmail":'',
        "senderPhoneNumber":senderPhoneNumber

      }
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(`https://6jfjr9vffh.execute-api.ap-south-1.amazonaws.com/staging/mcus/customers/${page}`,{params});
            dispatch(slice.actions.getAllCustomerSuccess(response.data.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}