
// material
import { experimentalStyled as styled } from "@material-ui/core/styles";
import {
	Card,
	Stack,
	Container,
	Grid,
} from "@material-ui/core";
// routes
import {PATH_DASHBOARD } from "../../routes/paths";
// hooks
import useAuth from "../../hooks/useAuth";
// layouts
// components
import Page from "../../components/Page";
import { MHidden } from "../../components/@material-extend";
import { ReceiverForm } from "./ReceiverForm";
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';


// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
	[theme.breakpoints.up("md")]: {
		display: "flex",
	},
}));

const SectionStyle = styled(Card)(({ theme }) => ({
	width: "100%",
	maxWidth: 464,
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
	//margin: theme.spacing(2, 0, 2, 2),
	backgroundColor: "#c8facd",
	boxShadow: "none",
	borderRadius: "0px",
}));

const ContentStyle = styled("div")(({ theme }) => ({
	maxWidth: 500,
	margin: "auto",
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
	padding: theme.spacing(12, 0),
}));


export default () => {


	const { method } = useAuth();
	return (

		<>
		  <Container><HeaderBreadcrumbs
			heading="Send Money"
			links={[
				{ name: 'Select Receiver', href: PATH_DASHBOARD.user.root },
				// { name: 'Delivery Options', href: PATH_DASHBOARD.user.root },
			]} />
			</Container>
			<RootStyle title="Send money ">
				<Container maxWidth="sm">

					<ContentStyle>
						<Stack direction="row" alignItems="center" sx={{ mb: 5 }}>
							<Grid item xs={12} md={6} lg={12}>
							<ReceiverForm newRecipientFormKey={0}/>
							</Grid>
						</Stack>
					</ContentStyle>
				</Container>
			</RootStyle>
            </>
	);
};
