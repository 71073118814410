import { map, filter } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
// utils
import axios from '../../utils/axios';
import { StateprovinceType } from '../../@types/stateProvinceType';

type StateprovinceState = {
    isLoading: boolean;
    error: boolean;
    stateProvinceBO: StateprovinceType[];
}

const initialState: StateprovinceState = {
    isLoading: false,
    error: false,
    stateProvinceBO: [],
}

const slice = createSlice({
    name: 'stateProvinceBO',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        // GET ROUTE STATE PROVINCE
        getStateprovinceBOSuccess(state, action) {
            state.isLoading = false;
            state.stateProvinceBO = action.payload;
        },
    }
});

// Reducer
export default slice.reducer;


export function getStateprovinceBO(page: number) {
    var params = {
        "noOfRecords":10,
      }
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(`https://6jfjr9vffh.execute-api.ap-south-1.amazonaws.com/staging/mtcs/bo/stateprovinces/${page}`,{params});
            dispatch(slice.actions.getStateprovinceBOSuccess(response.data.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}