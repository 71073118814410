// material
import {

    Card,
    Table,
    TableRow,
    TableBody,
    TableCell,
    TableHead,
    TableContainer,
    Container,
    Pagination,
    Grid,
    Box,
} from '@material-ui/core';
// utils
import { RootState, useDispatch, useSelector } from 'redux/store';
import { useEffect } from 'react';
import { getCountry } from 'redux/slices/country';
import Page from 'components/Page';
import HeaderBreadcrumbs from 'components/HeaderBreadcrumbs';
import { getStateprovinceBO } from 'redux/slices/routeStateProvinceBO';
import useLocales from 'hooks/useLocales';

// ----------------------------------------------------------------------

const style = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
    '& > *': { my: 1 }
} as const;

export default function StateProvince() {
    const { translate } = useLocales();
    const dispatch = useDispatch();
    const { stateProvinceBO } = useSelector(
        (state: RootState) => state.stateProvinceBO
    );


    useEffect(() => {
        let page = 1;
        dispatch(getStateprovinceBO(page));
    }, [dispatch]);

    const paginationFunction = (e: any, page: number) => {
        dispatch(getStateprovinceBO(page));
    }
    return (
        <>  <Page title="State province">
            <Container maxWidth="xl">

                <HeaderBreadcrumbs
                    heading={translate(`dashboard.${"State province"}`)}
                    links={[
                        // { name: 'Amount', href: PATH_DASHBOARD.user.root },
                        // { name: 'Delivery Options', href: PATH_DASHBOARD.user.root },
                    ]}
                />
                <Card sx={{ pb: 3 }}>
                    <TableContainer sx={{ minWidth: 720 }}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    {/* <TableCell>Route Id</TableCell> */}
                                    <TableCell>State province Id</TableCell>
                                    <TableCell>State province</TableCell>
                                    <TableCell>State province</TableCell>
                                </TableRow>
                            </TableHead>
                            {stateProvinceBO !== null &&
                                <TableBody>
                                    {stateProvinceBO.map((row) => (
                                        <TableRow key={row.routeStateProvinceId}>
                                            {/* <TableCell>{row.routeId}</TableCell> */}
                                            <TableCell>{row.routeStateProvinceId}</TableCell>
                                            <TableCell>{row.stateProvinceCode}</TableCell>
                                            <TableCell>{row.stateProvince}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>}
                        </Table>

                    </TableContainer>
                    <Grid item xs={12}>
                        <Box sx={style}>
                            <Pagination onChange={(e, page) => paginationFunction(e, page)} count={10} color="primary" />
                        </Box>
                    </Grid>
                </Card>
            </Container>
        </Page></>
    );
}