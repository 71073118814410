import { map, filter } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
// utils
import axios from '../../utils/axios';
import { routeSpecificReceiverData } from '../../@types/routeSpecificReceivers';
import URLConstants from 'constants/urlConstants';

type  RouteSpecificReceiverState = {
    isLoading: boolean;
    error: boolean;
    routeSpecificReceiver: routeSpecificReceiverData[];
}

const initialState: RouteSpecificReceiverState = {
    isLoading: false,
    error: false,
    routeSpecificReceiver: [],
}

const slice = createSlice({
    name: 'routeSpecificReceiver',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        // GET Route Specific Receiver list
        getRouteSpecificReceiverSuccess(state, action) {
            state.isLoading = false;
            state.routeSpecificReceiver = action.payload;
        },
    }
});

// Reducer
export default slice.reducer;


export function getRouteSpecificReceiver(routeId : string) {
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
        const response = await axios.get(`https://6jfjr9vffh.execute-api.ap-south-1.amazonaws.com/staging/mcus/users/me/route/${routeId}/receivers`);
        dispatch(slice.actions.getRouteSpecificReceiverSuccess(response.data.data));
      } catch (error) {
        dispatch(slice.actions.hasError(error));
      }
    };
  }

  