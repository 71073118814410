import { capitalCase } from "change-case";
import { Link as RouterLink } from "react-router-dom";
// material
import { alpha, experimentalStyled as styled } from "@material-ui/core/styles";
import {
    Box,
    Card,
    Stack,
    Link,
    Alert,
    Tooltip,
    Container,
    Typography,
    Grid,
    Tab,
    Avatar,
    CardContent
} from "@material-ui/core";
// routes
import { PATH_AUTH, PATH_DASHBOARD } from "../../routes/paths";
// hooks
import useAuth from "../../hooks/useAuth";
// layouts
import AuthLayout from "../../layouts/AuthLayout";
// components
import Page from "../../components/Page";
import { MHidden } from "../../components/@material-extend";

import CountryServices from "services/CountryServices";
import React, { useState } from "react";
import axios from "utils/axios";
import URLConstants from "constants/urlConstants";
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import { TabContext, TabList, TabPanel } from '@material-ui/lab';
import PhoneIcon from '@material-ui/icons/Phone';
import FavoriteIcon from '@material-ui/icons/Favorite';
import PersonPinIcon from '@material-ui/icons/PersonPin';
import { Block } from 'components/Block';
import AddIcon from '@material-ui/icons/Add';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ShouldArrive from "pages/Send-Money/ShouldArrive";
import { LoadingButton } from "@material-ui/lab";
import {useEffect} from "react";

// ----------------------------------------------------------------------
const style = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
    '& > *': { mx: '8px !important' }
} as const;

const RootStyle = styled(Page)(({ theme }) => ({
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(15)
}));

const PERCENT = 2.6;




const RowStyle = styled('div')({
    display: 'flex',
    justifyContent: 'space-between'
});

const SIMPLE_TAB = [
    { value: '1', icon: <PhoneIcon />, label: 'Item One', disabled: false },
    { value: '2', icon: <FavoriteIcon />, label: 'Item Two', disabled: false },
    { value: '3', icon: <PersonPinIcon />, label: 'Item Three', disabled: true }
];
        

export default () => {
    const [value, setValue] = useState('1');

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };
    return (

        <>
           
            <Page title="Privacy and policy">
            
                    
            <Container maxWidth="xl">
            <Grid item >
            
                    <HeaderBreadcrumbs
                        heading="Privacy and policy"
                        links={[
                            //{ name: 'Amount', href: PATH_DASHBOARD.user.root },
                            //{ name: 'Delivery Options', href: PATH_DASHBOARD.user.root },
                        ]} 
                    />
             <Card sx={{ p: 33, overflow: 'visible' }}>   
             <Typography align="justify" mt={-20} ml={-20} mr={-20}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas dictum lacinia sodales. 
             Duis in est leo. Ut eleifend magna et dictum suscipit. Aliquam faucibus condimentum nisl in maximus. Vivamus ac risus non dolor venenatis gravida. Sed eu ex a ligula suscipit tempus ac id magna. Donec vitae urna id nisl varius tincidunt. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Maecenas rhoncus justo at lorem lacinia, id ullamcorper est lacinia. Donec et mi vel lorem placerat scelerisque. Vestibulum sem magna, rhoncus eget faucibus non, molestie ac arcu. Sed sed ullamcorper nisl. Vivamus aliquam elit quis arcu tempor ornare. Curabitur vitae erat a lacus molestie iaculis laoreet sit amet metus. Curabitur tempus metus in rhoncus facilisis.
            Donec auctor vel erat id faucibus. In hac habitasse platea dictumst. Donec fringilla malesuada leo quis pellentesque. Nam vel vehicula ante. Donec ac ante nec ex feugiat elementum quis sed lorem. Mauris interdum ex at orci blandit mattis. Praesent rutrum, erat in varius mollis, enim odio viverra justo, a tristique lacus purus ac orci. Praesent convallis iaculis mattis. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Phasellus gravida ligula et ante aliquam, sit amet bibendum elit ullamcorper. Nulla urna nibh, blandit et nibh sit amet, blandit vulputate lorem.
            Phasellus non consequat nisi. Etiam blandit nisl at turpis maximus aliquam. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Maecenas ac ipsum semper, vestibulum orci vel, sodales dui. Praesent molestie urna a porta dapibus. Cras sed bibendum est. Sed in tempor lorem. Suspendisse pharetra feugiat nibh. Nulla efficitur arcu at nisi ullamcorper vulputate. Sed arcu quam, gravida id egestas vel, condimentum hendrerit ipsum. Morbi id porttitor nunc.
            Donec mi orci, sodales eu aliquam eu, mattis at purus. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce ex ex, facilisis eget purus eget, porta rhoncus leo. Nunc at elit quis enim ultrices pulvinar id ut est. Nulla condimentum lorem vel orci laoreet ultrices. Proin non viverra urna. Sed auctor ullamcorper urna sit amet eleifend. Suspendisse vestibulum, nunc vel tincidunt suscipit, eros mi blandit diam, eget placerat diam odio at odio. Integer ligula turpis, mattis nec hendrerit eget, volutpat vitae tellus. Sed blandit mi ac orci eleifend, ut tincidunt libero luctus. Maecenas molestie eu mi a pretium. Nam vehicula libero non egestas pharetra. Praesent finibus lacus sit amet elit rutrum, 
            sed feugiat tortor dictum. Fusce ut auctor velit. Nam imperdiet nulla a augue fringilla, nec maximus justo rutrum. Donec suscipit ante a diam suscipit dignissim.Donec mi orci, sodales eu aliquam eu, mattis at purus. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce ex ex, facilisis eget purus eget, porta rhoncus leo. Nunc at elit quis enim ultrices pulvinar id ut est. Nulla condimentum lorem vel orci laoreet ultrices. Proin non viverra urna. Sed auctor ullamcorper urna sit amet eleifend. Suspendisse vestibulum, nunc vel tincidunt suscipit, eros mi blandit diam, eget placerat diam odio at odio. Integer ligula turpis, mattis nec hendrerit eget, volutpat vitae tellus. Sed blandit mi ac orci eleifend, ut tincidunt libero luctus. Maecenas molestie eu mi a pretium. Nam vehicula libero non egestas pharetra. Praesent finibus lacus sit amet elit rutrum, sed feugiat tortor dictum. Fusce ut auctor velit. Nam imperdiet nulla a augue fringilla, nec maximus justo rutrum. Donec suscipit ante a diam suscipit dignissim.
            Maecenas feugiat dignissim urna. Nam varius, lectus eu sagittis vulputate, metus risus tincidunt elit, id maximus sapien nulla at leo. Ut in sapien maximus, lobortis orci nec, egestas elit. Maecenas ac consequat eros, non blandit orci. Sed id est arcu. Donec elementum rutrum magna eget auctor.
             In feugiat felis sit amet libero porta suscipit. Donec hendrerit enim faucibus ipsum tincidunt laoreet. Morbi tincidunt in ligula et lobortis. Nulla malesuada diam vitae lobortis aliquet. Ut turpis massa, elementum at vulputate ut, congue in justo. Quisque vehicula neque non sem ultricies, a placerat dolor convallis. Nulla pretium commodo turpis, sed pretium massa elementum vel.
             Donec auctor vel erat id faucibus. In hac habitasse platea dictumst. Donec fringilla malesuada leo quis pellentesque. Nam vel vehicula ante. Donec ac ante nec ex feugiat elementum quis sed lorem. Mauris interdum ex at orci blandit mattis. Praesent rutrum, erat in varius mollis, enim odio viverra justo, a tristique lacus purus ac orci. Praesent convallis iaculis mattis. 
             Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Phasellus gravida ligula et ante aliquam, sit amet bibendum elit ullamcorper. Nulla urna nibh, blandit et nibh sit amet, blandit vulputate lorem. 
             Phasellus non consequat nisi. Etiam blandit nisl at turpis maximus aliquam. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Maecenas ac ipsum semper, vestibulum orci vel, sodales dui. Praesent molestie urna a porta dapibus. Cras sed bibendum est. Sed in tempor lorem. Suspendisse pharetra feugiat nibh. Nulla efficitur arcu at nisi ullamcorper vulputate. Sed arcu quam, gravida id egestas vel, condimentum hendrerit ipsum. Morbi id porttitor nunc
            Donec mi orci, sodales eu aliquam eu, mattis at purus. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce ex ex, facilisis eget purus eget, porta rhoncus leo. Nunc at elit quis enim ultrices pulvinar id ut est. Nulla condimentum lorem vel orci laoreet ultrices. Proin non viverra urna. Sed auctor ullamcorper urna sit amet eleifend. Suspendisse vestibulum, nunc vel tincidunt suscipit, eros mi blandit diam, eget placerat diam odio at odio. Integer ligula turpis, mattis nec hendrerit eget, volutpat vitae tellus. Sed blandit mi ac orci eleifend, ut tincidunt libero luctus. Maecenas molestie eu mi a pretium. Nam vehicula libero non egestas pharetra.
            Praesent finibus lacus sit amet elit rutrum, sed feugiat tortor dictum. Fusce ut auctor velit. Nam imperdiet nulla a augue fringilla, nec maximus justo rutrum. Donec suscipit ante a diam suscipit dignissim.
            </Typography>    
             </Card>            
                        
            </Grid>
                    
            </Container> 
        
        </Page>   
            
                          
                    
            
            
            
        </>
    );
};
