// material
import {

    Card,
    Table,
    TableRow,
    TableBody,
    TableCell,
    TableHead,
    TableContainer,
    Container,
} from '@material-ui/core';
// utils
import { RootState, useDispatch, useSelector } from 'redux/store';
import { useEffect } from 'react';
import { getOccupation } from 'redux/slices/occupation';
import { getOutboundOptions } from 'redux/slices/outboundOptions';
import Page from 'components/Page';
import HeaderBreadcrumbs from 'components/HeaderBreadcrumbs';
import useLocales from 'hooks/useLocales';
// ----------------------------------------------------------------------

export default function SourceOptions() {
    const { translate } = useLocales();
    const dispatch = useDispatch();
    const { outboundOptions } = useSelector(
        (state: RootState) => state.outboundOptions
    );


    useEffect(() => {
        dispatch(getOutboundOptions());
    }, [dispatch]);


    return (
        <>  <Page title="Source options">
            <Container maxWidth="xl">

                <HeaderBreadcrumbs
                    heading={translate(`dashboard.${"Source options"}`)}
                    links={[
                        // { name: 'Amount', href: PATH_DASHBOARD.user.root },
                        // { name: 'Delivery Options', href: PATH_DASHBOARD.user.root },
                    ]}
                />
                <Card sx={{ pb: 3 }}>
                    <TableContainer sx={{ minWidth: 720 }}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Country code</TableCell>
                                    <TableCell>Country name</TableCell>
                                    <TableCell>Currency code</TableCell>
                                    <TableCell>Currency name</TableCell>
                                </TableRow>
                            </TableHead>
                            {outboundOptions !== null &&
                                <TableBody>
                                    {outboundOptions.map((row) => (
                                        <TableRow key={row.outboundCountryCode}>
                                            <TableCell>{row.outboundCountryCode}</TableCell>
                                            <TableCell>{row.outboundCountryName}</TableCell>
                                            <TableCell>{row.outboundCurrencyCode}</TableCell>
                                            <TableCell>{row.outboundCurrencyName}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>}
                        </Table>

                    </TableContainer>
                </Card>
            </Container>
        </Page></>
    );
}