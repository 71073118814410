import { Helmet } from "react-helmet-async";
import { useLocation } from "react-router-dom";
import { forwardRef, useEffect, useCallback, ReactNode } from "react";
// material
import { Box, BoxProps } from "@material-ui/core";
// utils

// ----------------------------------------------------------------------

interface PageProps extends BoxProps {
	children: ReactNode;
	title?: string;
}

const Page = forwardRef<HTMLDivElement, PageProps>(
	({ children, title = "", ...other }, ref) => {
		const { pathname } = useLocation();
		return (
			<Box ref={ref} {...other}>
				<title>{title}</title>

				{children}
			</Box>
		);
	}
);

export default Page;
