import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
// utils
import axios from '../../utils/axios';
import { EditBasicInfoType } from '../../@types/editBasicInfoType';


type EditBasicInfoState = {
    isLoading: boolean;
    error: boolean;
    editBasicInfo: EditBasicInfoType;
}

const initialState: EditBasicInfoState = {
    isLoading: false,
    error: false,
    editBasicInfo: {
        "senderFirstName": "",
        "senderMiddleName": "",
        "senderLastName": ""
    },
}

const slice = createSlice({
    name: 'editBasicInfo',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        editBasicInfoSuccess(state, action) {
            state.isLoading = false;
            state.editBasicInfo = action.payload;
        },
    }
});

// Reducer
export default slice.reducer;

export function editBasicInformations(userId: number, values: any) {
    return async () => {
        console.log(values);
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.put(`https://6jfjr9vffh.execute-api.ap-south-1.amazonaws.com/staging/mcus/bo/customer/${userId}/info`,
                {
                    "senderFirstName": values.senderFirstName,
                    "senderMiddleName": values.senderMiddleName,
                    "senderLastName": values.senderLastName,
                }
            );
            dispatch(slice.actions.editBasicInfoSuccess(response.data.data));
        } catch (error :any) {
            dispatch(slice.actions.hasError(error));
            return {message : error?.message, hasError : true} 
        }
    };
}