import React, { useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";

import { Box, Grid, Stack, Typography, Card } from "@material-ui/core";

import { LoadingButton } from "@material-ui/lab";
import { useCallback, useContext } from 'react'
import { AppContext } from "contexts/SendMoneyContext";

import { Link } from 'react-router-dom'
import { PATH_DASHBOARD } from "routes/paths";
import { experimentalStyled as styled } from '@material-ui/core/styles';
import { RootState, useDispatch, useSelector } from "redux/store";
import { getRouteSpecificReceiver } from "redux/slices/routeSpecificReceivers";
import { getReceiverById } from "redux/slices/receiverById";
import { getPurpose } from "redux/slices/purpose";
import { getRemitanceOrder } from "redux/slices/remitanceOrder";
import useLocales from 'hooks/useLocales'

const FormFieldPadding = '20px';

const RowStyle = styled('div')({
    display: 'flex',
    justifyContent: 'space-between'
});


const PurposeOptions = [
    {
        value: 'Debit card',
        label: 'Debit card',
    },
    {
        value: 'Credit card',
        label: 'Credit card',
    },
];


export function ConfirmForm() {
    const {
        formValues,
        handleChange,

    } = useContext(AppContext)
    const { routeId, receiverId, purposeValue,outboundQuoteId,payWith, sendAmount, receiveAmount, deliverTo, sendCurrency, receiveCurrency, accountType, receiverFirstName, receiverMiddleName, receiverLastName, accountNumber, IFSCCode } = formValues

    const { translate } = useLocales();
    const reviewDetails = "Review Details"
    const recipientInformation = "Recipient Information"
    const name = "Name"
    const accountNumberText = "Account Number"
    const  bankName= "Bank Name"
    const  yourTransfer= "Your Transfer"
    const willRecieve = "Will recieve"
    const feeText = "Fee"
    const shouldArrive = "Should arrive"
    const rateGuaranteed = "Rate guaranteed"
    const  totalPay= "Total to pay"
    const paymentIsCompleted="Payment is created"
    const yourOrderId = "Your Order ID"
    const email = "Email"
    const phoneNumber = "Phone Number"
    const bankCode = "Bank Code"
    const ifscCode = "IFSC Code"
    const branchCode = "Branch Code"
    const branchName = "Branch Name"
    const swiftCode = "Swift Code"
    const upToOneDay = "Up to one day"
    const confirm = "Confirm"
    const home ="Home"

    const isError = useCallback(
        () =>
            Object.keys({ purposeValue }).some(
                (name) =>
                    (formValues[name].required && !formValues[name].value) ||
                    formValues[name].error
            ),
        [formValues, purposeValue]
    )

    const [purpose, setPurpose] = React.useState("");
    const [invoiceKey, setInvoiceKey] = React.useState(0);

    const dispatch = useDispatch();
    const { receiverById } = useSelector(
        (state: RootState) => state.receiverById
    );
    const { purposeValues } = useSelector(
        (state: RootState) => state.purpose
    );
    const { remitanceOrder } = useSelector(
        (state: RootState) => state.remitanceOrder
    );
    console.log(receiverId);
    console.log(typeof (receiverId.value));
    console.log(purposeValues);

    let senderId = localStorage.getItem('senderId');
    useEffect(() => {
        if (senderId !== null) {
            if (receiverId.value !== null) {
                if (receiverId.value !== 0 && receiverId.value) {
                    dispatch(getReceiverById(senderId, receiverId.value));
                    console.log("success");
                    //TODO pass the original receiverId
                }
            }
        }

        dispatch(getPurpose());
    }, []);

    console.log(receiverById);
    console.log(receiverId.value);

    const handlePurpose = (e: any) => {
        setPurpose(e.target.value);
        formValues.purposeValue.value = e.target.value;
        console.log(e.target.value);
    }

    const handleRemitance = () => {
        
        if (senderId !== null && receiverId.value && outboundQuoteId.value && routeId.value && payWith.value && purposeValue.value) {
        dispatch(getRemitanceOrder(outboundQuoteId.value,senderId,receiverId.value+ '',routeId.value,payWith.value,purposeValue.value));
        setInvoiceKey(1)
    }
    }

    return (


        <>
            {(receiverById !== null && receiverById.receiverId !== 0) &&
                <Card sx={{ p: 4, overflow: 'visible' }}>
                    <Grid container >
                        <Grid item xs={12}>
                            <Box
                                sx={{
                                    width: '100%',
                                    // paddingBottom: FormFieldPadding
                                }}
                            >
                                <Stack spacing={2}>

                                    {invoiceKey == 0 && <RowStyle>
                                        <Typography variant="h4" >
                                        {translate(`dashboard.${reviewDetails}`)}
                                        </Typography>

                                    </RowStyle>}

                                    {invoiceKey == 1 && <div style={{textAlign:'center', display:'initial'}}>
                                        <img src="/static/icons/check.png" alt="" style={{width:'80px',textAlign:'center', display:'initial'}} />
                                               <Typography variant="h4" >
                                               {translate(`dashboard.${paymentIsCompleted}`)}
                                        </Typography>
                                        {remitanceOrder!==null && remitanceOrder.remittanceOrder &&
                                        <Typography variant="body2" >
                                            {translate(`dashboard.${yourOrderId}`)} {remitanceOrder.remittanceOrder.remittanceOrderId}
                                        </Typography>}
                                    </div>}

                                    <RowStyle>
                                        <Typography variant="h6" sx={{ color: 'text.secondary', paddingTop: '20px' }}>
                                        {translate(`dashboard.${recipientInformation}`)}
                                        </Typography>
                                    </RowStyle>

                                    {receiverById.receiverFirstName !== null && <RowStyle>
                                        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                        {translate(`dashboard.${name}`)}
                                        </Typography>
                                        <Typography variant="body2">
                                            {receiverById.receiverFirstName} {receiverById.receiverLastName}</Typography>
                                    </RowStyle>
                                    }
                                    {(receiverById.receiverContact !== null) ? <RowStyle>
                                        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                        {translate(`dashboard.${email}`)}
                                        </Typography>
                                        <Typography variant="body2">
                                            {receiverById.receiverContact[0].receiverEmail}</Typography>
                                    </RowStyle> : null
                                    }
                                    {(receiverById.receiverContact !== null) ? <RowStyle>
                                        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                        {translate(`dashboard.${phoneNumber}`)}
                                        </Typography>
                                        <Typography variant="body2">
                                            {receiverById.receiverContact[0].receiverPhoneNumber}</Typography>
                                    </RowStyle> : null
                                    }
                                    {(receiverById.receiverBank !== null && receiverById.receiverBank[0].receiverIFSC !== null) ? <RowStyle>
                                        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                        {translate(`dashboard.${ifscCode}`)}
                                        </Typography>
                                        <Typography variant="body2">
                                            {receiverById.receiverBank[0].receiverIFSC}</Typography>
                                    </RowStyle> : null
                                    }
                                    {(receiverById.receiverBank !== null && receiverById.receiverBank[0].receiverAccountNumber !== null) ? <RowStyle>
                                        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                        {translate(`dashboard.${accountNumberText}`)}
                                        </Typography>
                                        <Typography variant="body2">
                                            {receiverById.receiverBank[0].receiverAccountNumber}</Typography>
                                    </RowStyle> : null
                                    }

                                    {(receiverById.receiverBank !== null && receiverById.receiverBank[0].receiverBankName !== null) ? <RowStyle>
                                        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                        {translate(`dashboard.${bankName}`)}
                                        </Typography>
                                        <Typography variant="body2">
                                            {receiverById.receiverBank[0].receiverBankName}</Typography>
                                    </RowStyle> : null
                                    }

                                    {(receiverById.receiverBank !== null && receiverById.receiverBank[0].receiverBankCode !== null) ? <RowStyle>
                                        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                        {translate(`dashboard.${bankCode}`)}
                                        </Typography>
                                        <Typography variant="body2">
                                            {receiverById.receiverBank[0].receiverBankCode}</Typography>
                                    </RowStyle> : null
                                    }

                                    {(receiverById.receiverBank !== null && receiverById.receiverBank[0].receiverSwiftCode !== null) ? <RowStyle>
                                        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                        {translate(`dashboard.${swiftCode}`)}
                                        </Typography>
                                        <Typography variant="body2">
                                            {receiverById.receiverBank[0].receiverSwiftCode}</Typography>
                                    </RowStyle> : null
                                    }
                                    {(receiverById.receiverBank !== null && receiverById.receiverBank[0].receiverBranchName !== null) ? <RowStyle>
                                        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                        {translate(`dashboard.${bankCode}`)}
                                        </Typography>
                                        <Typography variant="body2">
                                            {receiverById.receiverBank[0].receiverBranchName}</Typography>
                                    </RowStyle> : null
                                    }

                                    {(receiverById.receiverBank !== null && receiverById.receiverBank[0].receiverBranchCode !== null) ? <RowStyle>
                                        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                        {translate(`dashboard.${branchCode}`)}
                                        </Typography>
                                        <Typography variant="body2">
                                            {receiverById.receiverBank[0].receiverBranchCode}</Typography>
                                    </RowStyle> : null
                                    }
                                    <RowStyle>
                                        <Typography variant="h6" sx={{ color: 'text.secondary', paddingTop: '20px' }}>
                                        {translate(`dashboard.${yourTransfer}`)}
                                        </Typography>
                                    </RowStyle>

                                    <RowStyle>
                                        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                        {translate(`dashboard.${willRecieve}`)}
                                        </Typography>
                                        <Typography variant="body2">
                                            {formValues.receiveAmount.value} {formValues.receiveCurrency.value}</Typography>
                                    </RowStyle>

                                    <RowStyle>
                                        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                        {translate(`dashboard.${feeText}`)}
                                        </Typography>
                                        <Typography variant="body2">
                                        {formValues.fee.value}  {formValues.sendCurrency.value}</Typography>
                                    </RowStyle>

                                    <RowStyle>
                                        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                        {translate(`dashboard.${shouldArrive}`)}
                                        </Typography>
                                        <Typography variant="body2">
                                        {translate(`dashboard.${upToOneDay}`)}</Typography>
                                    </RowStyle>


                                    <RowStyle>
                                        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                        {translate(`dashboard.${rateGuaranteed}`)}
                                        </Typography>
                                        {/* <Typography variant="body2">{fCurrency(currentBalance)}</Typography> */}
                                        <Typography variant="body2">0.665</Typography>
                                    </RowStyle>

                                    <RowStyle>
                                        <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                                        {translate(`dashboard.${totalPay}`)}
                                        </Typography>
                                        <Typography variant="subtitle2">
                                            {formValues.total.value}  {formValues.sendCurrency.value} </Typography>
                                    </RowStyle>

                                </Stack>
                            </Box>
                        </Grid>

                        <Grid item xs={12}>
                            <Box
                                sx={{
                                    width: '100%',
                                    paddingBottom: FormFieldPadding,
                                    paddingTop: FormFieldPadding
                                }}
                            >
                                {invoiceKey == 0 && <TextField
                                    style={{ width: "100%" }}
                                    name="purpose"
                                    select
                                    label="Purpose of transaction"
                                    margin="normal"
                                    variant="outlined"
                                    value={purpose}
                                    onChange={handlePurpose}
                                >
                                    {purposeValues.map((option) => (
                                        <MenuItem key={option.purposeCode} value={option.purposeCode}>
                                            {option.purposeText}
                                        </MenuItem>
                                    ))}
                                </TextField>}
                            </Box>
                        </Grid>

                        {invoiceKey == 0 && <LoadingButton
                            fullWidth
                            // color="inherit"
                            color={!isError() ? "primary" : "inherit"}
                            size="large"
                            type="submit"
                            variant="contained"
                            onClick={!isError() ? () => handleRemitance() : () => null}
                        //component={Link} to={PATH_DASHBOARD.general.recipients}
                        >
                           {translate(`dashboard.${confirm}`)}
                        </LoadingButton>}

                        {invoiceKey == 1 && <LoadingButton
                            fullWidth
                            color="primary"
                            size="large"
                            type="submit"
                            variant="contained"
                            component={Link} to={PATH_DASHBOARD.general.app}
                        >
                          {translate(`dashboard.${home}`)} 
                        </LoadingButton>}
                    </Grid>
                </Card>
            }
        </>
    );
}

