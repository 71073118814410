import React, { useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import { Autocomplete, Box, Grid, Stack, Toolbar, Typography, Card } from "@material-ui/core";
import { LoadingButton } from "@material-ui/lab";
import { useCallback, useContext } from 'react'
import { AppContext } from "contexts/SendMoneyContext";
import AppBar from '@material-ui/core/AppBar';
import ShouldArrive from "./ShouldArrive";
import { PATH_DASHBOARD } from "routes/paths";
import { useNavigate } from 'react-router-dom';
import { ReceiverForm } from "./ReceiverForm";
import { RootState, useDispatch, useSelector } from "redux/store";
import { getQuoteAmount } from "redux/slices/quoteAmount";
import { getOutboundOptions } from "redux/slices/outboundOptions";
import { getReceiveOptions } from "redux/slices/receiveOptions";
import { PaymentOption, receiveOptionsType, Route } from "../../@types/receiveOptionsType";
import useLocales from "hooks/useLocales";
import { experimentalStyled as styled } from '@material-ui/core/styles';

const FormFieldPadding = '20px';
type CurrencyProps = {
  options: {
    currencyCode: string;
    name: string;
    namePlural: string;
    symbol: string;
    symbolNative: string;
    decimalDigits: number;
  }[];
};

const RowStyle = styled('div')({
  display: 'flex',
  justifyContent: 'space-between'
});
// type CurrencyType = {
//   currencyCode: string;
//   name: string;
//   namePlural: string;
//   symbol: string;
//   symbolNative: string;
//   decimalDigits: number;
// }[];

// const DeliverOptions = [
//   {
//     value: 'BankDeposit',
//     label: 'Bank Account',
//   },
//   {
//     value: 'GCASH',
//     label: 'GCASH',
//   },
// ];
// const PayWithOptions = [
//   {
//     value: 'DebitCard',
//     label: 'Debit Card',
//   },
//   {
//     value: 'CreditCard',
//     label: 'Credit Card',
//   },
// ];

type keyType = {
  formKey: number,
};

const sendAmountText = "Send Amount";
const receiveAmountText = "Receive Amount";
const deliverToText = "Deliver To";
const paywithText = "Pay with";
const ContinueText = "Continue";
const shouldArriveText = "Should arrive";
const feeText = "Fee";
const totalToPayText = "Total to Pay";


export function CurrencyForm({ options }: CurrencyProps) {
  const { translate } = useLocales();
  //context
  const {
    formValues,
    handleChange,
  } = useContext(AppContext)

  const { sendAmount, receiveAmount, deliverTo, payWith, sendCurrency, receiveCurrency, routeId, outboundCountryValue, recvCountryValue } = formValues
  //checking for all value is filled
  const isError = useCallback(
    () =>
      Object.keys({ sendAmount, receiveAmount, deliverTo, payWith }).some(
        (name) =>
          (formValues[name].required && !formValues[name].value) ||
          formValues[name].error
      ),
    [formValues, sendAmount, deliverTo, payWith]
  )
  let senderId: number;
  let outboundCountry: string
  let recvCountry: string
  let senderIdTemp = localStorage.getItem('senderId');
  if (senderIdTemp !== null) {
    senderId = +senderIdTemp;
  }
  //Using dispatch for calling  API functions
  const dispatch = useDispatch();
  //API data for getting price quote
  const { quoteAmount } = useSelector(
    (state: RootState) => state.quoteAmount
  );
  //API data for outbound options - JPY
  const { outboundOptions } = useSelector(
    (state: RootState) => state.outboundOptions
  );

  //API data for receive outbound options
  const { receiveOptions } = useSelector(
    (state: RootState) => state.receiveOptions
  );

  useEffect(() => {
    //API call for getting default values of send money form when loading first time
    if (senderId) {
      dispatch(getQuoteAmount(senderId, "JPY", "JPN", "USD", "USA", 1000, "SEND"));
    }
    dispatch(getOutboundOptions());
    dispatch(getReceiveOptions());
  }, [dispatch]);

  console.log(quoteAmount);
  console.log(outboundOptions);
  console.log(receiveOptions);


  const navigate = useNavigate();

  const [sendAmountValue, setSendAmountValue] = React.useState("");
  const [receiveAmountValue, setReceiveAmountValue] = React.useState("");
  const [sendCurrencyValue, setSendCurrencyValue] = React.useState("JPY");
  const [recieveCurrencyValue, setRecieveCurrencyValue] = React.useState("USD");
  const [deliverTovalue, setDeliverTo] = React.useState("");
  const [payWithValue, setPayWith] = React.useState("");
  const [isSend, setIsSend] = React.useState(false);
  const [isRecv, setIsRecv] = React.useState(false);
  const [deliverToOptions, setDeliverToOptions] = React.useState<Route[]>([]);
  const [payWithOptions, setPayWithOptions] = React.useState<PaymentOption[]>([]);

  useEffect(() => {
    //setting the defualt values for fileds
    if (formValues.sendAmount.value == '') {
      formValues.sendAmount.value = 1000;
    }

    if (formValues.sendCurrency.value == '') {
      formValues.sendCurrency.value = 'JPY';
    }

    if (formValues.receiveCurrency.value == '') {
      formValues.receiveCurrency.value = 'USD';
    }
    // if (formValues.deliverTo.value == '') {
    //   formValues.deliverTo.value = 'JPN_JPY_USA_USD_MONEYGRAM_CASH_PICKUP';
    // }
    // if (formValues.payWith.value == '') {
    //   formValues.payWith.value = 'MANUAL_BANK_TRANSFER';
    // }

  }, []);

  if (formValues.receiveAmount.value == '') {
    formValues.receiveAmount.value = quoteAmount.netRecvAmount;
  }

  //Setting the default  options for DeliverTo and Paywith dropdowns (JPY-USA)
  let defaultOptionTemp = receiveOptions.filter((option) => (
    option.recvCountryCode == 'USA'
  ))
  //checking for deliverTo data is not null(from Api)
  if (defaultOptionTemp.length > 0 && deliverToOptions.length == 0) {
    defaultOptionTemp.map(option => (setDeliverToOptions(option.routes),
      setPayWithOptions(option.paymentOptions)))
  }

  const handleSendAmountValue = (e: any) => {
    setSendAmountValue(e.target.value)
    console.log(typeof e.target.value);

    let sendValue = +e.target.value
    console.log(typeof sendValue);

    let outboundCurrency = sendCurrencyValue
    let recvCurrency = recieveCurrencyValue
    let amount = sendValue
    let quoteType = "SEND"
    outboundCountry = outboundCountryValue.value
    recvCountry = recvCountryValue.value

    dispatch(getQuoteAmount(
      senderId,
      outboundCurrency,
      outboundCountry,
      recvCurrency,
      recvCountry,
      amount,
      quoteType
    ));
    console.log(isSend);



    if (isSend == true) {
      formValues.sendAmount.value = e.target.value

    }
    else {
      formValues.sendAmount.value = quoteAmount.netOutboundSendAmount
    }

    handleChange(e)
  }

  const handleReceiveAmountValue = (e: any) => {
    setReceiveAmountValue(e.target.value)
    let recvValue = +e.target.value
    let outboundCurrency = sendCurrencyValue
    outboundCountry = outboundCountryValue.value
    let recvCurrency = recieveCurrencyValue
    recvCountry = recvCountryValue.value
    let amount = recvValue
    let quoteType = "RECV"

    dispatch(getQuoteAmount(
      senderId,
      outboundCurrency,
      outboundCountry,
      recvCurrency,
      recvCountry,
      amount,
      quoteType
    ));
    if (isRecv == true) {
      formValues.receiveAmount.value = e.target.value
    }
    else {
      formValues.receiveAmount.value = quoteAmount.netRecvAmount
    }

    handleChange(e)
  }

  if (isSend == true) {
    formValues.receiveAmount.value = quoteAmount.netRecvAmount
  }
  if (isRecv == true) {
    formValues.sendAmount.value = quoteAmount.netOutboundSendAmount
  }
// Setting  outboundQuoteId to context 
  if (quoteAmount.outboundQuoteId) {
    formValues.outboundQuoteId.value = quoteAmount.outboundQuoteId;
  }

  if (quoteAmount.recvFees) {
    formValues.fee.value = quoteAmount.recvFees;
  }

  if (quoteAmount.totalOutboundSendAmount) {
    formValues.total.value = quoteAmount.totalOutboundSendAmount;
  }

  const handleSendCurrencyValue = (e: any, values: any) => {
    setSendCurrencyValue(values.outboundCurrencyCode)
    console.log(e.target.value);
    console.log(values);
    formValues.sendCurrency.value = values.outboundCurrencyCode
    formValues.outboundCountryValue.value = values.outboundCountryCode
    handleChange(e)
  }

  const handleRecieveCurrencyValue = (e: any, values: receiveOptionsType | null) => {
    if (values !== null) {
      setRecieveCurrencyValue(values.recvCurrencyCode)
      console.log(e);
      console.log(values);
      formValues.receiveCurrency.value = values.recvCurrencyCode
      formValues.recvCountryValue.value = values.recvCountryCode
      handleChange(e)

      let outboundCurrency = sendCurrencyValue
      let recvCurrency = values.recvCurrencyCode
      outboundCountry = outboundCountryValue.value
      recvCountry = recvCountryValue.value
      let amount = formValues.sendAmount.value
      let quoteType = "SEND"

      dispatch(getQuoteAmount(
        senderId,
        outboundCurrency,
        outboundCountry,
        recvCurrency,
        recvCountry,
        amount,
        quoteType
      ));
      setDeliverToOptions(values.routes)
      setPayWithOptions(values.paymentOptions)
      setIsSend(true)
      console.log(quoteAmount);
    }
  }
  console.log(deliverToOptions);
  console.log(payWithOptions);

  const handleDeliverTo = (e: React.ChangeEvent<HTMLInputElement>) => {
    let deliveryOptions = JSON.parse(e.target.value)
    console.log(deliveryOptions);
    setDeliverTo(deliveryOptions.routeCode)
    formValues.deliverTo.value = deliveryOptions.routeCode;
    formValues.routeId.value = deliveryOptions.routeId;
    //handleChange(e)
  }

  const handleDeliverToOption = (e: React.ChangeEvent<HTMLInputElement>) => {

    console.log(e.target.value);

  }

  const handlePayWith = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPayWith(e.target.value)
    console.log(e.target.value);
    formValues.payWith.value = e.target.value

  }
  // const handleNextForm = () => {

  //   navigate(PATH_DASHBOARD.general.receiver)
  // }

  const [sendFormKey, setFormKey] = React.useState(0);

  const NewRecipientView = ({ formKey }: keyType) => {
    console.log('success');
    return <ReceiverForm newRecipientFormKey={sendFormKey} />
  }
  return (


    <>
      {/* if sendFormKey is 1 , the route to next form */}
      {sendFormKey == 1 && <NewRecipientView formKey={sendFormKey} />}
      {sendFormKey !== 1 &&
        <Card sx={{ p: 4, overflow: 'visible' }}>
          <Grid container >
            <Grid item xs={12}>
              <Box
                sx={{
                  width: '100%',
                  marginBottom: '40px',
                }}
              >
                <AppBar position="static" >
                  <Toolbar variant="regular" >
                    {quoteAmount.rate !== 0 ? <Typography color="inherit" align="right" variant="h4" component="div" className="appbar-text">
                      1.00 {sendCurrencyValue}  = {quoteAmount.rate} {recieveCurrencyValue}
                    </Typography> : <Typography color="inherit" align="right" variant="h4" component="div" className="appbar-text">
                      1.00 JPY = {quoteAmount.rate} {recieveCurrencyValue}
                    </Typography>}
                  </Toolbar>
                </AppBar>
              </Box>
            </Grid>

            <Grid item xs={10}>
              <TextField className="send-amount"
                fullWidth
                name="sendAmount"
                autoComplete="username"
                type="number"
                label={translate(`dashboard.${sendAmountText}`)}
                value={formValues.sendAmount.value}
                //defaultValue="100000"
                onChange={handleSendAmountValue}
                onFocus={() => setIsSend(true)
                }
                onBlur={() => setIsSend(false)}
              />
            </Grid>

            <Grid item xs={2}>
              <Box
                sx={{
                  width: '100%',
                  marginBottom: FormFieldPadding,
                  borderRadius: '10px'
                }}
              >
                {sendCurrencyValue ?
                  <img src={`/static/flags/${sendCurrencyValue.toLowerCase()}.png`} width="30px" className="currency-img" /> :
                  <img src={`/static/flags/jpy.png`} width="30px" className="currency-img" />
                }
                <Autocomplete
                  fullWidth
                  disablePortal
                  autoHighlight
                  options={outboundOptions}
                  getOptionLabel={(option) => (option.outboundCountryCode)}
                  onChange={handleSendCurrencyValue}
                  renderOption={(props, option) => (

                    <li {...props}>
                      <img src={`/static/flags/${option.outboundCurrencyCode.toLowerCase()}.png`} width="30px" /><span>&nbsp;&nbsp;</span> {option.outboundCurrencyCode}  {option.outboundCurrencyName}
                    </li>
                  )}
                  renderInput={(params) => (
                    <div>
                      <TextField className="currency-text send-flag"
                        variant="outlined"
                        {...params}
                        fullWidth
                        name="currency"
                        value={sendCurrencyValue}
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: 'new-password',
                        }}
                      />
                    </div>

                  )}
                />
              </Box>
            </Grid>

            <Grid item xs={10}>
              <TextField className="receive-amount"
                fullWidth
                name="receiveAmount"
                autoComplete="username"
                type="number"
                value={formValues.receiveAmount.value}
                // defaultValue="65119"
                label={translate(`dashboard.${receiveAmountText}`)}
                onChange={handleReceiveAmountValue}
                onFocus={() => setIsRecv(true)
                }
                onBlur={() => setIsRecv(false)}
              />
            </Grid>

            <Grid item xs={2}>
              <Box
                sx={{
                  width: '100%',
                  paddingBottom: '10px'
                }}
              >
                {recieveCurrencyValue ?
                  <img src={`/static/flags/${recieveCurrencyValue.toLowerCase()}.png`} width="30px" className="currency-img" /> :
                  <img src={`/static/flags/usd.png`} width="30px" className="currency-img" />
                }
                <Autocomplete
                  fullWidth
                  disablePortal
                  autoHighlight
                  options={receiveOptions}
                  getOptionLabel={(option) => (option.recvCountryCode)}
                  // onSelect={handleRecieveCurrencyValue}
                  onChange={handleRecieveCurrencyValue}
                  renderOption={(props, option) => (
                    <li {...props}>
                      {/* <span>{countryToFlag(option.currencyCode)}</span> */}
                      <img src={`/static/flags/${option.recvCurrencyCode.toLowerCase()}.png`} width="30px" /><span>&nbsp;&nbsp;</span>{option.recvCountryName} {option.recvCurrencyCode}  {option.recvCurrencyName}
                    </li>
                  )}
                  renderInput={(params) => (
                    <div>
                      <TextField className="currency-text receive-flag"
                        variant="outlined"
                        {...params}
                        fullWidth
                        name="recieveCurrencyValue"
                        value={recieveCurrencyValue}
                        // onChange={handleRecieveCurrencyValue}
                        // onSelect={handleRecieveCurrencyValue}
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: 'new-password',
                        }}
                      />
                    </div>

                  )}
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box
                sx={{
                  width: '100%',
                  // paddingBottom: FormFieldPadding
                }}
              >
                <TextField
                  style={{ width: "100%" }}
                  name="deliverTo"
                  select
                  label={translate(`dashboard.${deliverToText}`)}
                  margin="normal"
                  variant="outlined"
                  defaultValue=''
                  onChange={handleDeliverTo}
                //onSelect={handleDeliverToOption}
                >
                  {deliverToOptions.map((option) => (
                    <MenuItem key={option.routeId} value={JSON.stringify(option)}>
                      {option.routeName}
                    </MenuItem>
                  ))}
                </TextField>
              </Box>
            </Grid>

            <Grid item xs={12}>
              <Box
                sx={{
                  width: '100%',
                  paddingBottom: FormFieldPadding
                }}
              >
                <TextField
                  style={{ width: "100%" }}
                  name="payWith"
                  select
                  label={translate(`dashboard.${paywithText}`)}
                  margin="normal"
                  variant="outlined"
                  defaultValue=""
                  // onSelect={handlePayWith}
                  onChange={handlePayWith}
                >
                  {payWithOptions.map((option) => (
                    <MenuItem key={option.paymentOptionId} value={option.paymentOptionId}>
                      {option.paymentOptionName}
                    </MenuItem>
                  ))}
                </TextField>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box
                sx={{
                  width: '100%',
                  paddingBottom: FormFieldPadding
                }}
              >
                {/* <ShouldArrive /> */}

                <Stack spacing={2}>
                  <RowStyle>
                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                      {translate(`dashboard.${shouldArriveText}`)}
                    </Typography>
                    <Typography variant="body2">
                      up to 1 day</Typography>
                  </RowStyle>

                  <RowStyle>
                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                      {translate(`dashboard.${feeText}`)}
                    </Typography>
                    {/* <Typography variant="body2">{fCurrency(currentBalance)}</Typography> */}
                    <Typography variant="body2">{quoteAmount.outboundSendFees}</Typography>
                  </RowStyle>

                  <RowStyle>
                    <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                      {translate(`dashboard.${totalToPayText}`)}
                    </Typography>
                    {/* <Typography variant="subtitle2">- {fCurrency(sentAmount)}</Typography> */}
                    <Typography variant="subtitle2">{quoteAmount.totalOutboundSendAmount} {sendCurrencyValue}</Typography>
                  </RowStyle>
                </Stack>
              </Box>
            </Grid>
            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              //component={Link} to={PATH_DASHBOARD.general.receiver}
              onClick={!isError() ? () => setFormKey(1) : () => null}
              color={!isError() ? "primary" : "inherit"}
            >
              {translate(`dashboard.${ContinueText}`)}
            </LoadingButton>
          </Grid>
        </Card>}
    </>
  );
}

