// material
import {

    Card,
    Table,
    TableRow,
    TableBody,
    TableCell,
    TableHead,
    TableContainer,
    Container,
} from '@material-ui/core';
// utils
import { RootState, useDispatch, useSelector } from 'redux/store';
import { useEffect } from 'react';
import { getRouteBankBO } from 'redux/slices/routeBankBO';
import HeaderBreadcrumbs from 'components/HeaderBreadcrumbs';
import Page from 'components/Page';
import { getIdentificationDoc } from 'redux/slices/identificationDoc';
import useLocales from 'hooks/useLocales';
// ----------------------------------------------------------------------

export default function IdTypes() {
    const { translate } = useLocales();
    const dispatch = useDispatch();
    const { identificationDoc } = useSelector(
        (state: RootState) => state.identificationDocBO
    );


    useEffect(() => {
        dispatch(getIdentificationDoc());
    }, [dispatch]);


    return (
        <>  <Page title="Id types">
            <Container maxWidth="xl">

                <HeaderBreadcrumbs
                    heading={translate(`dashboard.${"Id types"}`)}
                    links={[
                        // { name: 'Amount', href: PATH_DASHBOARD.user.root },
                        // { name: 'Delivery Options', href: PATH_DASHBOARD.user.root },
                    ]}
                />
                <Card sx={{ pb: 3 }}>
                    <TableContainer sx={{ minWidth: 720 }}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Identification doc Id</TableCell>
                                    <TableCell>Identification doc code</TableCell>
                                    <TableCell>Identification doc text</TableCell>
                                </TableRow>
                            </TableHead>
                            {identificationDoc !== null &&
                                <TableBody>
                                    {identificationDoc.map((row) => (
                                        <TableRow key={row.identificationDocId}>
                                            <TableCell>{row.identificationDocId}</TableCell>
                                            <TableCell>{row.identificationDocCode}</TableCell>
                                            <TableCell>{row.identificationDocText}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>}
                        </Table>

                    </TableContainer>
                </Card>
            </Container>
        </Page></>
    );
}