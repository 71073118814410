// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  register: path(ROOTS_AUTH, '/register'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  verify: path(ROOTS_AUTH, '/verify')
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page404: '/404',
  page500: '/500',
  components: '/components'
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    app: path(ROOTS_DASHBOARD, '/app'),
    sendmoney: path(ROOTS_DASHBOARD, '/send'),
    receiver: path(ROOTS_DASHBOARD, '/receiver'),
    confirm: path(ROOTS_DASHBOARD, '/confirm'),
    customer: path(ROOTS_DASHBOARD, '/customer'),
    accounts: path(ROOTS_DASHBOARD, '/accounts'),
    recipients: path(ROOTS_DASHBOARD, '/recipients'),
    transaction: path(ROOTS_DASHBOARD, '/transaction'),
    receiveTransactions: path(ROOTS_DASHBOARD, '/receive-transactions'),
    inboundTransfers: path(ROOTS_DASHBOARD, '/inbound-transfers'),
    reconciliation: path(ROOTS_DASHBOARD, '/reconciliation'),
    rates: path(ROOTS_DASHBOARD, '/rates'),
    purpose: path(ROOTS_DASHBOARD, '/configurations/purpose'),
    country: path(ROOTS_DASHBOARD, '/configurations/country'),
    occupation: path(ROOTS_DASHBOARD, '/configurations/occupation'),
    routes: path(ROOTS_DASHBOARD, '/configurations/routes'),
    source: path(ROOTS_DASHBOARD, '/configurations/source-of-funds'),
    sourceOptions: path(ROOTS_DASHBOARD, '/configurations/source-options'),
    currency: path(ROOTS_DASHBOARD, '/configurations/currency'),
    partners: path(ROOTS_DASHBOARD, '/configurations/partners'),
    banks: path(ROOTS_DASHBOARD, '/configurations/banks'),
    bankTypes: path(ROOTS_DASHBOARD, '/configurations/bank-types'),
    stateProvince: path(ROOTS_DASHBOARD, '/configurations/state'),
    deliveryOptions: path(ROOTS_DASHBOARD, '/configurations/deliver-options'),
    destinationOptions: path(ROOTS_DASHBOARD, '/configurations/destination-options'),
    idTypes: path(ROOTS_DASHBOARD, '/configurations/id-types'),

    ecommerce: path(ROOTS_DASHBOARD, '/ecommerce'),
    analytics: path(ROOTS_DASHBOARD, '/analytics'),
    registerReceiver: path(ROOTS_DASHBOARD, '/registerreceiver'),
    MGCashPickupGenGen: path(ROOTS_DASHBOARD, '/mgcashpickupgengen'),
    MGBankDepositJPYINR: path(ROOTS_DASHBOARD, '/mgbankdepositjpyinr'),

  },

  mail: {
    root: path(ROOTS_DASHBOARD, '/mail'),
    all: path(ROOTS_DASHBOARD, '/mail/all')
  },
  chat: {
    root: path(ROOTS_DASHBOARD, '/chat'),
    new: path(ROOTS_DASHBOARD, '/chat/new'),
    conversation: path(ROOTS_DASHBOARD, '/chat/:conversationKey')
  },
  calendar: path(ROOTS_DASHBOARD, '/calendar'),
  kanban: path(ROOTS_DASHBOARD, '/kanban'),
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    profile: path(ROOTS_DASHBOARD, '/user/profile'),
    cards: path(ROOTS_DASHBOARD, '/user/cards'),
    list: path(ROOTS_DASHBOARD, '/user/list'),
    newUser: path(ROOTS_DASHBOARD, '/user/new'),
    editById: path(ROOTS_DASHBOARD, '/user/ada-lindgren/edit'),
    account: path(ROOTS_DASHBOARD, '/user/account'),
    testing: path(ROOTS_DASHBOARD, '')
  },
  eCommerce: {
    root: path(ROOTS_DASHBOARD, '/e-commerce'),
    shop: path(ROOTS_DASHBOARD, '/e-commerce/shop'),
    product: path(ROOTS_DASHBOARD, '/e-commerce/product/:name'),
    productById: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-air-force-1-ndestrukt'),
    list: path(ROOTS_DASHBOARD, '/e-commerce/list'),
    newProduct: path(ROOTS_DASHBOARD, '/e-commerce/product/new'),
    editById: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-blazer-low-77-vintage/edit'),
    checkout: path(ROOTS_DASHBOARD, '/e-commerce/checkout'),
    invoice: path(ROOTS_DASHBOARD, '/e-commerce/invoice')
  },
  blog: {
    root: path(ROOTS_DASHBOARD, '/blog'),
    posts: path(ROOTS_DASHBOARD, '/blog/posts'),
    post: path(ROOTS_DASHBOARD, '/blog/post/:title'),
    postById: path(ROOTS_DASHBOARD, '/blog/post/portfolio-review-is-this-portfolio-too-creative'),
    newPost: path(ROOTS_DASHBOARD, '/blog/new-post')
  },
  help: {
    root: path(ROOTS_DASHBOARD, '/help'),
    TermsAndCondition: path(ROOTS_DASHBOARD, '/help/termsandcondition'),
    account: path(ROOTS_DASHBOARD, '/help/privacy'),
  },

};

export const PATH_DOCS = 'https://docs-minimals.vercel.app/introduction';
