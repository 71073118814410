import React, { useEffect, useState } from "react";
import { Box, Grid, Stack, Typography, Card, Tabs, Tab, Container, Button, Modal, TextField, Autocomplete } from "@material-ui/core";
import { LoadingButton, TabContext, TabPanel } from "@material-ui/lab";
import { RootState, useDispatch, useSelector } from "redux/store";
import useLocales from 'hooks/useLocales'
import { useNavigate, useParams } from "react-router";
import { getCustomerById } from "redux/slices/customerByIdBO";
import Page from "components/Page";
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import { getReceiversOfCustomer } from "redux/slices/receiversOfCustomerBO";
import { alpha, experimentalStyled as styled } from "@material-ui/core/styles";
import Icon from "@iconify/react";
import editFill from '@iconify/icons-eva/edit-fill';
import { makeStyles } from '@material-ui/core/styles';
import { useFormik } from "formik";
import * as Yup from "yup";
import { editBasicInformations } from "redux/slices/editBasicInfoBO";
import { MIconButton } from 'components/@material-extend';
import closeFill from '@iconify/icons-eva/close-fill';
import { useSnackbar } from 'notistack';
import { editAddressInfo } from "redux/slices/editAddressBO";
import { getCountry } from "redux/slices/country";
import { editIdentificationInfo } from "redux/slices/editIdentificationBO";
import { editBankInfo } from "redux/slices/editBankBO";
import { getRecentTransactionsOfCustomerBO } from "redux/slices/recentTransactionOfCustomerBO";
import { getTransactionOrderDetails } from "redux/slices/transactionOrderDetailsBO";
import Label, { LabelColor } from "components/Label";
import { useTheme } from '@material-ui/core/styles';
import { sentenceCase } from 'change-case';
const RowStyle = styled('div')({
    display: 'flex',
    justifyContent: 'space-between'
});

const RootStyle = styled(Page)(({ theme }) => ({
    [theme.breakpoints.up("md")]: {
        display: "flex",
    },
}));

const ContentStyle = styled("div")(({ theme }) => ({
    maxWidth: 1000,
    margin: "auto",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    padding: theme.spacing(2, 0),
}));

const SCROLLABLE_TAB = [
    { value: '1', label: 'Personal Information' },
    { value: '2', label: 'bank Info' },
    { value: '3', label: 'Receipeints' },
    { value: '4', label: 'Transactions' }
];

const IconWrapperStyle = styled('div')(({ theme }) => ({
    width: 24,
    height: 24,
    display: 'flex',
    borderRadius: '50%',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: theme.spacing(1),
    color: theme.palette.success.main,
    backgroundColor: alpha(theme.palette.success.main, 0.16)
}));


function getModalStyle() {
    const top = 50;
    const left = 50;
    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

const useStyles = makeStyles(theme => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'absolute',
        verticalAlign: 'center'
    },
    paper: {
        position: 'fixed',
        width: '30%',
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(4, 4, 4),
        outline: 'none',
        [theme.breakpoints.down('sm')]: {
            minWidth: `100% !important`,
        },
    },
}));

type BasicInfoInitialValues = {
    senderFirstName: string;
    senderMiddleName: string;
    senderLastName: string;
};

type AddressInitialValues = {
    senderAddress1: string;
    senderAddress2: string;
    senderCity: string;
    senderStateProvince: string;
    senderZipCode: string;
    senderCountry: string;
};

type IdentificationInitialValues = {
    senderIdType: string;
    senderIdNo: string;
    senderIdExpiry: string;
    senderIdCountry: string;
};

type BankInitialValues = {
    senderSwiftCode: string;
    senderSwiftBIC8Code: string;
    senderBankIdentifier: string;
    senderBankName: string;
    senderBankCode: string;
    senderBranchName: string;
    senderBranchCode: string;
    senderIBAN: string;
    senderIFSC: string;
    senderAccountNumber: string;
    senderAccountType: string;
    senderVirtualPaymentAccount: string;
    senderAdditionalKey: string;
    senderAdditionalValue: string;
};

function countryToFlag(isoCode: string) {
    return typeof String.fromCodePoint !== "undefined"
        ? isoCode
            .toUpperCase()
            .replace(/./g, (char) =>
                String.fromCodePoint(char.charCodeAt(0) + 127397)
            )
        : isoCode;
}

export type remittanceStatusType = "CREATED" | "WALLET_RECEIVED" | "WALLET_CREDITED" | "WALLET_DEBITED" | "WALLET_PAUSED" | "FAILED" | "COMPLETED";

export default function CustomerDetails(props: any) {

    const { translate } = useLocales();
    const reviewDetails = "Review Details"
    const recipientInformation = "Recipient Information"
    const name = "Name"
    const accountNumberText = "Account Number"
    const bankName = "Bank Name"
    const email = "Email"
    const phoneNumber = "Phone Number"
    const bankCode = "Bank Code"
    const ifscCode = "IFSC Code"
    const branchCode = "Branch Code"
    const swiftCode = "Swift Code"
    const submit = "Submit"
    const gender = "Gender"
    const DOB = "DOB"
    const Nationality = "Nationality"

    const yourTransfer = "Your Transfer"
    const willRecieve = "Will recieve"
    const feeText = "Fee"
    const shouldArrive = "Should arrive"
    const rateGuaranteed = "Rate guaranteed"
    const totalPay = "Total to pay"
    const paymentIsCompleted = "Payment is created"
    const yourOrderId = "Your Order ID"
    const accountType = "Account Type"
    const branchName = "Branch Name"
    const katakanaName = "Katakana name"
    const firstNameText = "First Name"
    const middleNameText = "Middle Name"
    const lastNameText = "Last Name"
    const addressLine1Text = "Address line 1"
    const addressLine2Text = "Address line 2"
    const cityText = "City"
    const stateProvince = "State Province"
    const country = "Country"
    const zipCodeText = "Zipcode"
    const bankCodeText = "Bank Code"
    const selectIdentificationType = "Select Identification type"
    const uniqueIdNumber = "Unique ID number"
    const idExpiryDate = "ID expiry date"



    const [valueScrollable, setValueScrollable] = useState('1');
    const classes = useStyles();
    const [modalStyle] = React.useState(getModalStyle);
    const [basicOpen, setBasicOpen] = React.useState(false);
    const [addressOpen, setAddressOpen] = React.useState(false);
    const [identificationOpen, setIdentificationOpen] = React.useState(false);
    const [bankOpen, setBankOpen] = React.useState(false);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const theme = useTheme();

    const { customerId } = useParams();
    console.log(customerId);
    let userId = +customerId;

    const [invoiceKey] = React.useState(0);

    const dispatch = useDispatch();

    const { customerById } = useSelector(
        (state: RootState) => state.customerById
    );
    const { receiversOfCustomer } = useSelector(
        (state: RootState) => state.receiversOfCustomer
    );
    const { editBasicInfo } = useSelector(
        (state: RootState) => state.editBasicInfoBO
    );

    const { editAddress } = useSelector(
        (state: RootState) => state.editAddressBO
    );

    const { countryList } = useSelector(
        (state: RootState) => state.countryData
    );

    const { editIdentification } = useSelector(
        (state: RootState) => state.editIdentificationBO
    );

    const { editBank } = useSelector(
        (state: RootState) => state.editBankBO
    );

    const { recentTransactionOfCustomer } = useSelector(
        (state: RootState) => state.recentTransactionOfCustomerBO
    );

    useEffect(() => {
        dispatch(getCustomerById(userId));
        dispatch(getReceiversOfCustomer(userId));
        dispatch(getCountry());
        dispatch(getRecentTransactionsOfCustomerBO(userId, 10, 1));
        console.log("success");
    }, []);


    const statusColorThemes = {
        "CREATED": "warning",
        "WALLET_RECEIVED": "primary",
        "WALLET_CREDITED": "secondary",
        "WALLET_DEBITED": "info",
        "WALLET_PAUSED": "warning",
        "FAILED": "error",
        "COMPLETED": "success"
    }

    const fetchOrderDetails = async (remittanceOrderId: string) => {
        dispatch(getTransactionOrderDetails(remittanceOrderId, navigate))
    }
    const handleChangeScrollable = (event: React.SyntheticEvent, newValue: string) => {
        setValueScrollable(newValue);
    };

    const handleNextForm = (receiverId: number) => {
        //navigate(`/dashboard/recipientdetails/${receiverId}`);
    }


    const handleBasicOpen = () => {
        setBasicOpen(true);
    };

    const handleBasicClose = () => {
        setBasicOpen(false)
    }

    const handleAddressOpen = () => {
        setAddressOpen(true);
    };

    const handleAddressClose = () => {
        setAddressOpen(false)
    }

    const handleIdentificationOpen = () => {
        setIdentificationOpen(true);
    };

    const handleIdentificationClose = () => {
        setIdentificationOpen(false)
    }

    const handleBankOpen = () => {
        setBankOpen(true);
    };

    const handleBankClose = () => {
        setBankOpen(false)
    }

    const formikBasicInfo = useFormik<BasicInfoInitialValues>({
        enableReinitialize: true,
        initialValues: {
            senderFirstName: customerById?.senderFirstName,
            senderMiddleName: customerById?.senderMiddleName || '',
            senderLastName: customerById?.senderLastName,
        },

        validationSchema: Yup.object().shape({

            senderFirstName: Yup.string()
                .required("*Required"),

            senderMiddleName: Yup.string()
                .max(20, "Must be 20 characters or less"),

            senderLastName: Yup.string()
                .required("*Required"),

        }),

        onSubmit: async (values) => {
            let response = await dispatch(editBasicInformations(userId, values));
            if (response?.hasError) {
                enqueueSnackbar(response.message, {
                    variant: "error",
                    action: (key) => (
                        <MIconButton size="small" onClick={() => closeSnackbar(key)}>
                            <Icon icon={closeFill} />
                        </MIconButton>
                    ),
                });
            }
            else {
                setBasicOpen(false);
                dispatch(getCustomerById(userId));
                enqueueSnackbar("Saved!", {
                    variant: "success",
                    action: (key) => (
                        <MIconButton size="small" onClick={() => closeSnackbar(key)}>
                            <Icon icon={closeFill} />
                        </MIconButton>
                    ),
                });
            }

        },
    });



    const formikAddress = useFormik<AddressInitialValues>({
        enableReinitialize: true,
        initialValues: {
            senderAddress1: customerById?.senderAddress && customerById?.senderAddress[0]?.senderAddress1,
            senderAddress2: customerById?.senderAddress && customerById?.senderAddress[0]?.senderAddress1,
            senderCity: customerById?.senderAddress && customerById?.senderAddress[0]?.senderCity,
            senderStateProvince: customerById?.senderAddress && customerById?.senderAddress[0]?.senderStateProvince,
            senderZipCode: customerById?.senderAddress && customerById?.senderAddress[0]?.senderZipCode,
            senderCountry: customerById?.senderAddress && customerById?.senderAddress[0]?.senderCountry,
        },

        validationSchema: Yup.object().shape({

            senderAddress1: Yup.string()
                .required("*Required"),

            senderAddress2: Yup.string()
                .required("*Required"),

            senderCity: Yup.string()
                .required("*Required"),

            senderStateProvince: Yup.string()
                .required("*Required"),

            senderZipCode: Yup.string()
                .required("*Required"),

            senderCountry: Yup.string()
                .required("*Required"),

        }),

        onSubmit: async (values) => {
            let response = await dispatch(editAddressInfo(userId, values));
            if (response?.hasError) {
                enqueueSnackbar(response.message, {
                    variant: "error",
                    action: (key) => (
                        <MIconButton size="small" onClick={() => closeSnackbar(key)}>
                            <Icon icon={closeFill} />
                        </MIconButton>
                    ),
                });
            }
            else {
                setAddressOpen(false);
                dispatch(getCustomerById(userId));
                enqueueSnackbar("Saved!", {
                    variant: "success",
                    action: (key) => (
                        <MIconButton size="small" onClick={() => closeSnackbar(key)}>
                            <Icon icon={closeFill} />
                        </MIconButton>
                    ),
                });
            }
        },
    });


    const formikIdentification = useFormik<IdentificationInitialValues>({
        enableReinitialize: true,
        initialValues: {
            senderIdType: customerById?.senderIdentificationDoc && customerById?.senderIdentificationDoc[0]?.senderIdType,
            senderIdNo: customerById?.senderIdentificationDoc && customerById?.senderIdentificationDoc[0]?.senderIdNo,
            senderIdExpiry: customerById?.senderIdentificationDoc && customerById?.senderIdentificationDoc[0]?.senderIdExpiry,
            senderIdCountry: customerById?.senderIdentificationDoc && customerById?.senderIdentificationDoc[0]?.senderIdCountry,
        },

        validationSchema: Yup.object().shape({

            // senderIdType: Yup.string()
            //     .required("*Required"),

            // senderIdNo: Yup.string()
            //     .required("*Required"),

            // senderIdExpiry: Yup.string()
            //     .required("*Required"),

            // senderIdCountry: Yup.string()
            //     .required("*Required"),
        }),

        onSubmit: async (values) => {
            let response = await dispatch(editIdentificationInfo(userId, values));
            if (response?.hasError) {
                enqueueSnackbar(response.message, {
                    variant: "error",
                    action: (key) => (
                        <MIconButton size="small" onClick={() => closeSnackbar(key)}>
                            <Icon icon={closeFill} />
                        </MIconButton>
                    ),
                });
            }
            else {
                setIdentificationOpen(false);
                dispatch(getCustomerById(userId));
                enqueueSnackbar("Saved!", {
                    variant: "success",
                    action: (key) => (
                        <MIconButton size="small" onClick={() => closeSnackbar(key)}>
                            <Icon icon={closeFill} />
                        </MIconButton>
                    ),
                });
            }
        },
    });

    const formikBank = useFormik<BankInitialValues>({
        enableReinitialize: true,
        initialValues: {
            senderSwiftCode: customerById?.senderBank && customerById?.senderBank[0]?.senderSwiftCode,
            senderSwiftBIC8Code: customerById?.senderBank && customerById?.senderBank[0]?.senderSwiftBIC8Code,
            senderBankIdentifier: customerById?.senderBank && customerById?.senderBank[0]?.senderBankIdentifier,
            senderBankName: customerById?.senderBank && customerById?.senderBank[0]?.senderBankName,
            senderBankCode: customerById?.senderBank && customerById?.senderBank[0]?.senderBankCode,
            senderBranchName: customerById?.senderBank && customerById?.senderBank[0]?.senderBranchName,
            senderBranchCode: customerById?.senderBank && customerById?.senderBank[0]?.senderBranchCode,
            senderIBAN: customerById?.senderBank && customerById?.senderBank[0]?.senderIBAN,
            senderIFSC: customerById?.senderBank && customerById?.senderBank[0]?.senderIFSC,
            senderAccountNumber: customerById?.senderBank && customerById?.senderBank[0]?.senderAccountNumber,
            senderAccountType: customerById?.senderBank && customerById?.senderBank[0]?.senderAccountType,
            senderVirtualPaymentAccount: customerById?.senderBank && customerById?.senderBank[0]?.senderVirtualPaymentAccount,
            senderAdditionalKey: 'katakanaName',
            senderAdditionalValue: customerById?.senderBank && customerById?.senderAdditional[0]?.katakanaName,
        },

        validationSchema: Yup.object().shape({

            // senderSwiftCode: Yup.string()
            //     .required("*Required"),

            //     senderSwiftBIC8Code: Yup.string()
            //     .required("*Required"),

            //     senderBankIdentifier: Yup.string()
            //     .required("*Required"),

            //     senderBankName: Yup.string()
            //     .required("*Required"),
        }),

        onSubmit: async (values) => {
            let response = await dispatch(editBankInfo(userId, values));
            if (response?.hasError) {
                enqueueSnackbar(response.message, {
                    variant: "error",
                    action: (key) => (
                        <MIconButton size="small" onClick={() => closeSnackbar(key)}>
                            <Icon icon={closeFill} />
                        </MIconButton>
                    ),
                });
            }
            else {
                setBankOpen(false);
                dispatch(getCustomerById(userId));
                enqueueSnackbar("Saved!", {
                    variant: "success",
                    action: (key) => (
                        <MIconButton size="small" onClick={() => closeSnackbar(key)}>
                            <Icon icon={closeFill} />
                        </MIconButton>
                    ),
                });
            }

        },
    });

    const handleAddressCountryValue = (e: any, values: any) => {
        if (values !== null) {
            console.log(values.alpha3);
            formikAddress.values.senderCountry = values.alpha3;
        }
    }

    const handleIDCountryValue = (e: any, values: any) => {
        if (values !== null) {
            console.log(values.alpha3);
            formikIdentification.values.senderIdCountry = values.alpha3;
        }
    }
    return (

        <>
            <Page title="Customer Details">
                <Container maxWidth="xl">
                    <HeaderBreadcrumbs
                        heading="Customer Details"
                        links={[
                            // { name: 'Amount', href: PATH_DASHBOARD.user.root },
                            // { name: 'Delivery Options', href: PATH_DASHBOARD.user.root },
                        ]} />


                    <Grid >
                        <Box
                            sx={{
                                flexGrow: 1,
                                width: '100%',
                                //  maxWidth: 320
                            }}
                        >
                            <Tabs
                                allowScrollButtonsMobile
                                value={valueScrollable}
                                variant="scrollable"
                                scrollButtons="auto"
                                onChange={handleChangeScrollable}
                            >
                                {SCROLLABLE_TAB.map((tab) => (
                                    <Tab key={tab.value} label={tab.label} value={tab.value} />
                                ))}
                            </Tabs>
                        </Box>
                    </Grid>
                    {(customerById !== null && customerById.userId !== 0) &&
                        <RootStyle title="Customer Details ">
                            <Container
                            //  maxWidth="sm"
                            >

                                <ContentStyle>
                                    <Stack direction="row" alignItems="center" sx={{ mb: 2 }}>
                                        <Grid item xs={12} md={6} lg={12}>

                                            {valueScrollable === '1' &&
                                                <>
                                                    <Card sx={{ p: 3, overflow: 'visible', marginTop: 3 }}>
                                                        <Grid container>
                                                            <Grid item xs={12}>
                                                                <Box
                                                                    sx={{
                                                                        width: '100%',
                                                                    }}
                                                                >
                                                                    <Stack spacing={2}>

                                                                        <RowStyle>
                                                                            <Typography variant="h6" sx={{ color: 'text.secondary', paddingTop: '20px' }}>
                                                                                {/* {translate(`dashboard.${recipientInformation}`)} */}
                                                                                Basic Information
                                                                            </Typography>
                                                                            <Box paddingTop={1}>
                                                                                <Button size="medium" endIcon={<Icon icon={editFill} />} onClick={handleBasicOpen}>
                                                                                    Edit</Button>
                                                                            </Box>
                                                                        </RowStyle>
                                                                        {customerById !== null &&
                                                                            <>
                                                                                <RowStyle>
                                                                                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                                                                        {translate(`dashboard.${name}`)}
                                                                                    </Typography>
                                                                                    <Typography variant="body2">
                                                                                        {customerById.senderFirstName} {customerById.senderMiddleName} {customerById.senderLastName}</Typography>
                                                                                </RowStyle>
                                                                                <RowStyle>
                                                                                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                                                                        {translate(`dashboard.${gender}`)}
                                                                                    </Typography>
                                                                                    <Typography variant="body2">
                                                                                        {customerById.senderGender}</Typography>
                                                                                </RowStyle>
                                                                                <RowStyle>
                                                                                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                                                                        {translate(`dashboard.${DOB}`)}
                                                                                    </Typography>
                                                                                    <Typography variant="body2">
                                                                                        {customerById.senderDOB}</Typography>
                                                                                </RowStyle>
                                                                                <RowStyle>
                                                                                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                                                                        {translate(`dashboard.${Nationality}`)}
                                                                                    </Typography>
                                                                                    <Typography variant="body2">
                                                                                        {customerById.senderNationality}</Typography>
                                                                                </RowStyle>
                                                                            </>}
                                                                        {(customerById.senderContact !== null) ? <RowStyle>
                                                                            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                                                                {translate(`dashboard.${email}`)}
                                                                            </Typography>
                                                                            <Typography variant="body2">
                                                                                {customerById.senderContact[0].senderEmail}</Typography>
                                                                        </RowStyle> : null}
                                                                        {(customerById.senderContact !== null) ? <RowStyle>
                                                                            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                                                                {translate(`dashboard.${phoneNumber}`)}
                                                                            </Typography>
                                                                            <Typography variant="body2">
                                                                                {customerById.senderContact[0].senderPhoneNumber}</Typography>
                                                                        </RowStyle> : null}

                                                                        <br />

                                                                    </Stack>
                                                                </Box>
                                                            </Grid>


                                                        </Grid>
                                                    </Card>
                                                    {customerById.senderAddress !== null &&
                                                        <Card sx={{ p: 3, overflow: 'visible', marginTop: 3 }}>
                                                            <Grid container>
                                                                <Grid item xs={12}>
                                                                    <Box
                                                                        sx={{
                                                                            width: '100%',
                                                                        }}
                                                                    >
                                                                        <Stack spacing={2}>

                                                                            <RowStyle>
                                                                                <Typography variant="h6" sx={{ color: 'text.secondary', paddingTop: '20px' }}>
                                                                                    {/* {translate(`dashboard.${recipientInformation}`)} */}
                                                                                    Address
                                                                                </Typography>
                                                                                <Box paddingTop={1}>
                                                                                    <Button size="medium" endIcon={<Icon icon={editFill} />} onClick={handleAddressOpen}>
                                                                                        Edit</Button>
                                                                                </Box>
                                                                            </RowStyle>

                                                                            <>
                                                                                <RowStyle>
                                                                                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                                                                        {/* {translate(`dashboard.${name}`)} */}
                                                                                        Address 1
                                                                                    </Typography>
                                                                                    <Typography variant="body2">
                                                                                        {customerById.senderAddress[0].senderAddress1}</Typography>
                                                                                </RowStyle>
                                                                                <RowStyle>
                                                                                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                                                                        {/* {translate(`dashboard.${gender}`)} */}
                                                                                        Address 2
                                                                                    </Typography>
                                                                                    <Typography variant="body2">
                                                                                        {customerById.senderAddress[0].senderAddress2}</Typography>
                                                                                </RowStyle>
                                                                                <RowStyle>
                                                                                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                                                                        {/* {translate(`dashboard.${DOB}`)} */}
                                                                                        Country
                                                                                    </Typography>
                                                                                    <Typography variant="body2">
                                                                                        {customerById.senderAddress[0].senderCountry}</Typography>
                                                                                </RowStyle>
                                                                                <RowStyle>
                                                                                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                                                                        {/* {translate(`dashboard.${Natioanality}`)} */}
                                                                                        State
                                                                                    </Typography>
                                                                                    <Typography variant="body2">
                                                                                        {customerById.senderAddress[0].senderStateProvince}</Typography>
                                                                                </RowStyle>
                                                                                <RowStyle>
                                                                                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                                                                        {/* {translate(`dashboard.${Natioanality}`)} */}
                                                                                        Zip Code
                                                                                    </Typography>
                                                                                    <Typography variant="body2">
                                                                                        {customerById.senderAddress[0].senderZipCode}</Typography>
                                                                                </RowStyle>
                                                                                <RowStyle>
                                                                                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                                                                        {/* {translate(`dashboard.${Natioanality}`)} */}
                                                                                        City
                                                                                    </Typography>
                                                                                    <Typography variant="body2">
                                                                                        {customerById.senderAddress[0].senderCity}</Typography>
                                                                                </RowStyle>
                                                                            </>


                                                                            <br />

                                                                        </Stack>
                                                                    </Box>
                                                                </Grid>


                                                            </Grid>
                                                        </Card>
                                                    }
                                                    {customerById.senderIdentificationDoc !== null &&
                                                        <Card sx={{ p: 3, overflow: 'visible', marginTop: 3 }}>
                                                            <Grid container>
                                                                <Grid item xs={12}>
                                                                    <Box
                                                                        sx={{
                                                                            width: '100%',
                                                                        }}
                                                                    >
                                                                        <Stack spacing={2}>

                                                                            <RowStyle>
                                                                                <Typography variant="h6" sx={{ color: 'text.secondary', paddingTop: '20px' }}>
                                                                                    {/* {translate(`dashboard.${recipientInformation}`)} */}
                                                                                    Identification Details
                                                                                </Typography>
                                                                                <Button size="medium" endIcon={<Icon icon={editFill} />} onClick={handleIdentificationOpen}>
                                                                                    Edit</Button>
                                                                            </RowStyle>

                                                                            <>
                                                                                <RowStyle>
                                                                                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                                                                        {/* {translate(`dashboard.${DOB}`)} */}
                                                                                        Identification Document type
                                                                                    </Typography>
                                                                                    <Typography variant="body2">
                                                                                        {customerById.senderIdentificationDoc[0].senderIdType}</Typography>
                                                                                </RowStyle>
                                                                                <RowStyle>
                                                                                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                                                                        {/* {translate(`dashboard.${DOB}`)} */}
                                                                                        Identification Document Id
                                                                                    </Typography>
                                                                                    <Typography variant="body2">
                                                                                        {customerById.senderIdentificationDoc[0].senderIdNo}</Typography>
                                                                                </RowStyle>
                                                                                <RowStyle>
                                                                                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                                                                        {/* {translate(`dashboard.${gender}`)} */}
                                                                                        Id Expiry
                                                                                    </Typography>
                                                                                    <Typography variant="body2">
                                                                                        {customerById.senderIdentificationDoc[0].senderIdExpiry}</Typography>
                                                                                </RowStyle>
                                                                                <RowStyle>
                                                                                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                                                                        {/* {translate(`dashboard.${name}`)} */}
                                                                                        Country
                                                                                    </Typography>
                                                                                    <Typography variant="body2">
                                                                                        {customerById.senderIdentificationDoc[0].senderIdCountry}</Typography>
                                                                                </RowStyle>
                                                                            </>


                                                                            <br />

                                                                        </Stack>
                                                                    </Box>
                                                                </Grid>


                                                            </Grid>
                                                        </Card>
                                                    }

                                                </>
                                            }
                                            {valueScrollable === '2' &&
                                                <>
                                                    {customerById.senderBank !== null ?
                                                        <Card sx={{ p: 3, overflow: 'visible', marginTop: 3 }}>
                                                            <Grid container >
                                                                <Grid item xs={12}>
                                                                    <Box
                                                                        sx={{
                                                                            width: '100%',

                                                                        }}
                                                                    >
                                                                        <Stack spacing={2}>

                                                                            <RowStyle>
                                                                                <Typography variant="h6" sx={{ color: 'text.secondary', paddingTop: '20px' }}>
                                                                                    {/* {translate(`dashboard.${recipientInformation}`)} */}
                                                                                    Bank Details
                                                                                </Typography>
                                                                                <Button size="medium" endIcon={<Icon icon={editFill} />} onClick={handleBankOpen}>
                                                                                    Edit</Button>
                                                                            </RowStyle>
                                                                            {customerById.senderBank !== null && customerById.senderBank[0] &&
                                                                                <>
                                                                                    {customerById.senderBank[0].senderBankName &&
                                                                                        <RowStyle>
                                                                                            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                                                                                {translate(`dashboard.${bankName}`)}
                                                                                            </Typography>
                                                                                            <Typography variant="body2">
                                                                                                {customerById.senderBank[0].senderBankName}</Typography>
                                                                                        </RowStyle>}
                                                                                    {customerById.senderBank[0].senderBankCode &&
                                                                                        <RowStyle>
                                                                                            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                                                                                {translate(`dashboard.${bankCode}`)}
                                                                                            </Typography>
                                                                                            <Typography variant="body2">
                                                                                                {customerById.senderBank[0].senderBankCode}</Typography>
                                                                                        </RowStyle>}
                                                                                    {/* <RowStyle>
                                                                                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                                                                    {translate(`dashboard.${bankId}`)}
                                                                                </Typography>
                                                                                <Typography variant="body2">
                                                                                    {customerById.senderBank[0].senderBankId}</Typography>
                                                                            </RowStyle> */}
                                                                                    {customerById.senderBank[0].senderAccountNumber &&
                                                                                        <RowStyle>
                                                                                            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                                                                                {translate(`dashboard.${accountNumberText}`)}
                                                                                            </Typography>
                                                                                            <Typography variant="body2">
                                                                                                {customerById.senderBank[0].senderAccountNumber}</Typography>
                                                                                        </RowStyle>}
                                                                                    {customerById.senderBank[0].senderAccountType &&
                                                                                        <RowStyle>
                                                                                            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                                                                                {translate(`dashboard.${accountType}`)}
                                                                                            </Typography>
                                                                                            <Typography variant="body2">
                                                                                                {customerById.senderBank[0].senderAccountType}</Typography>
                                                                                        </RowStyle>}
                                                                                    {customerById.senderBank[0].senderBranchCode &&
                                                                                        <RowStyle>
                                                                                            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                                                                                {translate(`dashboard.${branchCode}`)}
                                                                                            </Typography>
                                                                                            <Typography variant="body2">
                                                                                                {customerById.senderBank[0].senderBranchCode}</Typography>
                                                                                        </RowStyle>}
                                                                                    {customerById.senderBank[0].senderIFSC &&
                                                                                        <RowStyle>
                                                                                            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                                                                                {translate(`dashboard.${ifscCode}`)}
                                                                                            </Typography>
                                                                                            <Typography variant="body2">
                                                                                                {customerById.senderBank[0].senderIFSC}</Typography>
                                                                                        </RowStyle>}

                                                                                    {customerById.senderBank[0].senderSwiftCode &&
                                                                                        <RowStyle>
                                                                                            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                                                                                {translate(`dashboard.${Nationality}`)}
                                                                                            </Typography>
                                                                                            <Typography variant="body2">
                                                                                                {customerById.senderBank[0].senderSwiftCode}</Typography>
                                                                                        </RowStyle>}

                                                                                    {customerById.senderAdditional && customerById.senderAdditional[0] &&
                                                                                        <RowStyle>
                                                                                            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                                                                                {translate(`dashboard.${katakanaName}`)}
                                                                                            </Typography>
                                                                                            {customerById.senderAdditional.map(data =>
                                                                                                <Typography variant="body2">
                                                                                                    {data.katakanaName}</Typography>)}
                                                                                        </RowStyle>}
                                                                                </>

                                                                            }



                                                                        </Stack>
                                                                    </Box>
                                                                </Grid>


                                                            </Grid>
                                                        </Card>
                                                        :
                                                        <div className="empty-image-container" style={{ paddingTop: 30 }}>
                                                            <img
                                                                src="/static/illustrations/no-data.svg"
                                                                alt="empty"
                                                                width='300px'
                                                            />
                                                        </div>
                                                    }
                                                </>}
                                            {valueScrollable === '3' &&
                                                <>
                                                    {/* Api Checking */}
                                                    {receiversOfCustomer.length > 0 ?
                                                        <Grid container
                                                            spacing={2}
                                                            direction="row"
                                                            alignItems="flex-start"

                                                        >
                                                            {receiversOfCustomer.map((data) => (

                                                                <Grid key={data.receiverId} item xs={12} md={4} >
                                                                    <div key={data.receiverId} onClick={() => handleNextForm(data.receiverId)}>
                                                                        <Card key={data.receiverId} sx={{ cursor: 'pointer', display: 'flex', flexDirection: 'row', alignItems: 'center', p: 3, marginBottom: '30px', marginTop: 3 }} className="card-Shadow">
                                                                            <Box sx={{ flexGrow: 1 }}>
                                                                                <Stack direction="row" alignItems="center" flexWrap="wrap">
                                                                                    <IconWrapperStyle
                                                                                        sx={{
                                                                                            color: 'primary.main',
                                                                                            bgcolor: (theme) => alpha(theme.palette.primary.main, 0.16),
                                                                                            marginRight: '30px',
                                                                                            padding: '25px'
                                                                                        }}
                                                                                    >
                                                                                        <h3>{data.receiverFirstName.slice(0, 1).toUpperCase()}</h3>

                                                                                    </IconWrapperStyle>
                                                                                    <Stack  >
                                                                                        <Typography variant="h6" component="span">
                                                                                            {data.receiverFirstName}  {data.receiverMiddleName}  {data.receiverLastName}
                                                                                        </Typography>
                                                                                        {data.receiverBank.map((bankData) => (

                                                                                            <Typography key={bankData.receiverBankId} variant="body2" component="span">
                                                                                                {bankData.receiverAccountNumber ? <p>Account no: **** {bankData.receiverAccountNumber.substr(-5)}</p> : <p> {bankData.receiverVirtualPaymentAccount}</p>}
                                                                                            </Typography>
                                                                                        ))}
                                                                                    </Stack>
                                                                                </Stack>
                                                                            </Box>
                                                                            {/* <Typography variant="body1" component="span" sx={{ color: 'text.secondary', width: '10px' }}>
                                            <ArrowForwardIosIcon />
                                        </Typography> */}

                                                                        </Card>
                                                                    </div>
                                                                </Grid>

                                                            ))}


                                                        </Grid>
                                                        :
                                                        <div className="empty-image-container" style={{ paddingTop: 30 }}>
                                                            <img
                                                                src="/static/illustrations/no-data.svg"
                                                                alt="empty"
                                                                width='300px'
                                                            />
                                                        </div>
                                                    }
                                                </>
                                            }
                                            {valueScrollable === '4' &&
                                                // <div className="empty-image-container">
                                                //     <img
                                                //         src="/static/illustrations/in-progress.svg"
                                                //         alt="empty"
                                                //         width='300px'
                                                //     />
                                                // </div>
                                                <>
                                                    {recentTransactionOfCustomer.length > 0 ?

                                                        <Grid container direction="row"
                                                            margin={'auto'} md={8} lg={8}>


                                                            {recentTransactionOfCustomer.map((row) => {
                                                                const transaction_id_list = row.remittanceOrderId.split('-');
                                                                const transaction_id = transaction_id_list[transaction_id_list?.length - 1]?.toUpperCase();
                                                                const status = row.status as remittanceStatusType;
                                                                const colorPicker: LabelColor | undefined = statusColorThemes[status] as LabelColor | undefined
                                                                return (
                                                                    <Grid item xs={12}>
                                                                        <Card sx={{ cursor: "pointer", padding: 3, margin: 2, marginTop: 3 }} onClick={() => { fetchOrderDetails(row.remittanceOrderId) }}>
                                                                            <Box display={'flex'} sx={{ justifyContent: "space-between", alignItems: "center" }}>
                                                                                {/* <Box>
                                                                                    <Typography sx={{ fontSize: 24, padding: 1, width: 45, height: 45, justifyContent: "center", textAlign: "center", fontWeight: "bold", backgroundColor: "rgba(0, 171, 85, 0.08)", color: "#00AB55", borderRadius: "50%" }}>{row?.receiverName[0]?.toUpperCase()}</Typography>
                                                                                </Box> */}
                                                                                <IconWrapperStyle
                                                                                    sx={{
                                                                                        color: 'primary.main',
                                                                                        bgcolor: (theme) => alpha(theme.palette.primary.main, 0.16),
                                                                                        marginRight: '30px',
                                                                                        padding: '25px'
                                                                                    }}
                                                                                >
                                                                                    <h2>{row?.receiverName[0]?.toUpperCase()}</h2>

                                                                                </IconWrapperStyle>
                                                                                <Box>
                                                                                    <Typography variant='h6'>{row.receiverName?.toUpperCase()}</Typography>
                                                                                    <Typography>{transaction_id}</Typography>
                                                                                </Box>
                                                                                <Box>
                                                                                    <Typography variant='h6'>{row.amount} {row.outboundCurrency}</Typography>
                                                                                    <Typography variant='caption'>{row.date}</Typography>
                                                                                </Box>
                                                                                <Box>
                                                                                    <Label
                                                                                        variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
                                                                                        color={colorPicker}
                                                                                    >
                                                                                        {sentenceCase(row.status)}
                                                                                    </Label>
                                                                                </Box>
                                                                            </Box>
                                                                        </Card>
                                                                    </Grid>



                                                                )
                                                            })}




                                                        </Grid> :
                                                        <div className="empty-image-container" style={{ paddingTop: 30 }}>
                                                            <img
                                                                src="/static/illustrations/no-data.svg"
                                                                alt="empty"
                                                                width='300px'
                                                            />
                                                        </div>
                                                    }
                                                </>
                                            }
                                        </Grid>
                                    </Stack>
                                </ContentStyle>
                            </Container>
                        </RootStyle>
                    }

                </Container>
                <Modal
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    open={basicOpen}
                    onClose={handleBasicClose}
                >
                    <Card style={modalStyle} className={classes.paper}>
                        <form onSubmit={formikBasicInfo.handleSubmit}>
                            <Stack spacing={3}>
                                <Grid item xs={12}>
                                    <TextField
                                        type="senderFirstName"
                                        label={translate(`dashboard.${firstNameText}`)}
                                        fullWidth
                                        {...formikBasicInfo.getFieldProps("senderFirstName")}
                                        error={Boolean(formikBasicInfo.touched.senderFirstName && formikBasicInfo.errors.senderFirstName)}
                                        helperText={formikBasicInfo.touched.senderFirstName && formikBasicInfo.errors.senderFirstName}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <TextField
                                        type="senderMiddleName"
                                        label={translate(`dashboard.${middleNameText}`)}
                                        fullWidth
                                        {...formikBasicInfo.getFieldProps("senderMiddleName")}
                                        error={Boolean(formikBasicInfo.touched.senderMiddleName && formikBasicInfo.errors.senderMiddleName)}
                                        helperText={formikBasicInfo.touched.senderMiddleName && formikBasicInfo.errors.senderMiddleName}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <TextField
                                        type="senderLastName"
                                        label={translate(`dashboard.${lastNameText}`)}
                                        fullWidth
                                        {...formikBasicInfo.getFieldProps("senderLastName")}
                                        error={Boolean(formikBasicInfo.touched.senderLastName && formikBasicInfo.errors.senderLastName)}
                                        helperText={formikBasicInfo.touched.senderLastName && formikBasicInfo.errors.senderLastName}
                                    />
                                </Grid>


                            </Stack>

                            <Grid item marginTop={5} textAlign='center'>
                                <LoadingButton
                                    fullWidth
                                    size="medium"
                                    type="submit"
                                    variant="contained"
                                    loading={formikBasicInfo.isSubmitting}
                                >
                                    Submit
                                </LoadingButton>
                            </Grid>
                        </form>
                    </Card>
                </Modal>

                <Modal
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    open={addressOpen}
                    onClose={handleAddressClose}
                >
                    <Card style={modalStyle} className={classes.paper}>
                        <form onSubmit={formikAddress.handleSubmit}>
                            <Stack spacing={3}>
                                <Grid item xs={12}>
                                    <TextField
                                        type="senderAddress1"
                                        label={translate(`dashboard.${addressLine1Text}`)}
                                        fullWidth
                                        {...formikAddress.getFieldProps("senderAddress1")}
                                        error={Boolean(formikAddress.touched.senderAddress1 && formikAddress.errors.senderAddress1)}
                                        helperText={formikAddress.touched.senderAddress1 && formikAddress.errors.senderAddress1}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <TextField
                                        type="senderAddress2"
                                        label={translate(`dashboard.${addressLine2Text}`)}
                                        fullWidth
                                        {...formikAddress.getFieldProps("senderAddress2")}
                                        error={Boolean(formikAddress.touched.senderAddress2 && formikAddress.errors.senderAddress2)}
                                        helperText={formikAddress.touched.senderAddress2 && formikAddress.errors.senderAddress2}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <TextField
                                        type="senderCity"
                                        label={translate(`dashboard.${cityText}`)}
                                        fullWidth
                                        {...formikAddress.getFieldProps("senderCity")}
                                        error={Boolean(formikAddress.touched.senderCity && formikAddress.errors.senderCity)}
                                        helperText={formikAddress.touched.senderCity && formikAddress.errors.senderCity}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <TextField
                                        type="senderStateProvince"
                                        label={translate(`dashboard.${stateProvince}`)}
                                        fullWidth
                                        {...formikAddress.getFieldProps("senderStateProvince")}
                                        error={Boolean(formikAddress.touched.senderStateProvince && formikAddress.errors.senderStateProvince)}
                                        helperText={formikAddress.touched.senderStateProvince && formikAddress.errors.senderStateProvince}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <TextField
                                        type="senderZipCode"
                                        label={translate(`dashboard.${zipCodeText}`)}
                                        fullWidth
                                        {...formikAddress.getFieldProps("senderZipCode")}
                                        error={Boolean(formikAddress.touched.senderZipCode && formikAddress.errors.senderZipCode)}
                                        helperText={formikAddress.touched.senderZipCode && formikAddress.errors.senderZipCode}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <Box
                                        sx={{
                                            width: '100%',
                                            '& .MuiAutocomplete-option': {
                                                typography: 'body2',
                                                '& > span': { mr: 1, fontSize: 20 }
                                            }
                                        }}
                                    >
                                        <Autocomplete
                                            fullWidth
                                            disablePortal
                                            autoHighlight
                                            options={countryList}
                                            onChange={handleAddressCountryValue}
                                            getOptionLabel={(countryList) => countryList.name}
                                            renderOption={(props, countryList) => (
                                                <li {...props}>
                                                    <span>{countryToFlag(countryList.alpha3)}</span>
                                                    {countryList.name} ({countryList.alpha3})
                                                </li>
                                            )}

                                            renderInput={(params) => (
                                                <TextField
                                                    type="senderCountry"
                                                    {...formikAddress.getFieldProps('senderCountry')}
                                                    {...params}
                                                    label={translate(`dashboard.${country}`)}
                                                    inputProps={{
                                                        ...params.inputProps,
                                                        autoComplete: 'new-password'
                                                    }}
                                                    SelectProps={{ native: true }}
                                                    error={Boolean(formikAddress.touched.senderCountry && formikAddress.errors.senderCountry)}
                                                    helperText={formikAddress.touched.senderCountry && formikAddress.errors.senderCountry}
                                                />
                                            )}
                                        />
                                    </Box>
                                </Grid>
                            </Stack>

                            <Grid item marginTop={5} textAlign='center'>
                                <LoadingButton
                                    fullWidth
                                    size="medium"
                                    type="submit"
                                    variant="contained"
                                    loading={formikAddress.isSubmitting}
                                >
                                    Submit
                                </LoadingButton>
                            </Grid>
                        </form>
                    </Card>
                </Modal>

                <Modal
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    open={identificationOpen}
                    onClose={handleIdentificationClose}
                >
                    <Card style={modalStyle} className={classes.paper}>
                        <form onSubmit={formikIdentification.handleSubmit}>
                            <Stack spacing={3}>
                                <Grid item xs={12}>
                                    <TextField
                                        type="senderIdType"
                                        label={translate(`dashboard.${selectIdentificationType}`)}
                                        fullWidth
                                        {...formikIdentification.getFieldProps("senderIdType")}
                                        error={Boolean(formikIdentification.touched.senderIdType && formikIdentification.errors.senderIdType)}
                                        helperText={formikIdentification.touched.senderIdType && formikIdentification.errors.senderIdType}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <TextField
                                        type="senderIdNo"
                                        label={translate(`dashboard.${uniqueIdNumber}`)}
                                        fullWidth
                                        {...formikIdentification.getFieldProps("senderIdNo")}
                                        error={Boolean(formikIdentification.touched.senderIdNo && formikIdentification.errors.senderIdNo)}
                                        helperText={formikIdentification.touched.senderIdNo && formikIdentification.errors.senderIdNo}
                                    />
                                </Grid>


                                <Grid item xs={12}>
                                    <TextField
                                        id="senderIdExpiry"
                                        label={translate(`dashboard.${idExpiryDate}`)}
                                        fullWidth
                                        type="date"
                                        {...formikIdentification.getFieldProps("senderIdExpiry")}
                                        error={Boolean(formikIdentification.touched.senderIdExpiry && formikIdentification.errors.senderIdExpiry)}
                                        helperText={formikIdentification.touched.senderIdExpiry && formikIdentification.errors.senderIdExpiry}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <Box
                                        sx={{
                                            width: '100%',
                                            '& .MuiAutocomplete-option': {
                                                typography: 'body2',
                                                '& > span': { mr: 1, fontSize: 20 }
                                            }
                                        }}
                                    >
                                        <Autocomplete
                                            fullWidth
                                            disablePortal
                                            autoHighlight
                                            options={countryList}
                                            onChange={handleIDCountryValue}
                                            getOptionLabel={(countryList) => countryList.name}
                                            renderOption={(props, countryList) => (
                                                <li {...props}>
                                                    <span>{countryToFlag(countryList.alpha3)}</span>
                                                    {countryList.name} ({countryList.alpha3})
                                                </li>
                                            )}

                                            renderInput={(params) => (
                                                <TextField
                                                    type="senderIdCountry"
                                                    {...formikIdentification.getFieldProps('senderIdCountry')}
                                                    {...params}
                                                    label={translate(`dashboard.${country}`)}
                                                    inputProps={{
                                                        ...params.inputProps,
                                                        autoComplete: 'new-password'
                                                    }}
                                                    SelectProps={{ native: true }}
                                                    error={Boolean(formikIdentification.touched.senderIdCountry && formikIdentification.errors.senderIdCountry)}
                                                    helperText={formikIdentification.touched.senderIdCountry && formikIdentification.errors.senderIdCountry}
                                                />
                                            )}
                                        />
                                    </Box>
                                </Grid>
                            </Stack>

                            <Grid item marginTop={5} textAlign='center'>
                                <LoadingButton
                                    fullWidth
                                    size="medium"
                                    type="submit"
                                    variant="contained"
                                    loading={formikAddress.isSubmitting}
                                >
                                    Submit
                                </LoadingButton>
                            </Grid>
                        </form>
                    </Card>
                </Modal>

                <Modal
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    open={bankOpen}
                    onClose={handleBankClose}
                >
                    <Card style={modalStyle} className={classes.paper}>
                        <form onSubmit={formikBank.handleSubmit}>
                            <Stack spacing={3}>

                                <Grid item xs={12}>
                                    <TextField
                                        type="senderBankCode"
                                        label={translate(`dashboard.${bankCodeText}`)}
                                        fullWidth
                                        {...formikBank.getFieldProps("senderBankCode")}
                                        error={Boolean(formikBank.touched.senderBankCode && formikBank.errors.senderBankCode)}
                                        helperText={formikBank.touched.senderBankCode && formikBank.errors.senderBankCode}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <TextField
                                        type="senderAccountNumber"
                                        label={translate(`dashboard.${accountNumberText}`)}
                                        fullWidth
                                        {...formikBank.getFieldProps("senderAccountNumber")}
                                        error={Boolean(formikBank.touched.senderAccountNumber && formikBank.errors.senderAccountNumber)}
                                        helperText={formikBank.touched.senderAccountNumber && formikBank.errors.senderAccountNumber}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <TextField
                                        type="senderAdditionalValue"
                                        label={translate(`dashboard.${katakanaName}`)}
                                        fullWidth
                                        {...formikBank.getFieldProps("senderAdditionalValue")}
                                        error={Boolean(formikBank.touched.senderAdditionalValue && formikBank.errors.senderAdditionalValue)}
                                        helperText={formikBank.touched.senderAdditionalValue && formikBank.errors.senderAdditionalValue}
                                    />
                                </Grid>

                            </Stack>

                            <Grid item marginTop={5} textAlign='center'>
                                <LoadingButton
                                    fullWidth
                                    size="medium"
                                    type="submit"
                                    variant="contained"
                                    loading={formikBank.isSubmitting}
                                >
                                    Submit
                                </LoadingButton>
                            </Grid>
                        </form>
                    </Card>
                </Modal>
            </Page>

        </>
    );
}