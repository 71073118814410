import { map, filter } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
// utils
import axios from '../../utils/axios';
import { Customer } from '../../@types/customer';
import URLConstants from 'constants/urlConstants';

type CustomerState = {
    isLoading: boolean;
    error: boolean;
    customer: Customer;
}

const initialState: CustomerState = {
    isLoading: false,
    error: false,
    customer: {
        "senderId": 0,
        "userId": 0,
        "senderFirstName": "",
        "senderMiddleName": '',
        "senderLastName": "",
        "senderDOB": '',
        "senderGender": '',
        "senderNationality": '',
        'senderOccupation': '',
        'senderStatusId': 0,
        "createTime": new Date(),
        "updateTime": new Date(),
        "senderAddress": [{
            "senderAddressId": 0,
            "senderId": 0,
            "senderAddress1": "",
            "senderAddress2": "",
            "senderCity": "",
            "senderStateProvince": "",
            "senderZipCode": "",
            "senderCountry": "",
            "createTime": new Date(),
            "updateTime": new Date(),
        }],
        "senderIdentificationDoc": [{
            "senderIdentificationId": 0,
            "senderId": 0,
            "senderIdType": "",
            "senderIdNo": "",
            "senderIdExpiry": "",
            "senderIdCountry": "",
            "createTime": new Date(),
            "updateTime": new Date(),
        }],
        "senderContact": [{
            "senderContactId": 0,
            "senderId": 0,
            "senderPhoneNumber": "",
            "senderEmail": "",
            "createTime": new Date(),
            "updateTime": new Date(),
        }],

    }
}

const slice = createSlice({
    name: 'customer',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        // GET CUSTOMER
        getCustomerSuccess(state, action) {
            state.isLoading = false;
            state.customer = action.payload;
        },
    }
});

// Reducer
export default slice.reducer;


export function getCustomer() {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(URLConstants.CUSTOMER_BASE_URL);
            dispatch(slice.actions.getCustomerSuccess(response.data.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}