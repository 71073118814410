import faker from 'faker';
// material
import {
  Box,
  Card,
  Table,
  Avatar,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  CardHeader,
  Typography,
  TableContainer,
  Pagination,
  Grid,
  Container,
  OutlinedInput,
  InputAdornment,
  Toolbar,
} from '@material-ui/core';
// utils
//
import Label from '../../components/Label';
import Scrollbar from '../../components/Scrollbar';
import { Block } from 'components/Block';
import { RootState, useDispatch, useSelector } from 'redux/store';
import { getAllCustomer, searchCustomers } from 'redux/slices/allCustomersBO';
import { useEffect, useState } from 'react';
import { MAvatar } from 'components/@material-extend';
import createAvatar from 'utils/createAvatar';
import { useNavigate } from 'react-router';
import { Icon } from '@iconify/react';
import searchFill from '@iconify/icons-eva/search-fill';
import { useTheme, experimentalStyled as styled } from '@material-ui/core/styles';
import useLocales from 'hooks/useLocales';
// ----------------------------------------------------------------------

const style = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexWrap: 'wrap',
  '& > *': { my: 1 }
} as const;


const RootStyle = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 1)
}));

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter
  }),
  '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`
  }
}));




// ----------------------------------------------------------------------

export default function CustomersList() {
  const navigate = useNavigate();
  const theme = useTheme();
  const dispatch = useDispatch();
  const { allCustomers } = useSelector(
    (state: RootState) => state.allCustomers
  );
  const [name, setName] = useState('');
  const [userId, setUserId] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const { translate } = useLocales();


  useEffect(() => {
    let page = 1;
    dispatch(getAllCustomer(page));
  }, [dispatch]);

  console.log(allCustomers);

  function handleNextForm(receiverId: number) {
    navigate(`/dashboard/customerDetails/${receiverId}`);
  }


  const onFilterName = (name: string) => {
    console.log(name);
    setName(name);
    dispatch(searchCustomers(userId, 1, name,email,phoneNumber));
  }

  const onFilterUserId = (userId: string) => {
    console.log(userId);
    setUserId(userId);
    dispatch(searchCustomers(userId, 1,name, email,phoneNumber));
  }

  const onFilterEmail = (email: string) => {
    console.log(email);
    setEmail(email)
    dispatch(searchCustomers(userId, 1,name, email,phoneNumber));
  }

  const onFilterPhoneNumber = (phoneNumber: string) => {
    console.log(phoneNumber);
    setPhoneNumber(phoneNumber)
    dispatch(searchCustomers(userId, 1,name,email, phoneNumber));
  }

  console.log(allCustomers);

  const paginationFunction = (e: any, page: number) => {
    if (userId || name || email || phoneNumber) {
      dispatch(searchCustomers(userId, page, name,email,phoneNumber));
    }
   else {
      dispatch(getAllCustomer(page));
    }
  }
  console.log("phoneNumber:", phoneNumber);
  console.log("name:", name);
  console.log("email:", email);
  return (
    <Card sx={{ pb: 3 }}>
      <Grid container sx={{ pb: 3, pt: 3}} >
        <Grid item md={3}  className='full-width' >
          {/* <UserListSearchByName /> */}
          <RootStyle>
            <SearchStyle
              // value={filterName}
              className='full-width'
              onChange={(e) => onFilterName(e.target.value)}
              placeholder= {translate(`dashboard.${"Search name..."}`)}
              startAdornment={
                <InputAdornment position="start">
                  <Box component={Icon} icon={searchFill} sx={{ color: 'text.disabled' }} />
                </InputAdornment>
              }
            />

          </RootStyle>
        </Grid>

        <Grid item md={3}  className='full-width'>
          {/* <UserListSearchByCustomerId /> */}
          <RootStyle
          >
            <SearchStyle
              // value={filterName}
              onChange={(e) => onFilterUserId(e.target.value)}
              className='full-width'
              placeholder={translate(`dashboard.${"Search customer ID..."}`)}
              startAdornment={
                <InputAdornment position="start">
                  <Box component={Icon} icon={searchFill} sx={{ color: 'text.disabled' }} />
                </InputAdornment>
              }
            />
          </RootStyle>
        </Grid>

        <Grid item md={3}  className='full-width'>
          {/* <UserListSearchByEmail /> */}
          <RootStyle
          >
            <SearchStyle
              // value={filterName}
              onChange={(e) => onFilterEmail(e.target.value)}
              className='full-width'
              placeholder={translate(`dashboard.${"Search email..."}`)}
              startAdornment={
                <InputAdornment position="start">
                  <Box component={Icon} icon={searchFill} sx={{ color: 'text.disabled' }} />
                </InputAdornment>
              }
            />

          </RootStyle>
        </Grid>

        <Grid item md={3}  className='full-width'>
          {/* <UserListSearchByPhoneNumber /> */}
          <RootStyle
          >
            <SearchStyle
              // value={filterName}
              onChange={(e) => onFilterPhoneNumber(e.target.value)}
              className='full-width'
              placeholder={translate(`dashboard.${"Search phone number..."}`)}
              startAdornment={
                <InputAdornment position="start">
                  <Box component={Icon} icon={searchFill} sx={{ color: 'text.disabled' }} />
                </InputAdornment>
              }
            />

          </RootStyle>
        </Grid>
      </Grid>


      <TableContainer sx={{ minWidth: 720 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{translate(`dashboard.${"Name"}`)}</TableCell>
              <TableCell>{translate(`dashboard.${"Customer ID"}`)}</TableCell>
              <TableCell>{translate(`dashboard.${"Email"}`)}</TableCell>
              <TableCell>{translate(`dashboard.${"Phone Number"}`)}</TableCell>
              {/* <TableCell align="right">Rank</TableCell> */}
            </TableRow>
          </TableHead>
          {allCustomers !== null &&
            <TableBody>
              {allCustomers.map((row) => (
                <TableRow key={row.userId}  onClick={() => handleNextForm(row.userId)} style={{ cursor: 'pointer' }}>
                  <TableCell>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      {/* <Avatar alt={row.senderFirstName}  /> */}
                      <MAvatar
                        src={row.senderFirstName.toUpperCase()}
                        alt={row.senderFirstName.toUpperCase()}
                        color={
                          createAvatar(row.senderFirstName).color
                        }
                      ></MAvatar>
                      <Box sx={{ ml: 2 }}>
                        <Typography variant="subtitle2"> {row.senderFirstName} {row.senderLastName}</Typography>
                      </Box>
                    </Box>
                  </TableCell>
                  <TableCell>{row.userId}</TableCell>
                  <TableCell>{row.senderEmail}</TableCell>
                  <TableCell>
                    {row.senderPhoneNumber}
                  </TableCell>

                  {/* <TableCell align="right">
                    <Label
                      variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
                      color={
                        (row.rank === 'top_1' && 'primary') ||
                        (row.rank === 'top_2' && 'info') ||
                        (row.rank === 'top_3' && 'success') ||
                        (row.rank === 'top_4' && 'warning') ||
                        'error'
                      }
                    >
                      {sentenceCase(row.rank)}
                    </Label>
                  </TableCell> */}
                </TableRow>
              ))}
            </TableBody>}
        </Table>

      </TableContainer>

      <Grid item xs={12}>
        <Box sx={style}>

          <Pagination onChange={(e, page) => paginationFunction(e, page)} count={20} color="primary" />

        </Box>
      </Grid>
    </Card>
  );
}