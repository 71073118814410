
import { capitalCase } from "change-case";
import { Link as RouterLink } from "react-router-dom";
// material
import { experimentalStyled as styled } from "@material-ui/core/styles";
import {
    Box,
    Card,
    Stack,
    Link,
    Alert,
    Tooltip,
    Container,
    Typography,
    Grid,
} from "@material-ui/core";
// routes
import { PATH_AUTH, PATH_DASHBOARD } from "routes/paths";
// components
import Page from "components/Page";

import HeaderBreadcrumbs from 'components/HeaderBreadcrumbs';
import { useLocation,useParams } from 'react-router';
import MGCashPickupGenGen from "./MGCashPickupGenGen";


// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
    [theme.breakpoints.up("md")]: {
        display: "flex",
    },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
    width: "100%",
    maxWidth: 464,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    //margin: theme.spacing(2, 0, 2, 2),
    backgroundColor: "#c8facd",
    boxShadow: "none",
    borderRadius: "0px",
}));

const ContentStyle = styled("div")(({ theme }) => ({
    maxWidth: 500,
    margin: "auto",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    padding: theme.spacing(12, 0),
}));


export default () => {
    const search = useLocation().search;
    const id=new URLSearchParams(search).get("param");
    console.log(id)//123
    const { id1 } = useParams();
   console.log(id1);
   const authResult = new URLSearchParams(window.location.search); 
const code = authResult.get('param')
console.log(code);

   const FormName =MGCashPickupGenGen
    return (

        <>
            <Container><HeaderBreadcrumbs
                heading="Add new receipients"
                links={[
                    { name: 'Select Receiver', href: PATH_DASHBOARD.user.root },
                    // { name: 'Delivery Options', href: PATH_DASHBOARD.user.root },
                ]} />
            </Container>
            <RootStyle title="Receipients">
                <Container maxWidth="sm">

                    <ContentStyle>
                        <Stack direction="row" alignItems="center" sx={{ mb: 5 }}>
                            <Grid item xs={12} md={6} lg={12}>
                                <Card sx={{ p: 4, }}>
                                    {/* <ReceiverForm /> */}
                                    <FormName/>
                                    <p>success</p>
                                </Card>
                            </Grid>
                        </Stack>
                    </ContentStyle>
                </Container>
            </RootStyle>
        </>
    );
};
