import { map, filter } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
// utils
import axios from '../../utils/axios';
import { remitanceOrder } from '../../@types/remitanceOrder';
import URLConstants from 'constants/urlConstants';

type RemitanceOrderState = {
    isLoading: boolean;
    error: boolean;
    remitanceOrder: remitanceOrder;
}

const initialState: RemitanceOrderState = {
    isLoading: false,
    error: false,
    remitanceOrder: {
        'outboundCurrency': '',
        'outboundCountry': '',
        'recvCurrency': '',
        'recvCountry': '',
        'totalOutboundSendAmount': 0,
        'outboundSendFees': 0,
        'netOutboundSendAmount': 0,
        'rate': 0,
        'recvAmount': 0,
        'recvFees': 0,
        'netRecvAmount': 0,
        'outboundQuoteId': '',
        'senderId': '',
        'receiverId': '',
        'routeId': 0,
        'paymentOptionId': 0,
        'purposeOfTransaction': '',
        'remittanceOrder': {
            'remittanceOrderId': '',
            'senderId': '',
            'receiverId': '',
            'routeId': 0,
            'paymentOptionId': 0,
            'outboundQuoteId': '',
            'remittanceQuoteId': '',
            'purposeOfTransaction': '',
            'sourceOfFunds': '',
            'remittanceOrderStatus': '',
            'createTime': new Date(),
            'updateTime': new Date(),
        },

    }
}


const slice = createSlice({
    name: 'remitanceOrder',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        // GET ORDER
        getRemitanceOrderSuccess(state, action) {
            state.isLoading = false;
            state.remitanceOrder = action.payload;
        },
    }
});

// Reducer
export default slice.reducer;


export function getRemitanceOrder(outboundQuoteId: string, senderId: string, receiverId: string, routeId: number, paymentOptionId: string, purposeOfTransaction: string) {
   console.log(outboundQuoteId,senderId,receiverId,routeId,paymentOptionId,purposeOfTransaction);
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.post(URLConstants.ORDER_BASE_URL, {
                    'outboundQuoteId': outboundQuoteId, 'senderId': senderId, 'receiverId': receiverId, 'routeId': routeId, 'paymentOptionId': paymentOptionId, 'purposeOfTransaction': purposeOfTransaction
            });
            dispatch(slice.actions.getRemitanceOrderSuccess(response.data.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}