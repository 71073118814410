// material
import {

    Card,
    Table,
    TableRow,
    TableBody,
    TableCell,
    TableHead,
    TableContainer,
    Container,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography,
    Grid,
} from '@material-ui/core';
// utils
import { RootState, useDispatch, useSelector } from 'redux/store';
import { useEffect } from 'react';
import HeaderBreadcrumbs from 'components/HeaderBreadcrumbs';
import Page from 'components/Page';
import { getReceiveOptions } from 'redux/slices/receiveOptions';
import arrowIosDownwardFill from '@iconify/icons-eva/arrow-ios-downward-fill';
import { Icon } from '@iconify/react';
import Scrollbar from 'components/Scrollbar';
import useLocales from 'hooks/useLocales';
// ----------------------------------------------------------------------

export default function DestinationOptions() {
    const { translate } = useLocales();
    const dispatch = useDispatch();
    const { receiveOptions } = useSelector(
        (state: RootState) => state.receiveOptions
    );


    useEffect(() => {
        dispatch(getReceiveOptions());
    }, [dispatch]);


    return (
        <>  <Page title="Destination options">
            <Container maxWidth="xl">

                <HeaderBreadcrumbs
                    heading={translate(`dashboard.${"Destination options"}`)}
                    links={[
                        // { name: 'Amount', href: PATH_DASHBOARD.user.root },
                        // { name: 'Delivery Options', href: PATH_DASHBOARD.user.root },
                    ]}
                />
                {receiveOptions !== null &&
                    <>

                        <Grid container >
                            <Grid item xs={12} md={2} ></Grid>
                            <Grid item xs={12} md={8} >
                                {receiveOptions.map((row) => (
                                    <Card sx={{ mb: 10 }}>
                                  
                                            <TableContainer >
                                                <Table>
                                                    <TableRow key={row.recvCountryCode}>
                                                        <TableCell><h3>{row.recvCountryName.toUpperCase()}</h3> </TableCell>
                                                        <TableCell style={{ float: 'right' }}>{row.recvCurrencyCode}</TableCell>
                                                    </TableRow>
                                                </Table>
                                            </TableContainer>
                                 
                                        <Accordion>
                                            <AccordionSummary
                                                expandIcon={<Icon icon={arrowIosDownwardFill} width={20} height={20} />}    >
                                                <Typography variant="subtitle1" >{translate(`dashboard.${"Routes"}`)}</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Typography>
                                                    <Scrollbar>
                                                        <TableContainer sx={{ minWidth: 720}}>
                                                            <Table>
                                                                <TableHead>
                                                                    <TableRow>
                                                                        <TableCell>{translate(`dashboard.${"Route ID"}`)}</TableCell>
                                                                        <TableCell>{translate(`dashboard.${"Route name"}`)}</TableCell>
                                                                        <TableCell>{translate(`dashboard.${"Delivery option Id"}`)}</TableCell>
                                                                        <TableCell>{translate(`dashboard.${"Delivery option name"}`)}</TableCell>
                                                                    </TableRow>
                                                                </TableHead>
                                                                {receiveOptions !== null &&
                                                                    <TableBody>
                                                                        {row.routes.map((row) => (
                                                                            <TableRow key={row.routeId}>
                                                                                <TableCell>{row.routeId}</TableCell>
                                                                                <TableCell>{row.routeName}</TableCell>
                                                                                <TableCell>{row.deliveryOptionId}</TableCell>
                                                                                <TableCell>{row.deliveryOptionName}</TableCell>
                                                                            </TableRow>
                                                                        ))}
                                                                    </TableBody>}
                                                            </Table>

                                                        </TableContainer>
                                                    </Scrollbar>
                                                </Typography>
                                            </AccordionDetails>
                                        </Accordion>

                                        <Accordion>
                                            <AccordionSummary
                                                expandIcon={<Icon icon={arrowIosDownwardFill} width={20} height={20} />}    >
                                                <Typography variant="subtitle1" >{translate(`dashboard.${"Payment Options"}`)}</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Typography>
                                                    <Scrollbar>
                                                        <TableContainer sx={{ minWidth: 720}}>
                                                            <Table>
                                                                <TableHead>
                                                                    <TableRow>
                                                                        <TableCell>{translate(`dashboard.${"Payment option Id"}`)}</TableCell>
                                                                        <TableCell>{translate(`dashboard.${"Payment option name"}`)}</TableCell>
                                                                        <TableCell>{translate(`dashboard.${"Payment option code"}`)}</TableCell>

                                                                    </TableRow>
                                                                </TableHead>
                                                                {receiveOptions !== null &&
                                                                    <TableBody>
                                                                        {row.paymentOptions.map((row) => (
                                                                            <TableRow key={row.paymentOptionId}>
                                                                                <TableCell>{row.paymentOptionId}</TableCell>
                                                                                <TableCell>{row.paymentOptionName}</TableCell>
                                                                                <TableCell>{row.paymentOptionCode}</TableCell>
                                                                            </TableRow>
                                                                        ))}
                                                                    </TableBody>}
                                                            </Table>

                                                        </TableContainer>
                                                    </Scrollbar>
                                                </Typography>
                                            </AccordionDetails>
                                        </Accordion>

                                    </Card>
                                ))}
                            </Grid>
                            <Grid item xs={12} md={2}></Grid>
                        </Grid>
                    </>}
            </Container>
        </Page></>
    );
}