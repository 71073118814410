// material
import {

    Card,
    Table,
    TableRow,
    TableBody,
    TableCell,
    TableHead,
    TableContainer,
    Container,
} from '@material-ui/core';
// utils
import { RootState, useDispatch, useSelector } from 'redux/store';
import { useEffect } from 'react';
import { getPurpose } from 'redux/slices/purpose';
import Page from 'components/Page';
import HeaderBreadcrumbs from 'components/HeaderBreadcrumbs';
import useLocales from 'hooks/useLocales';
// ----------------------------------------------------------------------

export default function PurposeOfTransactions() {
    const { translate } = useLocales();
    const dispatch = useDispatch();
    const { purposeValues } = useSelector(
        (state: RootState) => state.purpose
    );


    useEffect(() => {
        dispatch(getPurpose());
    }, [dispatch]);


    return (
        <>  <Page title="Purpose of transaction">
            <Container maxWidth="xl">

                <HeaderBreadcrumbs
                    heading={translate(`dashboard.${"Purpose of transaction"}`)}
                    links={[
                        // { name: 'Amount', href: PATH_DASHBOARD.user.root },
                        // { name: 'Delivery Options', href: PATH_DASHBOARD.user.root },
                    ]}
                />
                <Card sx={{ pb: 3 }}>
                    <TableContainer sx={{ minWidth: 720 }}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Purpose Id</TableCell>
                                    <TableCell>Purpose code</TableCell>
                                    <TableCell>Purpose text</TableCell>
                                    <TableCell>Enabled</TableCell>
                                </TableRow>
                            </TableHead>
                            {purposeValues !== null &&
                                <TableBody>
                                    {purposeValues.map((row) => (
                                        <TableRow key={row.purposeId}>
                                            <TableCell>{row.purposeId}</TableCell>
                                            <TableCell>{row.purposeCode}</TableCell>
                                            <TableCell>{row.purposeText}</TableCell>
                                            <TableCell>{row.enables}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>}
                        </Table>

                    </TableContainer>
                </Card>
            </Container>
        </Page></>
    );
}