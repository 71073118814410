
export default class URLConstants {

  //BASE URLs
  static MTUS_BASE_URL: string =
    process.env.MTUS_BASE_URL ||
    "https://6jfjr9vffh.execute-api.ap-south-1.amazonaws.com/staging/mtus";

  static MTCS_BASE_URL: string =
    process.env.MTCS_BASE_URL ||
    "https://6jfjr9vffh.execute-api.ap-south-1.amazonaws.com/staging/mtcs";

  static MCUS_BASE_URL: string =
    process.env.MCUS_BASE_URL ||
    " https://6jfjr9vffh.execute-api.ap-south-1.amazonaws.com/staging/mcus"

    static MCTS_BASE_URL: string =
    process.env.MCTS_BASE_URL ||
    " https://6jfjr9vffh.execute-api.ap-south-1.amazonaws.com/staging/mcts"

    static MCPS_BASE_URL: string =
    process.env.MCPS_BASE_URL ||
    " https://6jfjr9vffh.execute-api.ap-south-1.amazonaws.com/staging/mcps"
  //MTUS URLs
  static LOGIN_BASE_URL: string = URLConstants.MTUS_BASE_URL + "/backoffice/login";
  static SIGNUP_BASE_URL: string = URLConstants.MTUS_BASE_URL + "/signup";

  //BO
  static CREATE_CUSTOMER_BASE_URL: string = URLConstants.MTUS_BASE_URL + "/bo/customer/create";
  static BO_INFO: string = URLConstants.MTUS_BASE_URL + "/bo/users/me";


  //MTCS URLs
  static COUNTRIES_BASE_URL: string = URLConstants.MTCS_BASE_URL + "/countries";
  static CURRENCIES_BASE_URL: string = URLConstants.MTCS_BASE_URL + "/currencies";
  static OUTBOUND_OPTIONS_BASE_URL: string = URLConstants.MTCS_BASE_URL + "/outbound/outbound-options";
  static RECEIVE_OPTIONS_BASE_URL: string = URLConstants.MTCS_BASE_URL + "/outbound/receive-options?outboundCountry=JPN&outboundCurrency=JPY";
  static PURPOSE_BASE_URL: string = URLConstants.MTCS_BASE_URL + "/purpose";
  static OCCUPATIONS_BASE_URL: string = URLConstants.MTCS_BASE_URL + "/occupations";
  static SOURCE_OF_FUNDS_BASE_URL: string = URLConstants.MTCS_BASE_URL + "/sourceoffunds";
  static DELIVERY_OPTIONS_BASE_URL: string = URLConstants.MTCS_BASE_URL + "/bo/deliveryoptions";
  static IDENTIFICATION_DOC_BASE_URL: string = URLConstants.MTCS_BASE_URL + "/bo/identificationDoc";

  //BO
  static ROUTES_BASE_URL: string = URLConstants.MTCS_BASE_URL + "/bo/routes";
  static PARTNERS_BASE_URL: string = URLConstants.MTCS_BASE_URL + "/bo/partners";

  
  // MCUS URLs
  static CUSTOMER_BASE_URL: string = URLConstants.MCUS_BASE_URL + "/users/me/customer";
  static SENDER_BASE_URL: string = URLConstants.MCUS_BASE_URL + "/users/me/sender";
  static RECEIVERS_BASE_URL: string = URLConstants.MCUS_BASE_URL + "/users/me/sender/1/receiver";
  static ROUTE_SPECIFIC_RECEIVERS_BASE_URL: string = URLConstants.MCUS_BASE_URL + "/users/me/route/:routeId/receivers";
 
    //BO
    static ALL_CUSTOMER_BASE_URL: string = URLConstants.MCUS_BASE_URL + "/customers/all/1";
    static CUSTOMER_BY_ID_BASE_URL: string = URLConstants.MCUS_BASE_URL + "/customer/1";


    // MCTS URLs
  static RECENT_TXN_BASE_URL: string = URLConstants.MCTS_BASE_URL + "/users/me/recentTxn";
  static TXN_STATS_BASE_URL: string = URLConstants.MCTS_BASE_URL + "/users/me/stats";
  static ORDER_BASE_URL: string = URLConstants.MCTS_BASE_URL + "/users/me/order";

  //Bo
  static TXN_COUNT_BASE_URL: string = URLConstants.MCTS_BASE_URL + "/bo/transactions/count";
  static RECENT_TXN_ORDER_DETAILS_BO : string = URLConstants.MCTS_BASE_URL + "/bo/transaction/details/";
  static RECENT_TXN_OF_CUSTOMER_BO: string = URLConstants.MCTS_BASE_URL + "/bo/transaction/recent/user/";

    // MCPS URLs
    static QUOTE_BASE_URL: string = URLConstants.MCPS_BASE_URL + "/quote";
}
