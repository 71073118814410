import faker from 'faker';
import { Icon } from '@iconify/react';
import { sentenceCase } from 'change-case';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// material
import { useTheme } from '@material-ui/core/styles';
import arrowIosForwardFill from '@iconify/icons-eva/arrow-ios-forward-fill';
import {
  Box,
  Card,
  Table,
  Button,
  Divider,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  CardHeader,
  TableContainer,
  Typography,
  Avatar,
} from '@material-ui/core';
// utils
import { fCurrency } from 'utils/formatNumber';
//
import Label, { LabelColor } from 'components/Label';
import Scrollbar from 'components/Scrollbar';
import { alpha, experimentalStyled as styled } from '@material-ui/core/styles';
import { RootState, useDispatch, useSelector } from 'redux/store';
import { useEffect } from 'react';
import { getRecentTransactions } from 'redux/slices/recentTransactions';
import useLocales from 'hooks/useLocales';
import { lastIndexOf } from 'lodash';
import { getAllTransactionOrderDetails } from 'redux/slices/transactionOrderDetailsBO';

//import MoreMenuButton from 'components/MoreMenuButton';

// ----------------------------------------------------------------------


const IconWrapperStyle = styled('div')(({ theme }) => ({
  //margin: 'auto',
  display: 'flex',
  maxWidth: '45px',
  maxHeight: '45px',
  borderRadius: '50%',
  alignItems: 'center',
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: 'center',
  // marginBottom: theme.spacing(3),
  color: theme.palette.primary.dark,
  // backgroundImage:
  //   `linear-gradient(135deg, ${alpha(theme.palette.primary.dark, 0)} 0%, ${alpha(theme.palette.primary.dark, 0.24)} 100%)`


}));
// ----------------------------------------------------------------------
const recentTransactionsText = "Recent Transactions"
const remittanceOrderId = "Order ID"
const amount = "Amount"
const date = "Date"
const status = "Status"
const receiverName = "Receiver name"
const senderName = "Sender name"

export default function AppRecentTransactions() {
  const { translate } = useLocales();
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { recentTransactions } = useSelector(
    (state: RootState) => state.recentTransactions
  );

  useEffect(() => {
    dispatch(getRecentTransactions(1, 10));
  }, [dispatch]);
  console.log(recentTransactions);

  type remittanceStatusType = "CREATED" | "WALLET_RECEIVED" | "WALLET_CREDITED" | "WALLET_DEBITED" | "WALLET_PAUSED" | "COMPLETED" | "FAILED";

  const statusColorThemes = {
    "CREATED" : "warning",
    "WALLET_RECEIVED" : "primary",
    "WALLET_CREDITED" : "secondary",
    "WALLET_DEBITED" : "info",
    "WALLET_PAUSED" : "warning",
    "FAILED" : "error",
    "COMPLETED" : "success"
  }

  const fetchOrderDetails = async (remittanceOrderId: string) => {
    dispatch(getAllTransactionOrderDetails(remittanceOrderId, navigate))
  }
  
  return (
    <Card>
      <CardHeader title={translate(`dashboard.${recentTransactionsText}`)} sx={{ mb: 3 }} />
      <Scrollbar>
        <TableContainer sx={{ minWidth: 720 }}>
          <Table>
            <TableHead>
              <TableRow>
              <TableCell>{translate(`dashboard.${remittanceOrderId}`)}</TableCell>
                <TableCell>{translate(`dashboard.${senderName}`)}</TableCell>
                <TableCell>{translate(`dashboard.${receiverName}`)}</TableCell>
                <TableCell>{translate(`dashboard.${amount}`)}</TableCell>
                <TableCell>{translate(`dashboard.${date}`)}</TableCell>
                <TableCell>{translate(`dashboard.${status}`)}</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
        
            <TableBody>
              {recentTransactions.map((row) => {
                const transaction_id_list = row.remittanceOrderId.split('-');
                const transaction_id = transaction_id_list[transaction_id_list?.length - 1]?.toUpperCase();
                const status = row.status as remittanceStatusType;
                const colorPicker: LabelColor | undefined = statusColorThemes[status] as LabelColor | undefined
                return (
                  <TableRow key={row.remittanceOrderId} sx={{ cursor: "pointer" }} onClick={() => { fetchOrderDetails(row.remittanceOrderId) }}>
                    <TableCell>
                      {row.remittanceOrderId.substring(row.remittanceOrderId.lastIndexOf('-') + 1, row.remittanceOrderId.length).toLocaleUpperCase()}
                    </TableCell>
                    <TableCell>{row.senderName} </TableCell>
                    <TableCell>{row.receiverName} </TableCell>
                    <TableCell>{row.amount} JPY </TableCell>
                    <TableCell>{row.date}</TableCell>
                    <TableCell>
                      <Label
                        variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
                        color={colorPicker}
                      >
                        {sentenceCase(row.status)}
                      </Label>
                    </TableCell>

                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Scrollbar>

      <Divider />

      <Box sx={{ p: 2, textAlign: 'right' }}>
        <Button
          to="/dashboard/transaction"
          size="small"
          color="inherit"
          component={RouterLink}
          endIcon={<Icon icon={arrowIosForwardFill} />}
        >
           {translate(`dashboard.${"View All"}`)}
        </Button>
      </Box>
    </Card>
  );
}
