
import { capitalCase } from "change-case";
import { Link as RouterLink } from "react-router-dom";
// material
import { experimentalStyled as styled } from "@material-ui/core/styles";
import {
	Box,
	Card,
	Stack,
	Link,
	Alert,
	Tooltip,
	Container,
	Typography,
	Grid,
} from "@material-ui/core";
// routes
import { PATH_AUTH, PATH_DASHBOARD } from "../../routes/paths";
// hooks
import useAuth from "../../hooks/useAuth";
// layouts
import AuthLayout from "../../layouts/AuthLayout";
// components
import Page from "../../components/Page";
import { MHidden } from "../../components/@material-extend";
import React from "react";
import axios from "utils/axios";
import URLConstants from "constants/urlConstants";
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import { ConfirmForm } from "./ConfirmForm";


// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
	[theme.breakpoints.up("md")]: {
		display: "flex",
	},
}));

const SectionStyle = styled(Card)(({ theme }) => ({
	width: "100%",
	maxWidth: 464,
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
	//margin: theme.spacing(2, 0, 2, 2),
	backgroundColor: "#c8facd",
	boxShadow: "none",
	borderRadius: "0px",
}));

const ContentStyle = styled("div")(({ theme }) => ({
	maxWidth: 500,
	margin: "auto",
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
	padding: theme.spacing(12, 0),
}));

type confirmKeyValueType = {
	confirmKeyValue: number,
  };

  
export default ({confirmKeyValue}:confirmKeyValueType) => {


	const { method } = useAuth();
	return (

		<>
		  {/* <Container>
			  <HeaderBreadcrumbs
			heading="Send Money"
			links={[
				{ name: 'Confirm Details', href: PATH_DASHBOARD.user.root },
			]} />
			</Container> */}
			<RootStyle title="Send money ">
				<Container maxWidth="sm">

					<ContentStyle>
						<Stack direction="row" alignItems="center" sx={{ mb: 5 }}>
							<Grid item xs={12} md={6} lg={12}>
							<ConfirmForm />
							</Grid>
						</Stack>
					</ContentStyle>
				</Container>
			</RootStyle>
            </>
	);
};
