import React, { useContext } from "react";

import {
  TextField,
  Grid,
  Card,
} from "@material-ui/core";
import { LoadingButton } from "@material-ui/lab";
import { experimentalStyled as styled } from "@material-ui/core/styles";
import * as Yup from "yup";
import {
  useFormik,

} from "formik";
import { RootState, useDispatch, useSelector } from "redux/store";
import { getCreateRouteSpecificReceiver } from "redux/slices/createNewReceiver";
import { AppContext } from "contexts/SendMoneyContext";
import Confirm from "../Confirm";

type InitialValues = {
  receiverFirstName: string;
  receiverMiddleName: string;
  receiverLastName: string;
  receiverPhoneNumber: string;
  email: string;
  remember: boolean;
  afterSubmit?: string;
};

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 380,
  margin: "auto",
  display: "flex",
  minHeight: "100vh",
  flexDirection: "column",
  justifyContent: "center",
  padding: theme.spacing(12, 0),
}));

type formNameType = {
  formType1: number,
};

type confirmKeyType = {
  confirmKey: number,
};
export default function MGCashPickupGenGen() {
  const dispatch = useDispatch();
  const { createRouteSpecificReceiver } = useSelector(
    (state: RootState) => state.createRouteSpecificReceiver
  );

  const {
    formValues,
    handleChange,
  } = useContext(AppContext)
  const { routeId, receiverId } = formValues


  const formik = useFormik<InitialValues>({
    initialValues: {
      receiverFirstName: "",
      receiverMiddleName: "",
      receiverLastName: "",
      receiverPhoneNumber: "",
      email: "",
      remember: true,
    },

    validationSchema: Yup.object().shape({
      receiverFirstName: Yup.string()
        .max(15, "Must be 15 characters or less")
        .required("*Required"),

      receiverMiddleName: Yup.string()
        .max(20, "Must be 20 characters or less"),

      receiverLastName: Yup.string()
        .max(20, "Must be 20 characters or less")
        .required("*Required"),

      receiverPhoneNumber: Yup.string()
        .max(20, "Must be 20 characters or less")
        .required("*Phone Number is Required"),

      email: Yup.string()
        .max(50, "Must be 20 characters or less")
        .required("*E-mail is required"),
    }),

    onSubmit: (values) => {
      // alert(JSON.stringify(values, null, 2));
      console.log(values);
      
     
      let senderId = localStorage.getItem('senderId');
      if (senderId !== null && routeId.value !== null && routeId.value !== 0) {
        dispatch(getCreateRouteSpecificReceiver(routeId.value, senderId, values));
        setFormType(1)
      }
    },
  });



  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } =
    formik;
  const [formType, setFormType] = React.useState(0);

  const ConfirmpageView = ({ confirmKey }: confirmKeyType) => {
    console.log('success');
    return <Confirm confirmKeyValue={confirmKey} />
  }

  if (createRouteSpecificReceiver !== null && createRouteSpecificReceiver.receiverId !== 0) {
    console.log(createRouteSpecificReceiver);
    formValues.receiverId.value = createRouteSpecificReceiver.receiverId;
  }

  return (
    <>
      {formType == 1 && <ConfirmpageView confirmKey={formType} />}
      {formType !== 1 &&
        <Card sx={{ p: 4, overflow: 'visible' }}>
          <form onSubmit={formik.handleSubmit}>
            <Grid container direction={"column"} spacing={3}>
              <Grid item>
                <TextField
                  type="receiverFirstName"
                  label="First Name"
                  fullWidth
                  {...getFieldProps("receiverFirstName")}
                  error={Boolean(
                    touched.receiverFirstName && errors.receiverFirstName
                  )}
                  helperText={touched.receiverFirstName && errors.receiverFirstName}
                />
              </Grid>

              <Grid item>
                <TextField
                  type="receiverMiddleName"
                  label="Middle Name"
                  fullWidth
                  {...getFieldProps("receiverMiddleName")}
                  error={Boolean(
                    touched.receiverMiddleName && errors.receiverMiddleName
                  )}
                  helperText={touched.receiverMiddleName && errors.receiverMiddleName}
                />
              </Grid>

              <Grid item>
                <TextField
                  type="receiverLastName"
                  label="Last Name"
                  fullWidth
                  {...getFieldProps("receiverLastName")}
                  error={Boolean(touched.receiverLastName && errors.receiverLastName)}
                  helperText={touched.receiverLastName && errors.receiverLastName}
                />
              </Grid>

              <Grid item>
                <TextField
                  label="Phone Number"
                  type="receiverPhoneNumber"
                  fullWidth
                  {...getFieldProps("receiverPhoneNumber")}
                  error={Boolean(
                    touched.receiverPhoneNumber && errors.receiverPhoneNumber
                  )}
                  helperText={
                    touched.receiverPhoneNumber && errors.receiverPhoneNumber
                  }
                />
              </Grid>

              <Grid item>
                <TextField
                  fullWidth
                  type="email"
                  label="E-mail"
                  {...getFieldProps("email")}
                  error={Boolean(touched.email && errors.email)}
                  helperText={touched.email && errors.email}
                />
              </Grid>

              <Grid item>
                <LoadingButton
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  loading={isSubmitting}
                >
                  Submit
                </LoadingButton>
              </Grid>
            </Grid>
          </form>
        </Card>}
    </>
  );
}

