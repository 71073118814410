import axios from 'axios';
const axiosInstance = axios.create();

axiosInstance.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    if (error.response.status == "401") {
      localStorage.removeItem("accessToken");
      window.location.href = "auth/login"
    }
    return Promise.reject((error.response && error.response.data) || 'Something went wrong')
  }
);

export default axiosInstance;
