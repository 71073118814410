import { map, filter } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
// utils
import axios from '../../utils/axios';
import { routeBankType } from '../../@types/routeBankType';
import URLConstants from 'constants/urlConstants';

type RouteBankState = {
    isLoading: boolean;
    error: boolean;
    routeBankBO: routeBankType[];
}

const initialState: RouteBankState = {
    isLoading: false,
    error: false,
    routeBankBO: [],
}

const slice = createSlice({
    name: 'routeBankBO',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        // GET ROUTE BANK
        getRouteBankBOSuccess(state, action) {
            state.isLoading = false;
            state.routeBankBO = action.payload;
        },
    }
});

// Reducer
export default slice.reducer;


export function getRouteBankBO(page: number) {
    var params = {
        "noOfRecords":10,
      }
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(`https://6jfjr9vffh.execute-api.ap-south-1.amazonaws.com/staging/mtcs/bo/routes/banks/all/${page}`,{params});
            dispatch(slice.actions.getRouteBankBOSuccess(response.data.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}