import { Autocomplete, Box, Button, Card, Container, Grid, IconButton, InputAdornment, Modal, Stack, TextField } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from "react";
// components
import Page from '../../components/Page';
import HeaderBreadcrumbs from 'components/HeaderBreadcrumbs';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Icon } from '@iconify/react';
import CustomersList from './CustomersList';
import { makeStyles } from '@material-ui/core/styles';
import { useFormik } from "formik";
import * as Yup from "yup";
import useLocales from 'hooks/useLocales';
import { RootState, useDispatch, useSelector } from 'redux/store';
import { getCountry } from 'redux/slices/country';
import { getOccupation } from 'redux/slices/occupation';
import { LoadingButton } from '@material-ui/lab';
import eyeFill from "@iconify/icons-eva/eye-fill";
import eyeOffFill from "@iconify/icons-eva/eye-off-fill";
import { stringify } from 'querystring';
import { createNewCustomer } from 'redux/slices/createNewCustomerBO';
import { MIconButton } from 'components/@material-extend';
import closeFill from '@iconify/icons-eva/close-fill';
import { useSnackbar } from 'notistack';
import { getAllCustomer } from 'redux/slices/allCustomersBO';

// ----------------------------------------------------------------------
type InitialValues = {
  senderFirstName: string;
  senderMiddleName: string;
  senderLastName: string;
  senderDOB: Date;
  senderGender: string;
  senderNationality: string;
  senderOccupation: string;
  email: string,
  password: string,
  phoneNumber: string,
  country: string,
  language: string,
  accountType: string,
};

function getModalStyle() {
  const top = 50;
  const left = 50;
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    verticalAlign: 'center'
  },
  paper: {
    // position: 'fixed',
    width: '50%',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4, 4, 4),
    outline: 'none',
    [theme.breakpoints.down('sm')]: {
      minWidth: `100% !important`,
    },
  },
}));

function countryToFlag(isoCode: string) {
  return typeof String.fromCodePoint !== "undefined"
    ? isoCode
      .toUpperCase()
      .replace(/./g, (char) =>
        String.fromCodePoint(char.charCodeAt(0) + 127397)
      )
    : isoCode;
}

const accounts = [
  { title: "Individual", value: "Individual" },
  { title: "Business", value: "Business" },
];

export default function Customers() {

  const [inputValue, setInputValue] = useState('');

  const { countryList } = useSelector(
    (state: RootState) => state.countryData
  );
  const { occupationList } = useSelector(
    (state: RootState) => state.occupation
  );
  const { createCustomer } = useSelector(
    (state: RootState) => state.createNewCustomer
  );

  const { allCustomers } = useSelector(
    (state: RootState) => state.allCustomers
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCountry());
    dispatch(getOccupation());
  }, [dispatch]);

  const { translate } = useLocales();
  const male = "Male"
  const female = "Female"
  const others = "Others"
  const firstNameText = "First Name"
  const middleNameText = "Middle Name"
  const lastNameText = "Last Name"
  const dateOfBirth = "Date of birth"
  const selectYourGender = "Select your gender"
  const genderText = "Gender"
  const nationality = "Nationality"
  const occupationText = "Occupation"

  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [allValues, setAllValues] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false)
  }

  const formik = useFormik<InitialValues>({
    initialValues: {
      senderFirstName: '',
      senderMiddleName: '',
      senderLastName: '',
      senderDOB: new Date(),
      senderGender: '',
      senderNationality: '',
      senderOccupation: '',
      email: '',
      password: '',
      phoneNumber: '',
      country: '',
      language: 'EN',
      accountType: '',
    },

    validationSchema: Yup.object().shape({

      senderFirstName: Yup.string()
        .required("FirstName is required"),

      senderLastName: Yup.string()
        .required("LastName is required"),

      senderDOB: Yup.string()

        .required("DOB is required")
        .max(20, 'DOB is required'),

      senderGender: Yup.string()
        .required("Gender is required"),

      senderNationality: Yup.string()
        .required("Nationality is required"),

      senderOccupation: Yup.string()
        .required("Occupation is required"),

      email: Yup.string()
        .email("Email must be a valid email address")
        .required("Email is required"),

      password: Yup.string()
        .required("Password is required"),

      phoneNumber: Yup.string()
        .required("Phone number is required"),

      country: Yup.string()
        .required("Please select your country"),

      accountType: Yup.string()
        .required("Account type is required"),

    }),

    onSubmit: async (values) => {
      let response = await dispatch(createNewCustomer(values));
      if (response?.hasError) {
        enqueueSnackbar(response.message, {
          variant: "error",
          action: (key) => (
            <MIconButton size="small" onClick={() => closeSnackbar(key)}>
              <Icon icon={closeFill} />
            </MIconButton>
          ),
        });
      }
      else {
        setOpen(false);
        dispatch(getAllCustomer(1));
        enqueueSnackbar("Created!", {
          variant: "success",
          action: (key) => (
            <MIconButton size="small" onClick={() => closeSnackbar(key)}>
              <Icon icon={closeFill} />
            </MIconButton>
          ),
        });
      }
    },
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } =
    formik;

  const options = [{ value: 0, label: translate(`dashboard.${male}`) }, { value: 1, label: translate(`dashboard.${female}`) }, { value: 3, label: translate(`dashboard.${others}`) }];


  const handleSenderFirstNameValue = (e: any) => {
    formik.values.senderFirstName = e.target.value
  }


  const handleSenderMiddleNameValue = (e: any) => {
    formik.values.senderMiddleName = e.target.value
  }
  const handleSenderLastNameValue = (e: any) => {
    formik.values.senderLastName = e.target.value
  }

  const handleSenderEmailValue = (e: any) => {
    formik.values.email = e.target.value
  }
  const handleSenderPasswordValue = (e: any) => {
    formik.values.password = e.target.value
  }

  const handleSenderPhoneNumberValue = (e: any) => {
    formik.values.phoneNumber = e.target.value
  }

  const handleSenderDOBValue = (e: any) => {
    formik.values.senderDOB = e.target.value
  }

  const handleCountryValue = (e: any, values: any) => {
    if (values !== null) {
      formik.values.country = values.alpha3;
      console.log(values.alpha3);
    }
  }

  const handleNationalityValue = (e: any, values: any) => {
    if (values !== null) {
      formik.values.senderNationality = values.alpha3;
      console.log(values.alpha3);
    }
  }
  const handleOccupationValue = (e: any, values: any) => {
    if (values !== null) {
      formik.values.senderOccupation = values.occupationText;
      console.log(values.occupationText);
    }
  }
  const handleAccountType = (e: any, values: any) => {
    if (values !== null) {
      formik.values.accountType = values.value;
      console.log(values.value);
    }
  }

  const handleGenderValue = (e: any, val: any) => {
    if (val !== null) {
      formik.values.senderGender = val.label
    }
  }


  return (
    <Page title="Customers :List">
      <Container maxWidth="xl">
        <HeaderBreadcrumbs
          heading={translate(`dashboard.${"Customers"}`)}
          links={[
            // { name: 'Dashboard', href: PATH_DASHBOARD.root },
            // { name: 'User', href: PATH_DASHBOARD.user.root },
            // { name: 'List' }
          ]}
          action={
            <Button
              variant="contained"
              // component={RouterLink}
              // to={PATH_DASHBOARD.user.newUser}
              onClick={handleOpen}
              startIcon={<Icon icon={plusFill} />}
            >
              {translate(`dashboard.${"Add customer"}`)}
            </Button>
          }
        />
      </Container>

      <Container maxWidth="xl">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <CustomersList />
          </Grid>
        </Grid>
      </Container>

      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={open}
        onClose={handleClose}
      >

        <Card style={modalStyle} className={classes.paper}>

          <form onSubmit={formik.handleSubmit}>
            <Stack spacing={3}>
              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={3} alignItems="center" display='flex' justifyContent='space-between' >
                <Grid item xs={12}>
                  <TextField
                    type="senderFirstName"
                    //name="senderFirstName"
                    label={translate(`dashboard.${firstNameText}`)}
                    fullWidth
                    // {...getFieldProps("senderFirstName")}
                    onChange={handleSenderFirstNameValue}
                    error={Boolean(touched.senderFirstName && errors.senderFirstName)}
                    helperText={touched.senderFirstName && errors.senderFirstName}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    type="senderMiddleName"
                    //name="middleName"
                    label={translate(`dashboard.${middleNameText}`)}
                    fullWidth
                    onChange={handleSenderMiddleNameValue}
                  // {...getFieldProps("senderMiddleName")}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    type="senderLastName"
                    // name="lastName"
                    label={translate(`dashboard.${lastNameText}`)}
                    fullWidth
                    onChange={handleSenderLastNameValue}
                    // {...getFieldProps("senderLastName")}
                    error={Boolean(touched.senderLastName && errors.senderLastName)}
                    helperText={touched.senderLastName && errors.senderLastName}
                  />
                </Grid>
              </Stack>
              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={3} alignItems="center" display='flex' justifyContent='space-between' >
                <Grid item xs={12}>
                  <TextField
                    id="senderDOB"
                    label={translate(`dashboard.${dateOfBirth}`)}
                    type="date"
                    //name="dob"
                    fullWidth
                    onChange={handleSenderDOBValue}
                    // {...getFieldProps("senderDOB")}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={Boolean(
                      touched.senderDOB && errors.senderDOB
                    )}
                    helperText={
                      touched.senderDOB && errors.senderDOB
                    }
                  />
                </Grid>

                <Grid item xs={12} >
                  <>
                    <Autocomplete
                      disablePortal
                      autoHighlight
                      //value={options.label}
                      options={options}
                      onChange={(e, val) => handleGenderValue(e, val)}
                      // {...getFieldProps("senderGender")}
                      inputValue={inputValue}
                      onInputChange={(event, newInputValue) => {
                        setInputValue(newInputValue);
                      }}
                      renderInput={(params) =>
                        <TextField {...params}
                          fullWidth
                          name="senderGender"
                          label={translate(`dashboard.${selectYourGender}`)}
                          placeholder={translate(`dashboard.${genderText}`)}
                          error={Boolean(
                            touched.senderGender && errors.senderGender
                          )}
                          helperText={
                            touched.senderGender && errors.senderGender
                          }
                        />}


                    />

                  </>
                </Grid>
                <Grid item xs={12} >
                  <Box
                    sx={{
                      width: '100%',
                      '& .MuiAutocomplete-option': {
                        typography: 'body2',
                        '& > span': { mr: 1, fontSize: 20 }
                      }
                    }}
                  >
                    <Autocomplete
                      id='senderNationality'
                      disablePortal
                      autoHighlight
                      options={countryList}
                      //{...getFieldProps("senderNationality")}
                      onChange={handleNationalityValue}
                      getOptionLabel={(countryList) => countryList.name}
                      renderOption={(props, countryList) => (
                        <li {...props}>
                          <span>{countryToFlag(countryList.alpha3)}</span>
                          {countryList.name} ({countryList.alpha3})
                        </li>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          // name='senderNationality'
                          placeholder='Nationality'
                          {...getFieldProps("senderNationality")}
                          id="senderNationality"
                          label='Nationality'
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: 'new-password'
                          }}
                          error={Boolean(
                            touched.senderNationality && errors.senderNationality
                          )}
                          helperText={
                            touched.senderNationality && errors.senderNationality
                          }
                        />
                      )}
                    />
                  </Box>
                </Grid>
              </Stack>


              <Stack direction={{ xs: 'column', md: 'row' }} spacing={3} paddingTop='10px'>
                <Grid item xs={12}>
                  <TextField
                    type="email"
                    //name="email"
                    label="email"
                    fullWidth
                    onChange={handleSenderEmailValue}
                    // {...getFieldProps("email")}
                    error={Boolean(touched.email && errors.email)}
                    helperText={touched.email && errors.email}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    autoComplete="current-password"
                    type={showPassword ? "text" : "password"}
                    label="Password"
                    // {...getFieldProps("password")}
                    onChange={handleSenderPasswordValue}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            edge="end"
                            onClick={() => setShowPassword((prev) => !prev)}
                          >
                            <Icon
                              icon={showPassword ? eyeFill : eyeOffFill}
                            />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    error={Boolean(touched.password && errors.password)}
                    helperText={touched.password && errors.password}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Phone number"
                    type="phoneNumber"
                    fullWidth
                    // {...getFieldProps("phoneNumber")}
                    onChange={handleSenderPhoneNumberValue}
                    error={Boolean(
                      touched.phoneNumber && errors.phoneNumber
                    )}
                    helperText={
                      touched.phoneNumber && errors.phoneNumber
                    }
                  />
                </Grid>
              </Stack>

              <Stack direction={{ xs: 'column', md: 'row' }} spacing={3} paddingTop='10px'>

                <Grid item xs={12} >
                  <Box
                    sx={{
                      width: '100%',
                      '& .MuiAutocomplete-option': {
                        typography: 'body2',
                        '& > span': { mr: 1, fontSize: 20 }
                      }
                    }}
                  >
                    <Autocomplete
                      id='country'
                      disablePortal
                      autoHighlight
                      options={countryList}
                      // {...getFieldProps("country")}
                      onChange={handleCountryValue}
                      getOptionLabel={(countryList) => countryList.name}
                      renderOption={(props, countryList) => (
                        <li {...props}>
                          <span>{countryToFlag(countryList.alpha3)}</span>
                          {countryList.name} ({countryList.alpha3})
                        </li>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          {...getFieldProps("country")}
                          name='country'
                          id="country"
                          label="country"
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: 'new-password'
                          }}
                          error={Boolean(
                            touched.country && errors.country
                          )}
                          helperText={
                            touched.country && errors.country
                          }
                        />
                      )}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12}>

                  <Box
                    sx={{
                      width: '100%',
                      '& .MuiAutocomplete-option': {
                        typography: 'body2',
                        '& > span': { mr: 1, fontSize: 20 }
                      }
                    }}
                  >
                    <Autocomplete
                      disablePortal
                      autoHighlight
                      options={accounts}
                      getOptionLabel={option => option.title}
                      onChange={handleAccountType}
                      //  {...getFieldProps("accountType")}
                      renderInput={params => (
                        <TextField
                          {...params}
                          {...getFieldProps("accountType")}
                          label="Account type"
                          variant="outlined"
                          fullWidth
                          name="accountType"
                          error={Boolean(
                            touched.accountType && errors.accountType
                          )}
                          helperText={
                            touched.accountType && errors.accountType
                          }
                        />
                      )}
                    />
                  </Box>
                </Grid>

                <Grid item xs={12} >
                  <Box
                    sx={{
                      width: '100%',
                      '& .MuiAutocomplete-option': {
                        typography: 'body2',
                        '& > span': { mr: 1, fontSize: 20 }
                      }
                    }}
                  >
                    <Autocomplete
                      disablePortal
                      autoHighlight
                      // {...getFieldProps("senderOccupation")}
                      options={occupationList}
                      onChange={handleOccupationValue}
                      getOptionLabel={(occupationList) => occupationList.occupationText}
                      renderOption={(props, occupationList) => (
                        <li {...props}>
                          {occupationList.occupationText}
                        </li>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          label={translate(`dashboard.${occupationText}`)}
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: 'new-password'
                          }}
                          error={Boolean(
                            touched.senderOccupation && errors.senderOccupation
                          )}
                          helperText={
                            touched.senderOccupation && errors.senderOccupation
                          }
                        />
                      )}
                    />
                  </Box>
                </Grid>
              </Stack>
            </Stack>

            <Grid item marginTop={5}>
              <LoadingButton
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                color={"primary"}
                onClick={() => formik.handleSubmit}
              // onClick={onSubmitValues}
              >
                Submit
              </LoadingButton>
            </Grid>
          </form>

        </Card>

      </Modal>
    </Page>

  );
}