import LoginForm from "./LoginForm";
import { capitalCase } from "change-case";
import { Link as RouterLink } from "react-router-dom";
// material
import { experimentalStyled as styled } from "@material-ui/core/styles";
import {
	Box,
	Card,
	Stack,
	Link,
	Alert,
	Tooltip,
	Container,
	Typography,
} from "@material-ui/core";
// routes
import { PATH_AUTH } from "../../routes/paths";
// hooks
import useAuth from "../../hooks/useAuth";
// layouts
import AuthLayout from "../../layouts/AuthLayout";
// components
import Page from "../../components/Page";
import { MHidden } from "../../components/@material-extend";
import useLocales from "hooks/useLocales";
import LanguagePopover from "layouts/LanguagePopover";

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
	[theme.breakpoints.up("md")]: {
		display: "flex",
	},
}));

const SectionStyle = styled(Card)(({ theme }) => ({
	width: "100%",
	maxWidth: 464,
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
	//margin: theme.spacing(2, 0, 2, 2),
	backgroundColor: "#d5e1f5",
	boxShadow: "none",
	borderRadius: "0px",
}));

const ContentStyle = styled("div")(({ theme }) => ({
	maxWidth: 380,
	margin: "auto",
	display: "flex",
	minHeight: "100vh",
	flexDirection: "column",
	justifyContent: "center",
	padding: theme.spacing(12, 0),
}));

const dontHaveAnAccountText = "Don’t have an account?"
const getStartedText = "Get started"
const hiWelcomeText = "Hi, Welcome Back"
const signMoneyTransferSystemtext = "Sign in to Money Transfer System"
const signIntoBoText = "Sign into MTS Back office"
const enterYourDetailsBelowText = "Enter your details below."
export default () => {
	const { translate } = useLocales();
	const { method } = useAuth();
	return (
		<RootStyle title="Login | MTS ">
			<AuthLayout>
				{/* {translate(`dashboard.${dontHaveAnAccountText}`)} &nbsp;
				<Link
					underline="none"
					variant="subtitle2"
					component={RouterLink}
					to={PATH_AUTH.register}
				>
					{translate(`dashboard.${getStartedText}`)}
				</Link> */}

				<span  style={{paddingLeft:'20px'}}>
					<LanguagePopover />
				</span>

			</AuthLayout>

			<MHidden width="mdDown">
				<SectionStyle>
					<Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
						{translate(`dashboard.${hiWelcomeText}`)}
					</Typography>
					<img
						src="/static/illustrations/login-bo.svg"
						alt="login"
					/>
				</SectionStyle>
			</MHidden>

			<Container maxWidth="sm">
				<ContentStyle>
					<Stack direction="row" alignItems="center" sx={{ mb: 5 }}>
						<Box sx={{ flexGrow: 1 }}>
							<Typography variant="h4" gutterBottom>
								{translate(`dashboard.${signIntoBoText}`)} &nbsp;
							</Typography>
							<Typography sx={{ color: "text.secondary" }}>
								{translate(`dashboard.${enterYourDetailsBelowText}`)} &nbsp;
							</Typography>
						</Box>

						{/* <Tooltip title={capitalCase(method)}>
							<Box
								component="img"
								src={`/static/auth/ic_${method}.png`}
								sx={{ width: 32, height: 32 }}
							/>
						</Tooltip> */}
					</Stack>

					<LoginForm />

					<MHidden width="smUp">
						<Typography variant="body2" align="center" sx={{ mt: 3 }}>
							{translate(`dashboard.${dontHaveAnAccountText}`)} &nbsp;&nbsp;
							<Link
								variant="subtitle2"
								component={RouterLink}
								to={PATH_AUTH.register}
							>
								{translate(`dashboard.${getStartedText}`)}
							</Link>
						</Typography>
					</MHidden>
				</ContentStyle>
			</Container>
		</RootStyle>
	);
};
