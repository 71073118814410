import {
  TextField,
  Grid,
  Autocomplete,
  Card,
} from "@material-ui/core";
import { LoadingButton } from "@material-ui/lab";
import { experimentalStyled as styled } from "@material-ui/core/styles";
import * as Yup from "yup";
import {
  useFormik,

} from "formik";
import { useCallback, useContext, useEffect } from "react";
import { RootState, useDispatch, useSelector } from "redux/store";
import { getRouteBank } from "redux/slices/routeBank";
import { AppContext } from "contexts/SendMoneyContext";
import { getCreateRouteSpecificReceiver } from "redux/slices/createNewReceiver";
import React from "react";
import Confirm from "../Confirm";

type InitialValues = {
  receiverFirstName: string;
  receiverLastName: string;
  accountNumber: string;
  bankName: string;
  branchName: string;
  remember: boolean;
  afterSubmit?: string;
};

type confirmKeyType = {
  confirmKey: number,
};

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 380,
  margin: "auto",
  display: "flex",
  minHeight: "100vh",
  flexDirection: "column",
  justifyContent: "center",
  padding: theme.spacing(12, 0),
}));

function MGBankDepositJPYLKR() {

  const dispatch = useDispatch();
  const { createRouteSpecificReceiver } = useSelector(
    (state: RootState) => state.createRouteSpecificReceiver
  );

  const { routeBank } = useSelector(
    (state: RootState) => state.routeBank
  );

  useEffect(() => {
    console.log(routeId.value);
    dispatch(getRouteBank(routeId.value));//TODO pass the original routeId
  }, [dispatch]);

  const {
    formValues,
    handleChange,
  } = useContext(AppContext)
  const { routeId, receiverId, receiverFirstName, receiverLastName, accountNumber, bankName,branchName } = formValues

  const isError = useCallback(
    () =>
      Object.keys({ receiverFirstName, receiverLastName, accountNumber, bankName,branchName }).some(
        (name) =>
          (formValues[name].required && !formValues[name].value) ||
          formValues[name].error
      ),
    [formValues, receiverFirstName, receiverLastName, accountNumber, bankName,branchName]
  )

  const formik = useFormik<InitialValues>({
    initialValues: {
      receiverFirstName: "",
      receiverLastName: "",
      accountNumber: "",
      bankName: "",
      branchName: "",
      remember: true,
    },

    validationSchema: Yup.object().shape({
      receiverFirstName: Yup.string()
        .max(15, "Must be 15 characters or less")
        .required("*Required"),

      receiverLastName: Yup.string()
        .max(20, "Must be 20 characters or less")
        .required("*Required"),

      accountNumber: Yup.string()
        .max(20, "Must be 20 characters or less")
        .required("*Account number is required"),

      bankName: Yup.string()
        .max(20, "Must be 20 characters or less")
        .required("*Bank name is required"),

      branchName: Yup.string()
        .max(20, "Must be 20 characters or less")
        .required("*Branch name is required"),
    }),

    onSubmit: (values) => {
      console.log(values.accountNumber);
      formValues.receiverFirstName.value = values.receiverFirstName;
      formValues.receiverLastName.value = values.receiverLastName;
      formValues.accountNumber.value = values.accountNumber;
      formValues.bankName.value = values.bankName;
      formValues.branchName.value = values.branchName;

      let senderId = localStorage.getItem('senderId');
      if (senderId !== null && routeId.value !== null && routeId.value !== 0) {
        dispatch(getCreateRouteSpecificReceiver(routeId.value, senderId, values));
        console.log(createRouteSpecificReceiver);
        setFormType(1)
      }
      //alert(JSON.stringify(values, null, 2));
    },
  });
  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } =
    formik;

  const [formType, setFormType] = React.useState(0);

  const ConfirmpageView = ({ confirmKey }: confirmKeyType) => {
    console.log('success');
    return <Confirm confirmKeyValue={confirmKey} />
  }
  if (createRouteSpecificReceiver !== null && createRouteSpecificReceiver.receiverId !== 0) {
    console.log(createRouteSpecificReceiver);
    formValues.receiverId.value = createRouteSpecificReceiver.receiverId;
  }

  return (
    <>
      {formType == 1 && <ConfirmpageView confirmKey={formType} />}
      {formType !== 1 &&
        <Card sx={{ p: 4, overflow: 'visible' }}>
          <form onSubmit={formik.handleSubmit}>
            <Grid container direction={"column"} spacing={3}>
              <Grid item>
                <TextField
                  type="receiverFirstName"
                  label="First Name"
                  fullWidth
                  {...getFieldProps("receiverFirstName")}
                  error={Boolean(
                    touched.receiverFirstName && errors.receiverFirstName
                  )}
                  helperText={touched.receiverFirstName && errors.receiverFirstName}
                />
              </Grid>

              <Grid item>
                <TextField
                  type="receiverLastName"
                  label="Last Name"
                  fullWidth
                  {...getFieldProps("receiverLastName")}
                  error={Boolean(touched.receiverLastName && errors.receiverLastName)}
                  helperText={touched.receiverLastName && errors.receiverLastName}
                />
              </Grid>

              <Grid item>
                <TextField
                  label="Account Number"
                  type="accountNumber"
                  fullWidth
                  {...getFieldProps("accountNumber")}
                  error={Boolean(touched.accountNumber && errors.accountNumber)}
                  helperText={touched.accountNumber && errors.accountNumber}
                />
              </Grid>

              <Grid item>
                <React.Fragment>
                  <Autocomplete
                    options={routeBank}
                    getOptionLabel={option => option.bankName}

                    renderInput={params => (
                      <TextField
                        {...params}
                        label="Bank Name"
                        variant="outlined"
                        fullWidth
                        {...getFieldProps("bankName")}
                        error={Boolean(touched.bankName && errors.bankName)}
                        helperText={touched.bankName && errors.bankName}
                      />
                    )}
                  />
                </React.Fragment>
              </Grid>

              <Grid item>
                <TextField
                  fullWidth
                  type="branchName"
                  label="Branch Name"
                  {...getFieldProps("branchName")}
                  error={Boolean(touched.branchName && errors.branchName)}
                  helperText={touched.branchName && errors.branchName}
                />
              </Grid>

              <Grid item>
                <LoadingButton
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  loading={isSubmitting}
                >
                  Submit
                </LoadingButton>
              </Grid>
            </Grid>
          </form>
        </Card>}
    </>
  );
}

export default MGBankDepositJPYLKR;
