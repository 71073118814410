import { map, filter } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
// utils
import axios from '../../utils/axios';
import { OccupationType } from '../../@types/occupationType';
import URLConstants from 'constants/urlConstants';

type OccupationState = {
    isLoading: boolean;
    error: boolean;
    occupationList: OccupationType[];
}

const initialState: OccupationState = {
    isLoading: false,
    error: false,
    occupationList: [
        {
            occupationId: 0,
            occupationCode: '',
            occupationText: '',
            enabled: true,
        }
    ],
}

const slice = createSlice({
    name: 'occupationList',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        // GET OCCUPATION
        getOccupationSuccess(state, action) {
            state.isLoading = false;
            state.occupationList = action.payload;
        },
    }
});

// Reducer
export default slice.reducer;


export function getOccupation() {
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
        const response = await axios.get(URLConstants.OCCUPATIONS_BASE_URL);
        dispatch(slice.actions.getOccupationSuccess(response.data.data));
      } catch (error) {
        dispatch(slice.actions.hasError(error));
      }
    };
  }