import { map, filter } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
// utils
import axios from '../../utils/axios';
import { transationsStatsType } from '../../@types/transactionStats';
import URLConstants from 'constants/urlConstants';

type TransactionsState = {
    isLoading: boolean;
    error: boolean;
    transationsStats: transationsStatsType;
}

const initialState: TransactionsState = {
    isLoading: false,
    error: false,
    transationsStats:  {  "balance": 0,
    "totalTransactions": 0,
    "inProgressTransactions": 0,
    "recipients": 0 },
}

const slice = createSlice({
    name: 'transationsStats',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        // GET TRANSACTIONS
        getTransactionStatsSuccess(state, action) {
            state.isLoading = false;
            state.transationsStats = action.payload;
        },
    }
});

// Reducer
export default slice.reducer;


export function getTransactionStats() {
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
        const response = await axios.get(URLConstants.TXN_STATS_BASE_URL);
        dispatch(slice.actions.getTransactionStatsSuccess(response.data.data));
      } catch (error) {
        dispatch(slice.actions.hasError(error));
      }
    };
  }