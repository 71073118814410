import {
    TextField,
    Grid,
    Card,
  } from "@material-ui/core";
  import { LoadingButton } from "@material-ui/lab";
  import { experimentalStyled as styled } from "@material-ui/core/styles";
  import * as Yup from "yup";
  import { useCallback, useContext } from "react";
  import {
    useFormik,

  } from "formik";
import React from "react";
import Confirm from "../Confirm";
import { RootState, useDispatch, useSelector } from "redux/store";
import { AppContext } from "contexts/SendMoneyContext";
import { getCreateRouteSpecificReceiver } from "redux/slices/createNewReceiver";
  
  type InitialValues = {
    receiverFirstName: string;
    receiverLastName: string;
    accountNumber: string;
    bankIdentifier: string;
    remember: boolean;
  };
  
  type confirmKeyType = {
    confirmKey: number,
  };
  
  const ContentStyle = styled("div")(({ theme }) => ({
    maxWidth: 380,
    margin: "auto",
    display: "flex",
    minHeight: "100vh",
    flexDirection: "column",
    justifyContent: "center",
    padding: theme.spacing(12, 0),
  }));
  
  function Bangladesh() {
    
    const dispatch = useDispatch();
    const { createRouteSpecificReceiver } = useSelector(
      (state: RootState) => state.createRouteSpecificReceiver
    );
    
    const {
      formValues,
      handleChange,
    } = useContext(AppContext)
    const { routeId, receiverId, receiverFirstName, receiverLastName, accountNumber, bankIdentifier } = formValues
  
    const isError = useCallback(
      () =>
        Object.keys({ receiverFirstName, receiverLastName, accountNumber, bankIdentifier }).some(
          (name) =>
            (formValues[name].required && !formValues[name].value) ||
            formValues[name].error
        ),
      [formValues, receiverFirstName, receiverLastName, accountNumber, bankIdentifier]
    )
    
    const formik = useFormik<InitialValues>({
      initialValues: {
        receiverFirstName: "",
        receiverLastName: "",
        accountNumber: "",
        bankIdentifier: "",
        remember: true,
      },
  
      validationSchema: Yup.object().shape({
        receiverFirstName: Yup.string()
          .max(15, "Must be 15 characters or less")
          .required("*Required"),
  
        receiverLastName: Yup.string()
          .max(20, "Must be 20 characters or less")
          .required("*Required"),
  
        accountNumber: Yup.string()
          .max(20, "Must be 20 characters or less")
          .required("*Account Number is required"),
  
        bankIdentifier: Yup.string()
          .max(20, "Must be 20 characters or less")
          .required("*BankIdentifier is required"),
  
      }),
  
      onSubmit: (values) => {
        console.log(values.accountNumber);
        formValues.receiverFirstName.value = values.receiverFirstName;
        formValues.receiverLastName.value = values.receiverLastName;
        formValues.accountNumber.value = values.accountNumber;
        formValues.bankIdentifier.value = values.bankIdentifier;
  
        let senderId = localStorage.getItem('senderId');
        if (senderId !== null && routeId.value !== null && routeId.value !== 0) {
          dispatch(getCreateRouteSpecificReceiver(routeId.value, senderId, values));
          console.log(createRouteSpecificReceiver);
          setFormType(1)
        }
       // alert(JSON.stringify(values, null, 2));
      },
    });
    const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } =
      formik;
  
                
  const [formType, setFormType] = React.useState(0);

  const ConfirmpageView = ({ confirmKey }: confirmKeyType) => {
    console.log('success');
    return <Confirm confirmKeyValue={confirmKey} />
  }
  if (createRouteSpecificReceiver !== null && createRouteSpecificReceiver.receiverId !== 0) {
    console.log(createRouteSpecificReceiver);
    formValues.receiverId.value = createRouteSpecificReceiver.receiverId;
  }

  
    return (
      <>
      {formType == 1 && <ConfirmpageView confirmKey={formType} />}
      {formType !== 1 &&
        <Card sx={{ p: 4, overflow: 'visible' }}>
      <form onSubmit={formik.handleSubmit}>
        <Grid container direction={"column"} spacing={3}>
          <Grid item>
            <TextField
              type="receiverFirstName"
              label="First Name"
              fullWidth
              {...getFieldProps("receiverFirstName")}
              error={Boolean(
                touched.receiverFirstName && errors.receiverFirstName
              )}
              helperText={touched.receiverFirstName && errors.receiverFirstName}
            />
          </Grid>
  
          <Grid item>
            <TextField
              type="receiverLastName"
              label="Last Name"
              fullWidth
              {...getFieldProps("receiverLastName")}
              error={Boolean(touched.receiverLastName && errors.receiverLastName)}
              helperText={touched.receiverLastName && errors.receiverLastName}
            />
          </Grid>
  
          <Grid item>
            <TextField
              label="Account Number"
              type="accountNumber"
              fullWidth
              {...getFieldProps("accountNumber")}
              error={Boolean(touched.accountNumber && errors.accountNumber)}
              helperText={touched.accountNumber && errors.accountNumber}
            />
          </Grid>
  
          <Grid item>
            <TextField
              label="BankIdentifier"
              type="bankIdentifier"
              fullWidth
              {...getFieldProps("bankIdentifier")}
              error={Boolean(touched.bankIdentifier && errors.bankIdentifier)}
              helperText={touched.bankIdentifier && errors.bankIdentifier}
            />
          </Grid>
  
  
          <Grid item>
            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={isSubmitting}
            >
              Submit
            </LoadingButton>
          </Grid>
        </Grid>
      </form>
      </Card>}
    </>
    );
  }
  
  export default Bangladesh;
  