import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
import axios from '../../utils/axios';
import { RecentOrderTransaction } from '../../@types/recentOrderTransactionType';
import URLConstants from 'constants/urlConstants';
import { NavigateFunction } from 'react-router';

export type TransactionOrderDetailsState = {
  isLoading: boolean;
  error: boolean;
  transactionOrderDetails: RecentOrderTransaction;
}

const initialState: TransactionOrderDetailsState = {
  isLoading: false,
  error: false,
  transactionOrderDetails: {} as RecentOrderTransaction,
}

const slice = createSlice({
  name: 'transactionOrderDetails',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET TRANSACTIONS
    getRecentTransactionsSuccess(state, action) {
      state.isLoading = false;
      state.transactionOrderDetails = action.payload;
    },
  }
});

// Reducer
export default slice.reducer;


export function getTransactionOrderDetails(orderId: string, navigate: NavigateFunction) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {

      const response = await axios.get(URLConstants.RECENT_TXN_ORDER_DETAILS_BO + orderId);
      if (response.status === 200) {
        dispatch(slice.actions.getRecentTransactionsSuccess(response.data.data));
        navigate('/dashboard/orderdetails')
      }


    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getAllTransactionOrderDetails(orderId: string, navigate: NavigateFunction) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {

      const response = await axios.get(URLConstants.RECENT_TXN_ORDER_DETAILS_BO + orderId);
      if (response.status === 200) {
        dispatch(slice.actions.getRecentTransactionsSuccess(response.data.data));
        navigate('/dashboard/transactiondetails')
      }


    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}