import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
// utils
import axios from '../../utils/axios';
import { PartnersType } from '../../@types/partnersTypes';
import URLConstants from 'constants/urlConstants';

type PartnersState = {
    isLoading: boolean;
    error: boolean;
    partners: PartnersType[];
}

const initialState: PartnersState = {
    isLoading: false,
    error: false,
    partners: [],
}

const slice = createSlice({
    name: 'partners',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        // GET PARTNERS
        getPartnersSuccess(state, action) {
            state.isLoading = false;
            state.partners = action.payload;
        },
    }
});

// Reducer
export default slice.reducer;


export function getPartnersList() {
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
        const response = await axios.get(URLConstants.PARTNERS_BASE_URL);
        dispatch(slice.actions.getPartnersSuccess(response.data.data));
      } catch (error) {
        dispatch(slice.actions.hasError(error));
      }
    };
  }