import { map, filter } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
// utils
import axios from '../../utils/axios';
import { CreateCustomerType } from '../../@types/createCustomerType';
import URLConstants from 'constants/urlConstants';
import { date } from 'faker';

type createCustomerState = {
    isLoading: boolean;
    error: boolean;
    createCustomer: CreateCustomerType;
}

const initialState: createCustomerState = {
    isLoading: false,
    error: false,
    createCustomer: {
        "userId": 0,
        "email": "",
        "phoneNumber": "",
        "country": "",
        "language": "",
        "emailVerified": false,
        "enabled": true,
        "createdBy": null,
        "createTime": new Date(),
        "updateTime":  new Date(),
        "userRoles": []
    },
}

const slice = createSlice({
    name: 'createCustomer',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        createCustomerSuccess(state, action) {
            state.isLoading = false;
            state.createCustomer = action.payload;
        },
    }
});

// Reducer
export default slice.reducer;

export function createNewCustomer(
    values: any,

) {
    return async () => {
        console.log( values);

        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.post(URLConstants.CREATE_CUSTOMER_BASE_URL,
                {
                    "senderFirstName" : values.senderFirstName,
                    "senderMiddleName" : values.senderMiddleName,
                    "senderLastName" : values.senderLastName,
                    "senderDOB": values.senderDOB,
                    "senderGender": values.senderGender,
                    "senderNationality" : values.senderNationality,
                    "senderOccupation" : values.senderOccupation,
                    "email":values.email,
                    "password":values.password,
                    "phoneNumber":values.phoneNumber,
                    "country":values.country,
                    "language":values.language,
                    "accountType":values.accountType,
                }
            );
            dispatch(slice.actions.createCustomerSuccess(response.data.data));
        } catch (error :any) {
            dispatch(slice.actions.hasError(error));
            return {message : error?.message, hasError : true} 
        }
    };
}