import { Container, Grid } from "@material-ui/core";
import HeaderBreadcrumbs from "components/HeaderBreadcrumbs";
import Page from "components/Page";
import useLocales from "hooks/useLocales";
import TransactionsTable from "./TransactionsTable";

export default function Transactions() {
  const { translate } = useLocales();
  return (
    <Page title="Remittance Transactions">
      <Container maxWidth="xl">

        <HeaderBreadcrumbs
          heading={translate(`dashboard.${"Transactions"}`)}
          links={[
            // { name: 'Amount', href: PATH_DASHBOARD.user.root },
            // { name: 'Delivery Options', href: PATH_DASHBOARD.user.root },
          ]}
        />

        <Grid container spacing={3}>


          <Grid item xs={12} lg={12}>
            <TransactionsTable />
          </Grid>
        </Grid>
      </Container>
    </Page>
  )
}
