import { map, filter } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
// utils
import axios from '../../utils/axios';
import { receiverData } from '../../@types/receiversData';
import URLConstants from 'constants/urlConstants';

type ReceiversState = {
    isLoading: boolean;
    error: boolean;
    receivers: receiverData[];
}

const initialState: ReceiversState = {
    isLoading: false,
    error: false,
    receivers: [],
}

const slice = createSlice({
    name: 'receivers',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        // GET COUNTRY
        getReceiversSuccess(state, action) {
            state.isLoading = false;
            state.receivers = action.payload;
        },
    }
});

// Reducer
export default slice.reducer;


export function getReceivers(senderId: string) {
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
        const response = await axios.get(`https://6jfjr9vffh.execute-api.ap-south-1.amazonaws.com/staging/mcus/users/me/sender/${senderId}/receiver`);
        dispatch(slice.actions.getReceiversSuccess(response.data.data));
      } catch (error) {
        dispatch(slice.actions.hasError(error));
      }
    };
  }