import { useState, ReactNode } from "react";
import { Navigate, useLocation } from "react-router-dom";
// hooks
import useAuth from "../hooks/useAuth";
// pages
import Login from "../pages/Login/Login";

// ----------------------------------------------------------------------

type AuthGuardProps = {
	children: ReactNode;
};

export default function AuthGuard({ children }: AuthGuardProps) {
	const { isAuthenticated } = useAuth();
	console.log("Is authenticated", isAuthenticated);
	const { pathname } = useLocation();
	const [requestedLocation, setRequestedLocation] = useState<string | null>(
		null
	);

	if (!isAuthenticated) {
		if (pathname !== requestedLocation) {
			setRequestedLocation(pathname);
		}
		return <Login />;
	}

	if (requestedLocation && pathname !== requestedLocation) {
		setRequestedLocation(null);
		return <Navigate to={requestedLocation} />;
	}

	return <>{children}</>;
}
