import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
// utils
import axios from '../../utils/axios';
import { BankTypesType } from '../../@types/bankTypesType';

type BankTypeState = {
    isLoading: boolean;
    error: boolean;
    bankTypesBO: BankTypesType[];
}

const initialState: BankTypeState = {
    isLoading: false,
    error: false,
    bankTypesBO: [],
}

const slice = createSlice({
    name: 'bankTypesBO',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        // GET BANK TYPES
        getBankTypesBOSuccess(state, action) {
            state.isLoading = false;
            state.bankTypesBO = action.payload;
        },
    }
});

// Reducer
export default slice.reducer;


export function getBankTypesBO() {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(`https://6jfjr9vffh.execute-api.ap-south-1.amazonaws.com/staging/mtcs/bo/routes/bankTypes/all`);
            dispatch(slice.actions.getBankTypesBOSuccess(response.data.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}