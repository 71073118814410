
import { capitalCase } from "change-case";
import { Link as RouterLink } from "react-router-dom";
// material
import { experimentalStyled as styled } from "@material-ui/core/styles";
import {
	Box,
	Card,
	Stack,
	Link,
	Alert,
	Tooltip,
	Container,
	Typography,
	Grid,
} from "@material-ui/core";
// routes
import { PATH_AUTH, PATH_DASHBOARD } from "../../routes/paths";
// hooks
import useAuth from "../../hooks/useAuth";
// layouts
import AuthLayout from "../../layouts/AuthLayout";
// components
import Page from "../../components/Page";
import { MHidden } from "../../components/@material-extend";
import { CurrencyForm } from "./SendMoneyForm";
import CountryServices from "services/CountryServices";
import React, { useEffect } from "react";
import axios from "utils/axios";
import URLConstants from "constants/urlConstants";
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import { RootState, useDispatch, useSelector } from '../../redux/store';
import { getCurrency } from 'redux/slices/currency'
import useLocales from "hooks/useLocales";

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
	[theme.breakpoints.up("md")]: {
		display: "flex",
	},
}));

const SectionStyle = styled(Card)(({ theme }) => ({
	width: "100%",
	maxWidth: 464,
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
	//margin: theme.spacing(2, 0, 2, 2),
	backgroundColor: "#c8facd",
	boxShadow: "none",
	borderRadius: "0px",
}));

const ContentStyle = styled("div")(({ theme }) => ({
	maxWidth: 500,
	margin: "auto",
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
	padding: theme.spacing(12, 0),
}));

type CurrencyProps = {
	currencyCode: string;
	name: string;
	namePlural: string;
	symbol: string;
	symbolNative: string;
	decimalDigits: number;
}[];

const sendMoneytext="Send Money"
const amount="Amount"

export default () => {
//	const [currencies, setCurrencies] = React.useState<CurrencyProps>([]);
const { translate } = useLocales();
	const dispatch = useDispatch();
	const { currency } = useSelector(
		(state: RootState) => state.currency
	);

	useEffect(() => {
		dispatch(getCurrency());
	}, [dispatch]);
	console.log(currency);

	// React.useEffect(() => {
	// 	axios.get(URLConstants.CURRENCIES_BASE_URL).then((response) => {
	// 		setCurrencies(response.data.data);
	// 		console.log(response.data.data);

	// 	});
	// }, []);

	const { method } = useAuth();
	
	return (

		<>
			<Container><HeaderBreadcrumbs
				heading={translate(`dashboard.${sendMoneytext}`)}
				links={[
					{ name: translate(`dashboard.${amount}`), href: PATH_DASHBOARD.user.root },
					// { name: 'Delivery Options', href: PATH_DASHBOARD.user.root },
				]} />
			</Container>
			<RootStyle title="Send money ">
				<Container maxWidth="sm">

					<ContentStyle>
						<Stack direction="row" alignItems="center" sx={{ mb: 5 }}>
							<Grid item xs={12} md={6} lg={12}>
								<CurrencyForm options={currency} />
							</Grid>
						</Stack>
					</ContentStyle>
				</Container>
			</RootStyle></>
	);
};
