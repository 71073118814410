import { useEffect, useState } from "react";
import { experimentalStyled as styled } from "@material-ui/core/styles";
import {
	Card,
	Stack,
	Container,
	Grid,
	Button,
	Tab,
	Tabs,
	Box,
	Typography,
	Divider,
} from "@material-ui/core";
import {
	ArrowBack,
	CheckCircleOutlineRounded,
	ErrorOutlineRounded,
	ContentCopy,
	AccountCircle,
	Send,
	PendingRounded,
	PauseCircleFilledOutlined,
	DangerousOutlined
} from "@material-ui/icons";
import Page from "../../components/Page";
import { useSelector, RootState } from "redux/store";
import useLocales from "hooks/useLocales";
import { useNavigate } from "react-router-dom";
import { RecentOrderTransaction } from "../../@types/recentOrderTransactionType";



const RootStyle = styled(Page)(({ theme }) => ({
	[theme.breakpoints.up("md")]: {
		display: "flex",
	},
}));

const ContentStyle = styled("div")(({ theme }) => ({
	maxWidth: 500,
	margin: "auto",
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
	padding: theme.spacing(12, 0),
}));

const DetailsSection = (props: RecentOrderTransaction) => {
	const { orderReceiver, orderSender, outboundQuote } = props;
	const { purposeOfTransaction } = props;
	const receiverName = `${orderReceiver && orderReceiver[0]?.receiverFirstName || ""} ${orderReceiver && orderReceiver[0]?.receiverMiddleName || ""} ${orderReceiver && orderReceiver[0]?.receiverLastName || ""}`
	const senderName = `${orderSender && orderSender[0]?.senderFirstName || ""} ${orderSender && orderSender[0]?.senderMiddleName || ""} ${orderSender && orderSender[0]?.senderLastName || ""}`
	const senderBank = orderSender && orderSender[0]?.senderBank;
	const receiverBank = orderReceiver && orderReceiver[0]?.receiverBank;
	const { totalOutboundSendAmount, outboundCurrency, outboundSendFees, recvCurrency, netRecvAmount, netOutboundSendAmount,rate } = outboundQuote || {};
	const senderBankDetailsExist =
		senderBank && senderBank[0]?.senderAccountNumber;
	const receiverBankDetailsExist =
		receiverBank && receiverBank[0]?.receiverAccountNumber;
	const navigate = useNavigate();
	const { translate } = useLocales();

	return (
		<>
			<Box sx={{ pt: 3, pb: 3 }}>
				<Typography sx={{ fontWeight: "bold" }} variant="body1" mb={1}>
					Sender Information
				</Typography>
				<Box
					display={"flex"}
					sx={{ alignItems: "center", justifyContent: "space-between" }}
				>
					<Box
						display={"flex"}
						sx={{ justifyContent: "space-between", alignItems: "center" }}
					>
						<AccountCircle
							style={{
								width: 40,
								height: 40,
								marginRight: 8,
								color: "#666",
							}}
						/>
						<Typography>{senderName}</Typography>
					</Box>
					<Box>
						<Typography variant="h6">
							{totalOutboundSendAmount} {outboundCurrency}
						</Typography>
					</Box>
				</Box>
				{/* {senderBankDetailsExist && (
					<Box mt={1}>
						<Box
							display={"flex"}
							sx={{
								alignItems: "center",
								justifyContent: "space-between",
							}}
						>
							<Typography variant="caption">IFSC Code</Typography>
							<Typography variant="caption">
								{senderBank && senderBank[0]?.senderIFSC
									? senderBank[0]?.senderIFSC
									: "-"}
							</Typography>
						</Box>
						<Box
							display={"flex"}
							sx={{
								alignItems: "center",
								justifyContent: "space-between",
							}}
						>
							<Typography variant="caption">Account Number</Typography>
							<Typography variant="caption">
								{senderBank && senderBank[0]?.senderAccountNumber
									? senderBank[0]?.senderAccountNumber
									: "-"}
							</Typography>
						</Box>
					</Box>
				)} */}
			</Box>
			<Divider sx={{ marginBottom: 2 }} />
			<Box sx={{ pb: 3 }}>
				<Typography sx={{ fontWeight: "bold" }} variant="body1" mb={1}>
					{translate(`dashboard.${"Recipient_Information"}`)}
				</Typography>
				<Box
					display={"flex"}
					sx={{ alignItems: "center", justifyContent: "space-between" }}
				>
					<Box
						display={"flex"}
						sx={{ justifyContent: "space-between", alignItems: "center" }}
					>
						<AccountCircle
							style={{
								width: 40,
								height: 40,
								marginRight: 8,
								color: "#666",
							}}
						/>
						<Typography>{receiverName}</Typography>
					</Box>
					<Box>
						<Typography variant="h6">
							{netRecvAmount} {recvCurrency}
						</Typography>
					</Box>
				</Box>
				{receiverBankDetailsExist && (
					<Box mt={1}>
						{/* <Box
							display={"flex"}
							sx={{
								alignItems: "center",
								justifyContent: "space-between",
							}}
						>
							<Typography variant="caption">{translate(`dashboard.${"IFSC_Code"}`)}</Typography>
							<Typography variant="caption">
								{receiverBank && receiverBank[0]?.receiverIFSC
									? receiverBank[0]?.receiverIFSC
									: "-"}
							</Typography>
						</Box> */}
						<Box
							display={"flex"}
							sx={{
								alignItems: "center",
								justifyContent: "space-between",
							}}
						>
							<Typography variant="caption">Account Number</Typography>
							<Typography variant="caption">
								{receiverBank && receiverBank[0]?.receiverAccountNumber
									? receiverBank[0]?.receiverAccountNumber
									: "-"}
							</Typography>
						</Box>
					</Box>
				)}
			</Box>
			<Divider sx={{ marginBottom: 2 }} />
			<Typography sx={{ fontWeight: "bold" }} variant="body1" mb={1}>
				Transaction details
			</Typography>
			<Box
				display={"flex"}
				sx={{ alignItems: "center", justifyContent: "space-between" }}
			>
				<Box
					display={"flex"}
					sx={{ justifyContent: "space-between", alignItems: "center" }}
				>

					<Typography>Total send amount</Typography>
				</Box>
				<Box>
					<Typography>
						{totalOutboundSendAmount} {outboundCurrency}
					</Typography>
				</Box>
			</Box>
			<Box
				display={"flex"}
				sx={{ alignItems: "center", justifyContent: "space-between" }}
			>
				<Box
					display={"flex"}
					sx={{ justifyContent: "space-between", alignItems: "center" }}
				>

					<Typography>Amount we convert</Typography>
				</Box>
				<Box>
					<Typography>
						{netOutboundSendAmount} {outboundCurrency}
					</Typography>
				</Box>
			</Box>
		
			<Box
				display={"flex"}
				sx={{ alignItems: "center", justifyContent: "space-between" }}
			>
				<Box
					display={"flex"}
					sx={{ justifyContent: "space-between", alignItems: "center" }}
				>

					<Typography>Our fee</Typography>
				</Box>
				<Box>
					<Typography>
						{outboundSendFees} {outboundCurrency}
					</Typography>
				</Box>
			</Box>

			<Box
				display={"flex"}
				sx={{ alignItems: "center", justifyContent: "space-between" }}
			>
				<Box
					display={"flex"}
					sx={{ justifyContent: "space-between", alignItems: "center" }}
				>

					<Typography>Rate</Typography>
				</Box>
				<Box>
					<Typography>
						{rate}
					</Typography>
				</Box>
			</Box>
			
			<Box
				display={"flex"}
				sx={{ alignItems: "center", justifyContent: "space-between" }}
			>
				<Box
					display={"flex"}
					sx={{ justifyContent: "space-between", alignItems: "center" }}
				>

					<Typography>Receive amount</Typography>
				</Box>
				<Box>
					<Typography>
						{netRecvAmount} {recvCurrency}
					</Typography>
				</Box>
			</Box>
			<Box
				display={"flex"}
				sx={{ alignItems: "center", justifyContent: "space-between" }}
			>
				<Box
					display={"flex"}
					sx={{ justifyContent: "space-between", alignItems: "center" }}
				>

					<Typography>Purpose of transaction</Typography>
				</Box>
				<Box>
					<Typography>
						{purposeOfTransaction}
					</Typography>
				</Box>
			</Box>




{/* 
			<Box sx={{ width: "100%", textAlign: "center" }} mt={4}>
				<Button
					onClick={() => {
						navigate("/dashboard/send");
					}}
					sx={{ width: "100%", padding: 1 }}
					variant="contained"
					endIcon={<Send />}
				>
					Send Again
				</Button>

			</Box> */}
		</>
	);
};

const UpdatesSection = () => {
	return (
		<Box p={1}>
			<img
				src="/static/illustrations/in-progress.svg"
				alt="balance "
				width="100%"
			/>
		</Box>
	);
};

const PaymentLogo = ({ remittanceOrderStatus }: any) => {
	switch (remittanceOrderStatus) {

		case ("CREATED" || "WALLET_RECEIVED" || "WALLET_CREDITED" || "WALLET_DEBITED"): return (<PendingRounded style={{
			color: `#eed202`,
			width: 70,
			height: 70,
		}} />);
		case "WALLET_PAUSED": return (<PauseCircleFilledOutlined style={{
			color: `#eed202`,
			width: 70,
			height: 70,
		}} />);
		case "FAILED": return (<DangerousOutlined style={{
			color: `##D0342C`,
			width: 70,
			height: 70,
		}} />);
		case "COMPLETED": return (<CheckCircleOutlineRounded
			style={{
				color: `#00ab55`,
				width: 70,
				height: 70,
			}}
		/>);
		default: return <ErrorOutlineRounded
			style={{
				color: `#eed202`,
				width: 70,
				height: 70,
			}}
		/>
	}
};
export default () => {
	const { transactionOrderDetails } = useSelector(
		(state: RootState) => state.transactionOrderDetails
	);

	const [currentTab, setCurrentTab] = useState("Details");
	const { translate } = useLocales();
	const orderhistoryText = "Order history";
	const Transaction_ID = "Transaction_ID";
	const navigate = useNavigate();

	const { remittanceOrderStatus, remittanceOrderId, createTime } =
		transactionOrderDetails;
	const paymentStatus = {
		CREATED: 'Created',
		WALLET_RECEIVED: 'Wallet Received',
		WALLET_CREDITED: 'Wallet Credited',
		WALLET_DEBITED: 'Wallet Debited',
		SENDING: 'Sending',
		ACCEPTED: 'Accepted',
		WALLET_PAUSED: 'Wallet Paused',
		FAILED: translate(`dashboard.${"Failed"}`),
		COMPLETED: translate(`dashboard.${"Completed"}`),	};

	const copyText = (orderId: string) => {
		navigator.clipboard.writeText(orderId);
	};

	const Tab_Content = [
		{
			value: "Details",
			component: <DetailsSection {...transactionOrderDetails} />,
		},
		{
			value: "Updates",
			component: <UpdatesSection />,
		},
	];

	useEffect(() => {
		if (Object.keys(transactionOrderDetails).length === 0) {
			navigate("/dashboard/customer");
		}
	}, []);

	return (
		<>

			<RootStyle title="Send money ">
				<Container maxWidth="sm">
					<ContentStyle>
						<Stack direction="row" alignItems="center" sx={{ mb: 5 }}>
							<Grid item xs={12} md={6} lg={12}>
								<Card>
									<Box paddingTop={2}>
										<Button
											onClick={() => {
												navigate("/dashboard/customer");
											}}
										>
											<ArrowBack />
										</Button>
									</Box>
									<Box textAlign={"center"}>

										<PaymentLogo
											remittanceOrderStatus={remittanceOrderStatus}
										/>
									</Box>
									<Box textAlign={"center"}>
										<Typography>
											{paymentStatus[remittanceOrderStatus]}
										</Typography>
										<Typography variant="caption">
											{createTime}
										</Typography>
									</Box>

									<Box sx={{ p: 3, pb: 0 }}>
										<Typography variant="h6" mb={1}>
											{translate(`dashboard.${Transaction_ID}`)}
										</Typography>
										<Box
											display={"flex"}
											sx={{
												alignItems: "center",
												justifyContent: "space-between",
												backgroundColor: "#f6f6f6",
												borderRadius: 2,
												padding: 2,
											}}
										>
											<Box>
												<Typography>{remittanceOrderId}</Typography>
											</Box>
											<Box>
												<Button
													onClick={() => {
														copyText(remittanceOrderId);
													}}
												>
													<ContentCopy />
												</Button>
											</Box>
										</Box>
									</Box>
									<Box sx={{ p: 3 }}>
										<Tabs
											value={currentTab}
											scrollButtons="auto"
											variant="fullWidth"
											centered
											allowScrollButtonsMobile
											onChange={(e, value) => setCurrentTab(value)}
										>
											{Tab_Content.map((tab) => (
												<Tab
													disableRipple
													key={tab.value}
													label={tab.value.toUpperCase()}
													// icon={tab.icon}
													value={tab.value}
												/>
											))}
										</Tabs>

										{Tab_Content.map((tab) => {
											const isMatched = tab.value === currentTab;
											return (
												isMatched && (
													<Box key={tab.value}>
														{tab.component}
													</Box>
												)
											);
										})}
									</Box>
								</Card>
							</Grid>
						</Stack>
					</ContentStyle>
				</Container>
			</RootStyle>
		</>
	);
};
