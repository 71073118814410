import React from "react";
import { Autocomplete, Box, Grid, Container, Stack, Toolbar, Typography, Card, Avatar } from "@material-ui/core";
import { LoadingButton } from "@material-ui/lab";
import { alpha, experimentalStyled as styled } from '@material-ui/core/styles';
import { Paper } from '@material-ui/core';
import Button from "theme/overrides/Button";


const FormFieldPadding = '20px';
const IconWrapperStyle = styled('div')(({ theme }) => ({
    width: 24,
    height: 24,
    display: 'flex',
    borderRadius: '50%',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: theme.spacing(1),
    color: theme.palette.success.main,
    backgroundColor: alpha(theme.palette.success.main, 0.16)
}));

const RowStyle = styled('div')({
    display: 'flex',
    justifyContent: 'space-between'
});

const ContainerStyle = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(5),
    borderRadius: theme.shape.borderRadiusSm,
    border: `solid 1px ${theme.palette.divider}`,
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 100 : 800]
}));
// ----------------------------------------------------------------------

const PERCENT = 2.6;
export function AccountsCard() {
    return (

        <div>
            <Card sx={{ p: 3, cursor: 'pointer', display: 'flex', alignItems: 'center', marginBottom: '30px' }} className="card-shadow">
                <Box sx={{ flexGrow: 1 }}>
                    <Stack  >
                        <Typography variant="subtitle2" component="span">
                            Acieda Bank, Combodia
                        </Typography>
                        <Typography variant="h6" component="span">
                            $200,500
                        </Typography>
                        <RowStyle>
                            <Typography variant="subtitle2" component="span">
                                Previous Closing Balance
                            </Typography>
                            <Typography variant="subtitle2" >
                                $189,765
                            </Typography>
                        </RowStyle>
                        <RowStyle>
                            <Typography variant="subtitle2" component="span">
                                Previous Closing Time
                            </Typography>
                            <Typography variant="subtitle2" >
                                2021-11-10
                            </Typography>
                        </RowStyle>
                    </Stack>
                    <Stack spacing={3}>
                        <RowStyle>
                            <Typography variant="subtitle2">
                                Last Deposit Amount
                            </Typography>
                            <Typography variant="subtitle2">
                                $100,500
                            </Typography>
                        </RowStyle>
                        <RowStyle>
                            <LoadingButton
                                variant="outlined"
                                size="medium">
                                Reconcile
                            </LoadingButton>
                            <LoadingButton
                                variant="contained"
                                size="medium">
                                View Detailes
                            </LoadingButton>
                        </RowStyle>
                    </Stack>
                </Box>
            </Card>
        </div>
    );
}

