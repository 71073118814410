import { Icon } from '@iconify/react';
import { sentenceCase } from 'change-case';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// material
import { useTheme } from '@material-ui/core/styles';
import {
  Box,
  Card,
  Table,
  Button,
  Divider,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  TableContainer,
  Pagination,
  Grid,
  InputAdornment,
  Toolbar,
  OutlinedInput,
} from '@material-ui/core';
// utils
//
import Label from 'components/Label';
import Scrollbar from 'components/Scrollbar';
import { alpha, experimentalStyled as styled } from '@material-ui/core/styles';
import { RootState, useDispatch, useSelector } from 'redux/store';
import { useEffect, useState } from 'react';
import useLocales from 'hooks/useLocales';
import { LabelColor } from "components/Label"
import { getAllTransactionOrderDetails } from 'redux/slices/transactionOrderDetailsBO';
import { getAllTransactions, searchTransactions } from 'redux/slices/allTransactionsBO ';
import searchFill from '@iconify/icons-eva/search-fill';



const RootStyle = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 1)
}));

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter
  }),
  '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`
  }
}));
// ----------------------------------------------------------------------
const transactionsText = "Transactions"
const transactions = "Transactions"
const remittanceOrderId = "Order ID"
const amount = "Amount"
const date = "Date"
const status = "Status"
const transactionId = "Transaction Id"
const receiverName = "Receiver name"
const senderName = "Sender name"

const style = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexWrap: 'wrap',
  '& > *': { my: 1 }
} as const;

export default function TransactionsTable() {
  const { translate } = useLocales();
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [senderId, setSenderId] = useState('');
  const [senderEmail, setSenderEmail] = useState('');
  const [senderPhoneNumber, setSenderPhoneNumber] = useState('');
  const [receiverEmail, setReceiverEmail] = useState('');
  const [receiverPhoneNumber, setReceiverPhoneNumber] = useState('');
  const [orderId, setOrderId] = useState('');

  const { recentTransactions } = useSelector(
    (state: RootState) => state.recentTransactions
  );

  const { allTransactions } = useSelector(
    (state: RootState) => state.allTransactions
  );

  useEffect(() => {
    dispatch(getAllTransactions(1, 20));
  }, [dispatch]);
  console.log(allTransactions);

  type remittanceStatusType = "CREATED" | "WALLET_RECEIVED" | "WALLET_CREDITED" | "WALLET_DEBITED" | "WALLET_PAUSED" | "COMPLETED" | "FAILED";

  const statusColorThemes = {
    "CREATED" : "warning",
    "WALLET_RECEIVED" : "primary",
    "WALLET_CREDITED" : "secondary",
    "WALLET_DEBITED" : "info",
    "WALLET_PAUSED" : "warning",
    "FAILED" : "error",
    "COMPLETED" : "success"
  }

  const paginationFunction = (e: any, page: number) => {
    if (senderId || senderEmail || senderPhoneNumber || receiverEmail || receiverPhoneNumber || orderId) {
      dispatch(searchTransactions(senderId, page, senderEmail, senderPhoneNumber, receiverEmail, receiverPhoneNumber, orderId, 20));
    }
    else {
      dispatch(getAllTransactions(page, 20));
    }
  }
  const fetchOrderDetails = async (remittanceOrderId: string) => {
    dispatch(getAllTransactionOrderDetails(remittanceOrderId, navigate))
  }

  const onFilterSenderId = (senderId: string) => {
    setSenderId(senderId);
    dispatch(searchTransactions(senderId, 1, senderEmail, senderPhoneNumber, receiverEmail, receiverPhoneNumber, orderId, 20));
  }

  const onFilterSenderEmail = (senderEmail: string) => {
    setSenderEmail(senderEmail);
    dispatch(searchTransactions(senderId, 1, senderEmail, senderPhoneNumber, receiverEmail, receiverPhoneNumber, orderId, 20));
  }

  const onFilterSenderPhoneNumber = (senderPhoneNumber: string) => {
    setSenderPhoneNumber(senderPhoneNumber);
    dispatch(searchTransactions(senderId, 1, senderEmail, senderPhoneNumber, receiverEmail, receiverPhoneNumber, orderId, 20));
  }

  const onFilterReceiverEmail = (receiverEmail: string) => {
    setReceiverEmail(receiverEmail)
    dispatch(searchTransactions(senderId, 1, senderEmail, senderPhoneNumber, receiverEmail, receiverPhoneNumber, orderId, 20));
  }

  const onFilterReceiverPhoneNumber = (receiverPhoneNumber: string) => {
    setReceiverPhoneNumber(receiverPhoneNumber)
    dispatch(searchTransactions(senderId, 1, senderEmail, senderPhoneNumber, receiverEmail, receiverPhoneNumber, orderId, 20));
  }

  const onFilterOrderId = (orderId: string) => {
    setOrderId(orderId)
    dispatch(searchTransactions(senderId, 1, senderEmail, senderPhoneNumber, receiverEmail, receiverPhoneNumber, orderId, 20));
  }


  return (
    <Card sx={{ pb: 3 }}>
      <Grid container sx={{ pb: 3, pt: 3 }} >

        <Grid item md={2} className='full-width' >
          {/* <UserListSearchBySenderId /> */}
          <RootStyle>
            <SearchStyle
              // value={filterName}
              className='full-width'
              onChange={(e) => onFilterSenderId(e.target.value)}
              placeholder={translate(`dashboard.${"Sender ID..."}`)}
              startAdornment={
                <InputAdornment position="start">
                  <Box component={Icon} icon={searchFill} sx={{ color: 'text.disabled' }} />
                </InputAdornment>
              }
            />

          </RootStyle>
        </Grid>

        <Grid item md={2} className='full-width' >
          {/* <UserListSearchBySenderEmail /> */}
          <RootStyle>
            <SearchStyle
              // value={filterName}
              className='full-width'
              onChange={(e) => onFilterSenderEmail(e.target.value)}
              placeholder={translate(`dashboard.${"Sender email..."}`)}
              startAdornment={
                <InputAdornment position="start">
                  <Box component={Icon} icon={searchFill} sx={{ color: 'text.disabled' }} />
                </InputAdornment>
              }
            />

          </RootStyle>
        </Grid>

        <Grid item md={2} className='full-width'>
          {/* <UserListSearchBySenderPhoneNumber /> */}
          <RootStyle
          >
            <SearchStyle
              // value={filterName}
              onChange={(e) => onFilterSenderPhoneNumber(e.target.value)}
              className='full-width'
              placeholder={translate(`dashboard.${"Sender phone number..."}`)}
              startAdornment={
                <InputAdornment position="start">
                  <Box component={Icon} icon={searchFill} sx={{ color: 'text.disabled' }} />
                </InputAdornment>
              }
            />
          </RootStyle>
        </Grid>

        <Grid item md={2} className='full-width'>
          {/* <UserListSearchByReceiverEmail /> */}
          <RootStyle
          >
            <SearchStyle
              // value={filterName}
              onChange={(e) => onFilterReceiverEmail(e.target.value)}
              className='full-width'
              placeholder={translate(`dashboard.${"Receiver email..."}`)}
              startAdornment={
                <InputAdornment position="start">
                  <Box component={Icon} icon={searchFill} sx={{ color: 'text.disabled' }} />
                </InputAdornment>
              }
            />

          </RootStyle>
        </Grid>

        <Grid item md={2} className='full-width'>
          {/* <UserListSearchByReceiverPhoneNumber /> */}
          <RootStyle
          >
            <SearchStyle
              // value={filterName}
              onChange={(e) => onFilterReceiverPhoneNumber(e.target.value)}
              className='full-width'
              placeholder={translate(`dashboard.${"Receiver phone number..."}`)}
              startAdornment={
                <InputAdornment position="start">
                  <Box component={Icon} icon={searchFill} sx={{ color: 'text.disabled' }} />
                </InputAdornment>
              }
            />

          </RootStyle>
        </Grid>
        <Grid item md={2} className='full-width'>
          {/* <UserListSearchByOrderId /> */}
          <RootStyle
          >
            <SearchStyle
              // value={filterName}
              onChange={(e) => onFilterOrderId(e.target.value)}
              className='full-width'
              placeholder={translate(`dashboard.${remittanceOrderId}`)}
              startAdornment={
                <InputAdornment position="start">
                  <Box component={Icon} icon={searchFill} sx={{ color: 'text.disabled' }} />
                </InputAdornment>
              }
            />

          </RootStyle>
        </Grid>
      </Grid>

      <Scrollbar>
        <TableContainer sx={{ minWidth: 720, minHeight: 700 }}>
          <Table sx={{ marginTop: 1 }}>
            <TableHead >
              <TableRow>
                <TableCell>{translate(`dashboard.${transactionId}`)}</TableCell>
                <TableCell>{translate(`dashboard.${senderName}`)}</TableCell>
                <TableCell>{translate(`dashboard.${receiverName}`)}</TableCell>
                <TableCell>{translate(`dashboard.${amount}`)}</TableCell>
                <TableCell>{translate(`dashboard.${date}`)}</TableCell>
                <TableCell>{translate(`dashboard.${status}`)}</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
           
            <TableBody>
              {allTransactions.map((row) => {
                const transaction_id_list = row.remittanceOrderId.split('-');
                const transaction_id = transaction_id_list[transaction_id_list?.length - 1]?.toUpperCase();
                const status = row.remittanceOrderStatus as remittanceStatusType;
                const colorPicker: LabelColor | undefined = statusColorThemes[status] as LabelColor | undefined
                return (
                  <TableRow key={row.remittanceOrderId} sx={{ cursor: "pointer" }} onClick={() => { fetchOrderDetails(row.remittanceOrderId) }}>
                    <TableCell>
                      {row.remittanceOrderId.substring(row.remittanceOrderId.lastIndexOf('-') + 1, row.remittanceOrderId.length).toLocaleUpperCase()}
                    </TableCell>
                    <TableCell>{row.senderName} </TableCell>
                    <TableCell>{row.receiverName} </TableCell>
                    <TableCell>{row.totalOutboundSendAmount} JPY </TableCell>
                    <TableCell>{row.createTime.toString()}</TableCell>
                    <TableCell>
                      <Label
                        variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
                        color={colorPicker}
                      >
                        {sentenceCase(row.remittanceOrderStatus)}
                      </Label>
                    </TableCell>

                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Scrollbar>

      <Divider />
      <Grid item xs={12}>
        <Box sx={style}>
          <Pagination onChange={(e, page) => paginationFunction(e, page)} count={20} color="primary" />
        </Box>
      </Grid>

    </Card>
  );
}
