// material
import {
    Card,
    Table,
    TableRow,
    TableBody,
    TableCell,
    TableHead,
    TableContainer,
    Container,
    
} from '@material-ui/core';
// utils
import { RootState, useDispatch, useSelector } from 'redux/store';
import { useEffect } from 'react';
import { getRoutesList } from 'redux/slices/routes';
import Page from 'components/Page';
import HeaderBreadcrumbs from 'components/HeaderBreadcrumbs';
import useLocales from 'hooks/useLocales';

// ----------------------------------------------------------------------

export default function Routes() {
    const { translate } = useLocales();
    const dispatch = useDispatch();
    const { routes } = useSelector(
        (state: RootState) => state.routes
    );


    useEffect(() => {
        dispatch(getRoutesList());
    }, [dispatch]);


    return (
        <>  <Page title="Route">
            <Container maxWidth="xl">

                <HeaderBreadcrumbs
                    heading={translate(`dashboard.${"Routes"}`)}
                    links={[
                        // { name: 'Amount', href: PATH_DASHBOARD.user.root },
                        // { name: 'Delivery Options', href: PATH_DASHBOARD.user.root },
                    ]}
                />
                <Card sx={{ pb: 3 }}>
                    <TableContainer sx={{ minWidth: 720 }} >
                        <Table >
                            <TableHead>
                                <TableRow>
                                    <TableCell>{translate(`dashboard.${"Route ID"}`)}</TableCell>
                                    <TableCell>{translate(`dashboard.${"Route name"}`)}</TableCell>
                                    <TableCell>{translate(`dashboard.${"Outbound destination ID"}`)}</TableCell>
                                    <TableCell>{translate(`dashboard.${"Outbound currency code"}`)}</TableCell>
                                    <TableCell>{translate(`dashboard.${"Send currency code"}`)}</TableCell>
                                    <TableCell>{translate(`dashboard.${"Receive currency code"}`)}</TableCell>
                                    <TableCell>{translate(`dashboard.${"Partner ID"}`)}</TableCell>
                                    <TableCell>{translate(`dashboard.${"Delivery option ID"}`)}</TableCell>
                                    <TableCell>{translate(`dashboard.${"Receive provider ID"}`)}</TableCell>
                                </TableRow>
                            </TableHead>
                            {routes !== null &&
                                <TableBody>
                                    {routes.map((row) => (
                                        <TableRow key={row.routeId}>
                                            <TableCell>{row.routeId}</TableCell>
                                            <TableCell>{row.routeName}</TableCell>
                                            <TableCell>{row.outboundDestinationId}</TableCell>
                                            <TableCell>{row.outboundCurrencyCode}</TableCell>
                                            <TableCell>{row.sendCurrencyCode}</TableCell>
                                            <TableCell>{row.recvCurrencyCode}</TableCell>
                                            <TableCell>{row.partnerId}</TableCell>
                                            <TableCell>{row.deliveryOptionId}</TableCell>
                                            <TableCell>{row.recvProviderId}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>}
                        </Table>

                    </TableContainer>
                </Card>
            </Container>
        </Page></>
    );
}