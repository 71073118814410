import React from "react";
import 'simplebar/src/simplebar.css';
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import { AuthProvider } from "./contexts/JWTContext";
import App from "./App";
import "./locales/i18n";
import { Provider as ReduxProvider } from 'react-redux';
import { store, persistor } from './redux/store';
import { PersistGate } from 'redux-persist/lib/integration/react';
import LoadingScreen from './components/LoadingScreen';


ReactDOM.render(
	<Router>
		<ReduxProvider store={store}>
		<PersistGate loading={<LoadingScreen />} persistor={persistor}>
		<AuthProvider>
			<App />
		</AuthProvider>
		</PersistGate>
		</ReduxProvider>
	</Router>,
	document.getElementById("root")
);
