import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
// utils
import axios from '../../utils/axios';
import { IdentificationDocType } from '../../@types/identificationDocTypes';
import URLConstants from 'constants/urlConstants';

type DeliveryOptionsState = {
    isLoading: boolean;
    error: boolean;
    identificationDoc: IdentificationDocType[];
}

const initialState: DeliveryOptionsState = {
    isLoading: false,
    error: false,
    identificationDoc: [],
}

const slice = createSlice({
    name: 'identificationDoc',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        // GET IDENTIFICATION DOC
        getIdentificationDocSuccess(state, action) {
            state.isLoading = false;
            state.identificationDoc = action.payload;
        },
    }
});

// Reducer
export default slice.reducer;


export function getIdentificationDoc() {
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
        const response = await axios.get(URLConstants.IDENTIFICATION_DOC_BASE_URL);
        dispatch(slice.actions.getIdentificationDocSuccess(response.data.data));
      } catch (error) {
        dispatch(slice.actions.hasError(error));
      }
    };
  }