import { map, filter } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
// utils
import axios from '../../utils/axios';
import { currencyApi } from '../../@types/currencyData';
import URLConstants from 'constants/urlConstants';

type CurrencyState = {
    isLoading: boolean;
    error: boolean;
    currency: currencyApi[];
}

const initialState: CurrencyState = {
    isLoading: false,
    error: false,
    currency: [],
}

const slice = createSlice({
    name: 'vurrency',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        // GET COUNTRY
        getCurrencySuccess(state, action) {
            state.isLoading = false;
            state.currency = action.payload;
        },
    }
});

// Reducer
export default slice.reducer;


export function getCurrency() {
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
        const response = await axios.get(URLConstants.CURRENCIES_BASE_URL);
        dispatch(slice.actions.getCurrencySuccess(response.data.data));
      } catch (error) {
        dispatch(slice.actions.hasError(error));
      }
    };
  }