import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { useCallback } from 'react';
import { Form, FormikProvider, useFormik } from 'formik';
// material
import {
    Box,
    Grid,
    Card,
    Stack,
    Switch,
    TextField,
    FormControlLabel,
    Typography,
    FormHelperText,
    Avatar
} from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
// hooks
import useAuth from 'hooks/useAuth';
import useIsMountedRef from 'hooks/useIsMountedRef';
//import { UploadAvatar } from '../../../upload';
// utils
import { fData } from 'utils/formatNumber';
// @types
//import { User } from '@types/account';
//
//import countries from '../countries';
//import { User } from '@types/account';
import { countries } from 'constants/country';
import { User } from '../../@types/account';
import MyAvatar from 'components/MyAvatar';

// ----------------------------------------------------------------------

interface InitialState extends Omit<User, 'password' | 'id' | 'role'> {
    afterSubmit?: string;
}

export default function AccountGeneral() {
    const isMountedRef = useIsMountedRef();
    const { enqueueSnackbar } = useSnackbar();
    const { user, updateProfile } = useAuth();

    const UpdateUserSchema = Yup.object().shape({
        displayName: Yup.string().required('Name is required')
    });

    const formik = useFormik<InitialState>({
        enableReinitialize: true,
        initialValues: {
            displayName: user?.displayName || '',
            email: user?.email,
            photoURL: user?.photoURL,
            phoneNumber: user?.phoneNumber,
            country: user?.country,
            address: user?.address,
            state: user?.state,
            city: user?.city,
            zipCode: user?.zipCode,
            about: user?.about,
            isPublic: user?.isPublic
        },

        validationSchema: UpdateUserSchema,
        onSubmit: async (values, { setErrors, setSubmitting }) => {
            try {
                updateProfile?.();
                enqueueSnackbar('Update success', { variant: 'success' });
                if (isMountedRef.current) {
                    setSubmitting(false);
                }
            } catch (error) {
                if (isMountedRef.current) {
                    //setErrors({ afterSubmit: error.code });
                    setSubmitting(false);
                }
            }
        }
    });

    const { values, errors, touched, isSubmitting, handleSubmit, getFieldProps, setFieldValue } =
        formik;

    const handleDrop = useCallback(
        (acceptedFiles) => {
            const file = acceptedFiles[0];
            if (file) {
                setFieldValue('photoURL', {
                    ...file,
                    preview: URL.createObjectURL(file)
                });
            }
        },
        [setFieldValue]
    );

    return (
        <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={4}>
                        <Card sx={{ py: 10, px: 3, textAlign: 'center', alignItems: 'center' }}>
                            <Box sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}>
                                <Avatar
                                    sx={{ width: '100px', height: '100px' }}
                                    alt=""
                                    src={user?.photoURL} />
                            </Box>

                            {/* <UploadAvatar
                accept="image/*"
                file={values.photoURL}
                maxSize={3145728}
                onDrop={handleDrop}
                error={Boolean(touched.photoURL && errors.photoURL)}
                caption={
                  <Typography
                    variant="caption"
                    sx={{
                      mt: 2,
                      mx: 'auto',
                      display: 'block',
                      textAlign: 'center',
                      color: 'text.secondary'
                    }}
                  >
                    Allowed *.jpeg, *.jpg, *.png, *.gif
                    <br /> max size of {fData(3145728)}
                  </Typography>
                }
              /> */}

                            <FormHelperText error sx={{ px: 2, textAlign: 'center' }}>
                                {touched.photoURL && errors.photoURL}
                            </FormHelperText>

                            <FormControlLabel
                                control={<Switch {...getFieldProps('isPublic')} color="primary" />}
                                labelPlacement="start"
                                label="Public Profile"
                                sx={{ mt: 5 }}
                            />
                        </Card>
                    </Grid>

                    <Grid item xs={12} md={8}>
                        <Card sx={{ p: 3 }}>
                            <Stack spacing={{ xs: 2, md: 3 }}>
                                <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                                    <TextField fullWidth disabled label="Name" {...getFieldProps('displayName')} />
                                    <TextField fullWidth disabled label="Email Address" {...getFieldProps('email')} />
                                </Stack>

                                <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                                    <TextField fullWidth disabled label="Phone Number" {...getFieldProps('phoneNumber')} />
                                    <TextField fullWidth disabled label="Address" {...getFieldProps('address')} />
                                </Stack>

                                <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                                    <TextField
                                        select
                                        fullWidth
                                        disabled
                                        label="Country"
                                        placeholder="Country"
                                        {...getFieldProps('country')}
                                        SelectProps={{ native: true }}
                                        error={Boolean(touched.country && errors.country)}
                                        helperText={touched.country && errors.country}
                                    >
                                        <option value="" />
                                        {countries.map((option) => (
                                            <option key={option.code} value={option.label}>
                                                {option.label}
                                            </option>
                                        ))}
                                    </TextField>
                                    <TextField fullWidth label="State/Region" {...getFieldProps('state')} />
                                </Stack>

                                <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                                    <TextField fullWidth disabled label="City" {...getFieldProps('city')} />
                                    <TextField fullWidth disabled label="Zip/Code" {...getFieldProps('zipCode')} />
                                </Stack>

                                <TextField
                                    {...getFieldProps('about')}
                                    fullWidth
                                    disabled
                                    multiline
                                    minRows={4}
                                    maxRows={4}
                                    label="About"
                                />
                            </Stack>

                            <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
                                <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                                    Save Changes
                                </LoadingButton>
                            </Box>
                        </Card>
                    </Grid>
                </Grid>
            </Form>
        </FormikProvider>
    );
}
