

// material
import { experimentalStyled as styled } from "@material-ui/core/styles";
import {
	Box,
	Card,
	Stack,
	Container,
	Typography,
	Grid,
} from "@material-ui/core";

// components
import Page from "../../components/Page";



import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import { AccountsCard } from "./AccountsCard";
import useLocales from "hooks/useLocales";


// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
	[theme.breakpoints.up("md")]: {
		display: "flex",
	},
}));

const SectionStyle = styled(Card)(({ theme }) => ({
	width: "100%",
	maxWidth: 464,
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
	//margin: theme.spacing(2, 0, 2, 2),
	backgroundColor: "#c8facd",
	boxShadow: "none",
	borderRadius: "0px",
}));

const ContentStyle = styled("div")(({ theme }) => ({
	maxWidth: 1000,
	margin: "auto",
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
	padding: theme.spacing(2, 0),
}));


export default () => {
	const { translate } = useLocales();
	return (
		<>
			<Page>
				<Container maxWidth="xl">
				<HeaderBreadcrumbs
                        heading={translate(`dashboard.${"Account"}`)}
                        links={[
                            // { name: 'Amount', href: PATH_DASHBOARD.user.root },
                            // { name: 'Delivery Options', href: PATH_DASHBOARD.user.root },
                        ]} />
					<Grid container spacing={3}>
						<Grid item xs={12} md={4}>
							<AccountsCard />
						</Grid>
						<Grid item xs={12} md={4}>
							<AccountsCard />
						</Grid>
						<Grid item xs={12} md={4}>
							<AccountsCard />
						</Grid>
					</Grid>
				</Container>
			</Page>

		</>
	);
};
