// material
import {
    Card,
    Table,
    TableRow,
    TableBody,
    TableCell,
    TableHead,
    TableContainer,
    Container,
} from '@material-ui/core';
// utils
import { RootState, useDispatch, useSelector } from 'redux/store';
import { useEffect } from 'react';
import { getPartnersList } from 'redux/slices/partners';
import Page from 'components/Page';
import HeaderBreadcrumbs from 'components/HeaderBreadcrumbs';
import useLocales from 'hooks/useLocales';
// ----------------------------------------------------------------------

export default function Partners() {
    const { translate } = useLocales();
    const dispatch = useDispatch();
    const { partners } = useSelector(
        (state: RootState) => state.partners
    );


    useEffect(() => {
        dispatch(getPartnersList());
    }, [dispatch]);


    return (
        <>  <Page title="Partners">
            <Container maxWidth="xl">

                <HeaderBreadcrumbs
                    heading={translate(`dashboard.${"Partners"}`)}
                    links={[
                        // { name: 'Amount', href: PATH_DASHBOARD.user.root },
                        // { name: 'Delivery Options', href: PATH_DASHBOARD.user.root },
                    ]}
                />
                <Card sx={{ pb: 3 }}>
                    <TableContainer sx={{ minWidth: 720 }}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>{translate(`dashboard.${"Partner ID"}`)}</TableCell>
                                    <TableCell>{translate(`dashboard.${"Name"}`)}</TableCell>
                                    <TableCell>{translate(`dashboard.${"Code"}`)}</TableCell>
                                </TableRow>
                            </TableHead>
                            {partners !== null &&
                                <TableBody>
                                    {partners.map((row) => (
                                        <TableRow key={row.partnerId}>
                                            <TableCell>{row.partnerId}</TableCell>
                                            <TableCell>{row.partnerName}</TableCell>
                                            <TableCell>{row.partnerCode}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>}
                        </Table>

                    </TableContainer>
                </Card>
            </Container>
        </Page></>
    );
}