import { map, filter } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
// utils
import axios from '../../utils/axios';
import { transationsCountType } from '../../@types/transactionCount';
import URLConstants from 'constants/urlConstants';

type TransactionsCount = {
  isLoading: boolean;
  error: boolean;
  transationsCount: transationsCountType;
}

const initialState: TransactionsCount = {
  isLoading: false,
  error: false,
  transationsCount: {
    "dailyTransactions": 0,
    "weeklyTransactions": 0,
    "monthlyTransactions": 0,
    "fyTransactions": 0
  },
}

const slice = createSlice({
  name: 'transactionsCount',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET TRANSACTION COUNT
    getTransactionCountSuccess(state, action) {
      state.isLoading = false;
      state.transationsCount = action.payload;
    },
  }
});

// Reducer
export default slice.reducer;


export function getTransactionCount() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(URLConstants.TXN_COUNT_BASE_URL);
      dispatch(slice.actions.getTransactionCountSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}