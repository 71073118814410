import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
// utils
import axios from '../../utils/axios';
import { EditAddressType } from '../../@types/editAddressType';


type EditAddressState = {
    isLoading: boolean;
    error: boolean;
    editAddress: EditAddressType;
}

const initialState: EditAddressState = {
    isLoading: false,
    error: false,
    editAddress: {
        "senderAddressId": 0,
        "senderId": 0,
        "senderAddress1": "",
        "senderAddress2": "",
        "senderCity": "",
        "senderStateProvince": "",
        "senderZipCode": "",
        "senderCountry": "",
        "createTime": new Date(),
        "updateTime": new Date()
    },
}

const slice = createSlice({
    name: 'editAddress',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        editAddressSuccess(state, action) {
            state.isLoading = false;
            state.editAddress = action.payload;
        },
    }
});

// Reducer
export default slice.reducer;

export function editAddressInfo(userId: number, values: any) {
    return async () => {
        console.log(values);
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.put(`https://6jfjr9vffh.execute-api.ap-south-1.amazonaws.com/staging/mcus/bo/customer/${userId}/address`,
                {
                    "senderAddress1": values.senderAddress1,
                    "senderAddress2": values.senderAddress2,
                    "senderCity": values.senderCity,
                    "senderStateProvince": values.senderStateProvince,
                    "senderZipCode": values.senderZipCode,
                    "senderCountry": values.senderCountry,
                }
            );
            dispatch(slice.actions.editAddressSuccess(response.data.data));
        } catch (error :any) {
            dispatch(slice.actions.hasError(error));
            return {message : error?.message, hasError : true} 
        }
    };
}