// import css variables from "./App.scss"
import "./App.scss";
import { Wrapper } from "./container/Wrapper";
import LoadingScreen from "./components/LoadingScreen";
import ThemeConfig from "./theme";
import Routes from "./routes";
import useAuth from "./hooks/useAuth";
import NotistackProvider from "./components/NotistackProvider";

export default () => {
	const { isInitialized } = useAuth();

	return (
		<div>
			<ThemeConfig>
				<NotistackProvider>
					<Wrapper>
						{isInitialized ? <Routes /> : <LoadingScreen />}
					</Wrapper>
				</NotistackProvider>
			</ThemeConfig>
		</div>
	);
};
