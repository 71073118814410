import {
  TextField,
  Grid,
  Card,
} from "@material-ui/core";
import { LoadingButton } from "@material-ui/lab";
import { experimentalStyled as styled } from "@material-ui/core/styles";
import * as Yup from "yup";
import {
  useFormik,

} from "formik";
import { RootState, useDispatch, useSelector } from "redux/store";
import { useCallback, useContext } from "react";
import { AppContext } from "contexts/SendMoneyContext";
import { getCreateRouteSpecificReceiver } from "redux/slices/createNewReceiver";
import React from "react";
import Confirm from "../Confirm";

type InitialValues = {
  receiverFirstName: string;
  receiverLastName: string;
  // address: string;
  // city: string;
  // stateProvince: string;
  receiverPhoneNumber: string;
  email: string;

};

type confirmKeyType = {
  confirmKey: number,
};

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 380,
  margin: "auto",
  display: "flex",
  minHeight: "100vh",
  flexDirection: "column",
  justifyContent: "center",
  padding: theme.spacing(12, 0),
}));

function MGBankDepositJPYVND() {

  const dispatch = useDispatch();
  const { createRouteSpecificReceiver } = useSelector(
    (state: RootState) => state.createRouteSpecificReceiver
  );


  const {
    formValues,
    handleChange,
  } = useContext(AppContext)
  const { routeId, receiverId, receiverFirstName, receiverLastName, phoneNumber, email } = formValues

  const isError = useCallback(
    () =>
      Object.keys({ receiverFirstName, receiverLastName, phoneNumber, email }).some(
        (name) =>
          (formValues[name].required && !formValues[name].value) ||
          formValues[name].error
      ),
    [formValues, receiverFirstName, receiverLastName, phoneNumber, email]
  )
  const formik = useFormik<InitialValues>({
    initialValues: {
      receiverFirstName: "",
      receiverLastName: "",
      // address: "",
      // city: "",
      // stateProvince: "",
      receiverPhoneNumber: "",
      email: "",

    },

    validationSchema: Yup.object().shape({
      receiverFirstName: Yup.string()
        .max(15, "Must be 15 characters or less")
        .required("*Required"),

      receiverLastName: Yup.string()
        .max(20, "Must be 20 characters or less")
        .required("*Required"),

      // address: Yup.string()
      //   .max(20, "Must be 20 characters or less")
      //   .required("*Address is required"),

      // city: Yup.string()
      //   .max(20, "Must be 20 characters or less")
      //   .required("*City is required"),

      // stateProvince: Yup.string()
      //   .max(20, "Must be 20 characters or less")
      //   .required("*State Province is required"),

      receiverPhoneNumber: Yup.string()
        .max(20, "Must be 20 characters or less")
        .required("*Phone number is required"),

      email: Yup.string()
        .max(50, "Must be 20 characters or less")
        .required("*E-mail is required"),
    }),

    onSubmit: (values) => {
      formValues.receiverFirstName.value = values.receiverFirstName;
      formValues.receiverLastName.value = values.receiverLastName;
      formValues.phoneNumber.value = values.receiverPhoneNumber;
      formValues.email.value = values.email;

      let senderId = localStorage.getItem('senderId');
      if (senderId !== null && routeId.value !== null && routeId.value !== 0) {
        dispatch(getCreateRouteSpecificReceiver(routeId.value, senderId, values));
        console.log(createRouteSpecificReceiver);
        setFormType(1)
      }
      // alert(JSON.stringify(values, null, 2));
    },
  });
  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } =
    formik;

  const [formType, setFormType] = React.useState(0);

  const ConfirmpageView = ({ confirmKey }: confirmKeyType) => {
    console.log('success');
    return <Confirm confirmKeyValue={confirmKey} />
  }
  if (createRouteSpecificReceiver !== null && createRouteSpecificReceiver.receiverId !== 0) {
    console.log(createRouteSpecificReceiver);
    formValues.receiverId.value = createRouteSpecificReceiver.receiverId;
  }
  return (
    <>
      {formType == 1 && <ConfirmpageView confirmKey={formType} />}
      {formType !== 1 &&
        <Card sx={{ p: 4, overflow: 'visible' }}>
          <form onSubmit={formik.handleSubmit}>
            <Grid container direction={"column"} spacing={3}>
              <Grid item>
                <TextField
                  type="receiverFirstName"
                  label="First Name"
                  fullWidth
                  {...getFieldProps("receiverFirstName")}
                  error={Boolean(
                    touched.receiverFirstName && errors.receiverFirstName
                  )}
                  helperText={touched.receiverFirstName && errors.receiverFirstName}
                />
              </Grid>

              <Grid item>
                <TextField
                  type="receiverLastName"
                  label="Last Name"
                  fullWidth
                  {...getFieldProps("receiverLastName")}
                  error={Boolean(touched.receiverLastName && errors.receiverLastName)}
                  helperText={touched.receiverLastName && errors.receiverLastName}
                />
              </Grid>

              {/* <Grid item>
          <TextField
            label="Address"
            type="address"
            fullWidth
            {...getFieldProps("address")}
            error={Boolean(touched.address && errors.address)}
            helperText={touched.address && errors.address}
          />
        </Grid> */}

              {/* <Grid item>
          <TextField
            fullWidth
            type="city"
            label="City"
            {...getFieldProps("city")}
            error={Boolean(touched.city && errors.city)}
            helperText={touched.city && errors.city}
          />
        </Grid> */}

              {/* <Grid item>
          <TextField
            fullWidth
            type="stateProvince"
            label="State Province"
            {...getFieldProps("stateProvince")}
            error={Boolean(touched.stateProvince && errors.stateProvince)}
            helperText={touched.stateProvince && errors.stateProvince}
          />
        </Grid> */}

              <Grid item>
                <TextField
                  fullWidth
                  type="receiverPhoneNumber"
                  label="Phone Number"
                  {...getFieldProps("receiverPhoneNumber")}
                  error={Boolean(touched.receiverPhoneNumber && errors.receiverPhoneNumber)}
                  helperText={touched.receiverPhoneNumber && errors.receiverPhoneNumber}
                />
              </Grid>

              <Grid item>
                <LoadingButton
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  loading={isSubmitting}
                >
                  Submit
                </LoadingButton>
              </Grid>
            </Grid>
          </form>
        </Card>}
    </>
  );
}

export default MGBankDepositJPYVND;
