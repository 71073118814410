import React, { useEffect } from "react";
import { Box, Grid, Stack, Toolbar, Typography, Card, Avatar } from "@material-ui/core";
import { LoadingButton } from "@material-ui/lab";
import { useCallback, useContext } from 'react'
import { AppContext } from "contexts/SendMoneyContext";
import { Link } from 'react-router-dom'
import { PATH_DASHBOARD } from "routes/paths";
import { alpha, experimentalStyled as styled } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import MGCashPickupGenGen from "./RegisterReceiverForms/MGCashPickupGenGen";
import { useNavigate } from 'react-router-dom';
import MGBankDepositJPYINR from "./RegisterReceiverForms/MGBankDepositJPYINR";
import MGBankDepositJPYBRL from "./RegisterReceiverForms/MGBankDepositJPYBRL";
import MGBankDepositJPYCNY from "./RegisterReceiverForms/MGBankDepositJPYCNY";
import MGBankDepositJPYIDR from "./RegisterReceiverForms/MGBankDepositJPYIDR";
import MGBankDepositJPYNGN from "./RegisterReceiverForms/MGBankDepositJPYNGN";
import MGBankDepositJPYPHP from "./RegisterReceiverForms/MGBankDepositJPYPHP";
import MGBankDepositJPYLKR from "./RegisterReceiverForms/MGBankDepositJPYLKR";
import MGBankDepositJPYVND from "./RegisterReceiverForms/MGBankDepositJPYVND";
import Confirm from "./Confirm";
import useLocales from "hooks/useLocales";
import { RootState, useDispatch, useSelector } from "redux/store";
import { getRouteSpecificReceiver } from "redux/slices/routeSpecificReceivers";
import MGBankDepositJPYAUD from "./RegisterReceiverForms/MGBankDepositJPYAUD";
import MGBankDepositJPYBDT from "./RegisterReceiverForms/MGBankDepositJPYBDT";
import MGBankDepositJPYEURFRA from "./RegisterReceiverForms/MGBankDepositJPYEURFRA";
import MGBankDepositJPYEURGER from "./RegisterReceiverForms/MGBankDepositJPYEURGER";
import MGBankDepositJPYEURITA from "./RegisterReceiverForms/MGBankDepositJPYEURITA";
import MGBankDepositJPYGBR from "./RegisterReceiverForms/MGBankDepositJPYGBR";
import MGBankDepositJPYGHS from "./RegisterReceiverForms/MGBankDepositJPYGHS";
import MGBankDepositJPYNZD from "./RegisterReceiverForms/MGBankDepositJPYNZD";
import MGBankDepositJPYPKR from "./RegisterReceiverForms/MGBankDepositJPYPKR";
import MGBankDepositJPYSGD from "./RegisterReceiverForms/MGBankDepositJPYSGD";
import MGBankDepositJPYTHB from "./RegisterReceiverForms/MGBankDepositJPYTHB";
import MGBankDepositJPYTHBSCB from "./RegisterReceiverForms/MGBankDepositJPYTHBSCB";
import MGBankDepositJPYTHBEarthPot from "./RegisterReceiverForms/MGBankDepositJPYTHBEarthPot";
import MGBankDepositJPYKRW from "./RegisterReceiverForms/MGBankDepositJPYKRW";

const FormFieldPadding = '20px';
const IconWrapperStyle = styled('div')(({ theme }) => ({
    width: 24,
    height: 24,
    display: 'flex',
    borderRadius: '50%',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: theme.spacing(1),
    color: theme.palette.success.main,
    backgroundColor: alpha(theme.palette.success.main, 0.16)
}));

// ----------------------------------------------------------------------

const PERCENT = 2.6;

type formNameType = {
    formType: string,
};
type newRecipientFormKeyType = {
    newRecipientFormKey: number,
};
type receipientDataType = {
    firstName: string,
    middlName: string,
    lastName: string,
    phoneNumber: string,
    email: string,
};

type confirmKeyType = {
    confirmKey: number,
};

const WhoSendingText = "Who are you sending to?";
const AddNewRecipientText = "Add a new Recipient"
const ContinueText = "Continue";
const yourRecipientsText = "Your Recipients";


export function ReceiverForm({ newRecipientFormKey }: newRecipientFormKeyType) {
    console.log(newRecipientFormKey);

    const {
        formValues,
        handleChange,

    } = useContext(AppContext)
    const { routeId, outboundQuoteId, sendAmount, receiveAmount, deliverTo, payWith, sendCurrency, receiveCurrency, accountType, receiverFirstName, receiverMiddleName, receiverLastName, accountNumber, IFSCCode } = formValues
    const [formType, setFormType] = React.useState("");
    const [confirmFormKey, setConfirmFormType] = React.useState(0);
    const [selected, setSelected] = React.useState(0);
    //const [newReceiverKey, setNewReceiverKey] = React.useState(0);
    const navigate = useNavigate();
    const isError = useCallback(
        () =>
            Object.keys({ accountType }).some(
                (name) =>
                    (formValues[name].required && !formValues[name].value) ||
                    formValues[name].error
            ),
        [formValues, accountType]
    )
    console.log(deliverTo.value);
    console.log(routeId.value);
    const dispatch = useDispatch();

    const { routeSpecificReceiver } = useSelector(
        (state: RootState) => state.routeSpecificReceivers
    );

    useEffect(() => {
        dispatch(getRouteSpecificReceiver(routeId.value));//TODO pass the original routeId
    }, [1]);


    console.log(routeSpecificReceiver);


    //DeliverTo Value for thailand , Distinguish between two account transfer
    if (routeId.value == 6) {
        deliverTo.value = 'JPN_JPY_THA_THB_EARTHPORT_MONEYGRAM_ACCOUNT_TRANSFER'
    }
    const formTypeValue = deliverTo.value;
    let form = {}

    Object.keys(formValues).map((name) => {
        form = {
            ...form,
            [name]: formValues[name].value
        }
        return form
    })
    // Do whatever with the values
    console.log(form)
    console.log(outboundQuoteId.value);

    // Show last component or success message


    const SwitchForms = ({ formType }: formNameType) => {
        console.log(formType);
        console.log(formTypeValue);

        switch (formTypeValue) {
            case 'JPN_JPY_IND_INR_MONEYGRAM_ACCOUNT_TRANSFER':
                return <MGBankDepositJPYINR />

            case 'JPN_JPY_BRA_BRL_MONEYGRAM_ACCOUNT_TRANSFER':
                return <MGBankDepositJPYBRL />

            case 'JPN_JPY_CHN_CNY_MONEYGRAM_ACCOUNT_TRANSFER':
                return <MGBankDepositJPYCNY />

            case 'JPN_JPY_IDN_IDR_MONEYGRAM_ACCOUNT_TRANSFER':
                return <MGBankDepositJPYIDR />

            case 'JPN_JPY_LKA_LKR_MONEYGRAM_ACCOUNT_TRANSFER':
                return <MGBankDepositJPYLKR />

            case 'JPN_JPY_AUS_AUD_MONEYGRAM_ACCOUNT_TRANSFER':
                return <MGBankDepositJPYAUD />

            case 'JPN_JPY_BGD_BDT_MONEYGRAM_ACCOUNT_TRANSFER':
                return <MGBankDepositJPYBDT />

            case 'JPN_JPY_FRA_EUR_MONEYGRAM_ACCOUNT_TRANSFER':
                return <MGBankDepositJPYEURFRA />

            case 'JPN_JPY_DEU_EUR_MONEYGRAM_ACCOUNT_TRANSFER':
                return <MGBankDepositJPYEURGER />

            case 'JPN_JPY_ITA_EUR_MONEYGRAM_ACCOUNT_TRANSFER':
                return <MGBankDepositJPYEURITA />

            case 'JPN_JPY_GBR_GBP_MONEYGRAM_ACCOUNT_TRANSFER':
                return <MGBankDepositJPYGBR />

            case 'JPN_JPY_GHA_GHS_MONEYGRAM_ACCOUNT_TRANSFER':
                return <MGBankDepositJPYGHS />

            case 'JPN_JPY_NZL_NZD_MONEYGRAM_ACCOUNT_TRANSFER':
                return <MGBankDepositJPYNZD />

            case 'JPN_JPY_PHL_PHP_MONEYGRAM_ACCOUNT_TRANSFER':
                return <MGBankDepositJPYPHP />

            case 'JPN_JPY_PAK_PKR_MONEYGRAM_ACCOUNT_TRANSFER':
                return <MGBankDepositJPYPKR />

            case 'JPN_JPY_SGP_SGD_MONEYGRAM_ACCOUNT_TRANSFER':
                return <MGBankDepositJPYSGD />

            case 'JPN_JPY_THA_THB_MONEYGRAM_ACCOUNT_TRANSFER':
                return <MGBankDepositJPYTHB />

            case 'JPN_JPY_THA_THB_EARTHPORT_MONEYGRAM_ACCOUNT_TRANSFER':
                return <MGBankDepositJPYTHBEarthPot />

            case 'JPN_JPY_VNM_VND_MONEYGRAM_ACCOUNT_TRANSFER':
                return <MGBankDepositJPYVND />

            case 'JPN_JPY_VNM_VND_MONEYGRAM_ACCOUNT_TRANSFER':
                return <MGBankDepositJPYVND />

            case 'JPN_JPY_PHL_PHP_MONEYGRAM_ACCOUNT_TRANSFER':
                return <MGBankDepositJPYPHP />

            case 'JPN_JPY_KOR_KRW_DE_FINANCIALS_ACCOUNT_TRANSFER':
                return <MGBankDepositJPYKRW />



            //Cash Pickup
            case 'JPN_JPY_USA_USD_MONEYGRAM_CASH_PICKUP':
                return <MGCashPickupGenGen />

            case 'JPN_JPY_IND_INR_MONEYGRAM_CASH_PICKUP':
                return <MGCashPickupGenGen />

            case 'JPN_JPY_PHL_PHP_MONEYGRAM_CASH_PICKUP':
                return <MGCashPickupGenGen />

            case 'JPN_JPY_THA_THB_MONEYGRAM_CASH_PICKUP':
                return <MGCashPickupGenGen />

            case 'JPN_JPY_IDN_IDR_MONEYGRAM_CASH_PICKUP':
                return <MGCashPickupGenGen />

            case 'JPN_JPY_VNM_VND_MONEYGRAM_CASH_PICKUP':
                return <MGCashPickupGenGen />

            case 'JPN_JPY_CHN_CNY_MONEYGRAM_CASH_PICKUP':
                return <MGCashPickupGenGen />

            case 'JPN_JPY_LKA_LKR_MONEYGRAM_CASH_PICKUP':
                return <MGCashPickupGenGen />

            case 'JPN_JPY_BRA_BRL_MONEYGRAM_CASH_PICKUP':
                return <MGCashPickupGenGen />

            case 'JPN_JPY_SGP_SGD_MONEYGRAM_CASH_PICKUP':
                return <MGCashPickupGenGen />

            case 'JPN_JPY_PAK_PKR_MONEYGRAM_CASH_PICKUP':
                return <MGCashPickupGenGen />

            case 'JPN_JPY_BGD_BDT_MONEYGRAM_CASH_PICKUP':
                return <MGCashPickupGenGen />

            case 'JPN_JPY_KOR_KRW_DE_FINANCIALS_CASH_PICKUP':
                return <MGCashPickupGenGen />

            case 'JPN_JPY_GBR_GBP_MONEYGRAM_CASH_PICKUP':
                return <MGCashPickupGenGen />

            case 'JPN_JPY_FRA_EUR_MONEYGRAM_CASH_PICKUP':
                return <MGCashPickupGenGen />

            case 'JPN_JPY_DEU_EUR_MONEYGRAM_CASH_PICKUP':
                return <MGCashPickupGenGen />

            case 'JPN_JPY_SWE_SEK_MONEYGRAM_CASH_PICKUP':
                return <MGCashPickupGenGen />

            case 'JPN_JPY_AUS_AUD_MONEYGRAM_CASH_PICKUP':
                return <MGCashPickupGenGen />

            case 'JPN_JPY_NZL_NZD_MONEYGRAM_CASH_PICKUP':
                return <MGCashPickupGenGen />

            case 'JPN_JPY_GHA_GHS_MONEYGRAM_CASH_PICKUP':
                return <MGCashPickupGenGen />

            default:
                throw new Error('Unknown step')
        }
    }

    const newReceipentFormData = ({ firstName, middlName, lastName, phoneNumber, email }: receipientDataType) => {
        console.log(firstName);

    }
    const ConfirmpageView = ({ confirmKey }: confirmKeyType) => {
        console.log('success');
        return <Confirm confirmKeyValue={confirmKey} />
    }

    const { translate } = useLocales();

    const selectCard = (receiverId: number) => {
        setSelected(receiverId);
        formValues.receiverId.value = receiverId
    }

    return (


        <>
            {confirmFormKey == 1 && <ConfirmpageView confirmKey={confirmFormKey} />}
            {formType == formTypeValue && <SwitchForms formType={formType} />}
            {formType !== formTypeValue && confirmFormKey == 0 &&
                <Card sx={{ p: 4, overflow: 'visible' }}>
                    <Grid container >
                        <Grid item xs={12}>
                            <Box
                                sx={{
                                    width: '100%',
                                    marginBottom: '40px',
                                }}
                            >
                                <h2 className='pb'>{translate(`dashboard.${WhoSendingText}`)}</h2>


                                {/* <h2 className='pb'>{formType}</h2>
                            <h2 className='pb'>{deliverTo.value}{sendCurrency.value}{receiveCurrency.value}</h2> */}

                                <div onClick={() =>
                                    setFormType(formTypeValue)
                                }>

                                    {formType !== formTypeValue && <Card sx={{ p: 3, cursor: 'pointer', display: 'flex', alignItems: 'center', marginTop: '30px' }} className="card-shadow">
                                        <Box sx={{ flexGrow: 1, }}>
                                            <Stack direction="row" alignItems="center" flexWrap="wrap">
                                                <IconWrapperStyle
                                                    sx={{

                                                        color: 'primary.main',
                                                        bgcolor: (theme) => alpha(theme.palette.primary.main, 0.16),
                                                        marginRight: '30px'
                                                    }}
                                                >
                                                    {/* <Icon width={16} height={16} icon={AddIcon} /> */}
                                                    <AddIcon />
                                                </IconWrapperStyle>

                                                <Typography variant="subtitle2" component="span">
                                                    {translate(`dashboard.${AddNewRecipientText}`)}
                                                </Typography>

                                            </Stack>
                                        </Box>
                                        <Typography variant="body2" component="span" sx={{ color: 'text.secondary', width: '10px' }}>
                                            <ArrowForwardIosIcon />
                                        </Typography>

                                    </Card>}
                                </div>
                            </Box>
                        </Grid>

                        {formType !== formTypeValue &&
                            <>
                                {/* <Grid item xs={12}>
                            <Box
                                sx={{
                                    width: '100%',
                                    marginBottom: '40px',
                                }}
                            >
                                <h4 className='pb'>Your Accounts</h4>
                                <div onClick={() => { alert("Hello from here"); }}>
                                    <Card sx={{ p: 3, cursor: 'pointer', display: 'flex', alignItems: 'center', marginBottom: '30px' }} className="card-shadow">
                                        <Box sx={{ flexGrow: 1 }}>
                                            <Stack direction="row" alignItems="center" flexWrap="wrap">
                                                <IconWrapperStyle
                                                    sx={{
                                                        color: 'primary.main',
                                                        bgcolor: (theme) => alpha(theme.palette.primary.main, 0.16),
                                                        marginRight: '30px'
                                                    }}
                                                >
                                                    <Avatar

                                                        alt="Remy Sharp"
                                                        src={`/static/mock-images/avatars/avatar_6.jpg`} />
                                                </IconWrapperStyle>
                                                <Stack>
                                                    <Typography variant="h6" component="span">
                                                        Gokuldas PG
                                                    </Typography>
                                                    <Typography variant="body2" component="span">
                                                        INR Account ending 7788
                                                    </Typography>
                                                </Stack>
                                            </Stack>
                                        </Box>
                                        <Typography variant="body2" component="span" sx={{ color: 'text.secondary', width: '10px' }}>
                                            <ArrowForwardIosIcon />
                                        </Typography>

                                    </Card>
                                </div>

                                <div onClick={() => { alert("Hello from here"); }}>
                                    <Card sx={{ p: 3, cursor: 'pointer', display: 'flex', alignItems: 'center', marginBottom: '30px' }} className="card-shadow">
                                        <Box sx={{ flexGrow: 1 }}>
                                            <Stack direction="row" alignItems="center" flexWrap="wrap">
                                                <IconWrapperStyle
                                                    sx={{
                                                        color: 'primary.main',
                                                        bgcolor: (theme) => alpha(theme.palette.primary.main, 0.16),
                                                        marginRight: '30px'
                                                    }}
                                                >
                                                    <Avatar

                                                        alt="Remy Sharp"
                                                        src={`/static/mock-images/avatars/avatar_6.jpg`} />
                                                </IconWrapperStyle>
                                                <Stack>
                                                    <Typography variant="h6" component="span">
                                                        Gokuldas PG
                                                    </Typography>
                                                    <Typography variant="body2" component="span">
                                                        INR Account ending 7788
                                                    </Typography>
                                                </Stack>
                                            </Stack>
                                        </Box>
                                        <Typography variant="body1" component="span" sx={{ color: 'text.secondary', width: '10px' }}>
                                            <ArrowForwardIosIcon />
                                        </Typography>
                                    </Card>
                                </div>
                            </Box>
                        </Grid> */}
                                <Grid item xs={12}>
                                    <Box
                                        sx={{
                                            width: '100%',
                                            marginBottom: '40px',
                                        }}
                                    >
                                        <h4 className='pb'> {translate(`dashboard.${yourRecipientsText}`)}</h4>
                                        {/* {newRecipientFormKey == 2 && <div onClick={() => { alert("Hello from here"); }}>
                                            <Card sx={{ p: 3, cursor: 'pointer', display: 'flex', alignItems: 'center', marginBottom: '30px' }} className="card-shadow">
                                                <Box sx={{ flexGrow: 1 }}>
                                                    <Stack direction="row" alignItems="center" flexWrap="wrap">
                                                        <IconWrapperStyle
                                                            sx={{
                                                                color: 'primary.main',
                                                                bgcolor: (theme) => alpha(theme.palette.primary.main, 0.16),
                                                                marginRight: '30px',
                                                                padding: '25px'
                                                            }}
                                                        >
                                                            <Avatar

                                                                alt="Remy Sharp"
                                                                src={`/static/mock-images/avatars/avatar_6.jpg`} />
                                                        </IconWrapperStyle>
                                                        <Stack>
                                                            <Typography variant="h6" component="span">
                                                                {formValues.receiverFirstName.value} {formValues.receiverLastName.value}
                                                            </Typography>
                                                            <Typography variant="body2" component="span">
                                                                INR Account ending {formValues.accountNumber.value}
                                                            </Typography>
                                                        </Stack>
                                                    </Stack>
                                                </Box>
                                                <Typography variant="body2" component="span" sx={{ color: 'text.secondary', width: '10px' }}>
                                                    <ArrowForwardIosIcon />
                                                </Typography>

                                            </Card>
                                        </div>} */}
                                        {routeSpecificReceiver ?
                                            <>
                                                {routeSpecificReceiver.slice(-4).map((data) => (
                                                    <div key={data.receiverId} onClick={() => { selectCard(data.receiverId) }}>
                                                        <Card key={data.receiverId} className={selected == data.receiverId ? "green-card" : "white-card"} sx={{ p: 3, cursor: 'pointer', display: 'flex', alignItems: 'center', marginBottom: '30px' }} >
                                                            <Box sx={{ flexGrow: 1 }}>
                                                                <Stack direction="row" alignItems="center" flexWrap="wrap">
                                                                    <IconWrapperStyle
                                                                        sx={{
                                                                            color: 'primary.main',
                                                                            bgcolor: (theme) => alpha(theme.palette.primary.main, 0.16),
                                                                            marginRight: '30px',
                                                                            padding: '20px'
                                                                        }}
                                                                    >
                                                                        <h3>{data.receiverFirstName.slice(0, 1).toUpperCase()}{data.receiverLastName.slice(0, 1).toUpperCase()}</h3>
                                                                        {/* <Icon width={16} height={16} icon={AddIcon} /> */}
                                                                        {/* <Avatar
                                                            alt="Remy Sharp"
                                                            src={`/static/mock-images/avatars/avatar_6.jpg`} /> */}
                                                                    </IconWrapperStyle>
                                                                    <Stack>
                                                                        <Typography variant="h6" component="span">
                                                                            {data.receiverFirstName}  {data.receiverMiddleName}  {data.receiverLastName}
                                                                        </Typography>
                                                                        {data.receiverBank ? data.receiverBank.map((bankData) => (

                                                                            <Typography key={bankData.receiverBankId} variant="body2" component="span">
                                                                                {bankData.receiverAccountNumber ? <p>Account number ending {bankData.receiverAccountNumber.substr(-5)}</p> : <p> {bankData.receiverVirtualPaymentAccount}</p>}
                                                                            </Typography>
                                                                        )) : null}
                                                                    </Stack>
                                                                </Stack>
                                                            </Box>
                                                            <Typography variant="body2" component="span" sx={{ color: 'text.secondary', width: '10px' }}>
                                                                <ArrowForwardIosIcon />
                                                            </Typography>

                                                        </Card>
                                                    </div>))}
                                                {routeSpecificReceiver.length == 1 &&
                                                    <><Card></Card><Card></Card><Card></Card><Card></Card></>
                                                }
                                                {routeSpecificReceiver.length == 2 &&
                                                    <><Card sx={{ height: "50" }}></Card><Card sx={{ height: "50" }}></Card><Card sx={{ height: "50" }} ></Card></>
                                                }
                                                {routeSpecificReceiver.length == 3 &&
                                                    <><Card></Card><Card></Card></>
                                                }
                                                {routeSpecificReceiver.length == 4 &&
                                                    <><Card></Card></>
                                                }
                                            </> :
                                            <div className="empty-image-container">
                                                <img
                                                    src="/static/illustrations/no-data.svg"
                                                    alt="empty"
                                                    width='300px'
                                                />
                                            </div>
                                        }

                                    </Box>
                                </Grid><LoadingButton
                                    fullWidth
                                    size="large"
                                    type="submit"
                                    variant="contained"
                                    onClick={() => setConfirmFormType(1)}
                                    color={selected ? "primary" : "inherit"}

                                // component={Link} to={PATH_DASHBOARD.general.confirm}
                                >
                                    {translate(`dashboard.${ContinueText}`)}
                                </LoadingButton></>}
                    </Grid>
                </Card>}
        </>
    );
}
