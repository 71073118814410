import { map, filter } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
// utils
import axios from '../../utils/axios';
import { AllCustomers } from '../../@types/allCustomersType';
import URLConstants from 'constants/urlConstants';

type searchCustomersState = {
    isLoading: boolean;
    error: boolean;
    searchCustomers: AllCustomers[];
}

const initialState: searchCustomersState = {
    isLoading: false,
    error: false,
    searchCustomers:[ 
        {
        "senderId": 0,
        "userId": 0,
        "senderFirstName": "",
        "senderMiddleName": '',
        "senderLastName": "",
        'senderEmail': "",
        'senderPhoneNumber': '',    
    }
]
}

const slice = createSlice({
    name: 'searchCustomers',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        // GET CUSTOMER
        searchCustomerSuccess(state, action) {
            state.isLoading = false;
            state.searchCustomers = action.payload;
        },
    }
});

// Reducer
export default slice.reducer;


// export function searchCustomerByName(senderId : any,page:number, senderName :string) {
//     var params = {
//         "senderId":senderId,
//         'senderName':senderName,
//         "senderEmail":'',
//         "senderPhoneNumber":''

//       }
//     return async () => {
//         dispatch(slice.actions.startLoading());
//         try {
//             const response = await axios.get(`https://6jfjr9vffh.execute-api.ap-south-1.amazonaws.com/staging/mcus/customers/${page}`,{params});
//             dispatch(slice.actions.searchCustomerSuccess(response.data.data));
//         } catch (error) {
//             dispatch(slice.actions.hasError(error));
//         }
//     };
// }
// export function searchCustomerById(senderId : any,page:number) {
//     var params = {
//         "senderId":senderId,
//         'senderName':'',
//         "senderEmail":'',
//         "senderPhoneNumber":''

//       }
//     return async () => {
//         dispatch(slice.actions.startLoading());
//         try {
//             const response = await axios.get(`https://6jfjr9vffh.execute-api.ap-south-1.amazonaws.com/staging/mcus/customers/${page}`,{params});
//             dispatch(slice.actions.searchCustomerSuccess(response.data.data));
//         } catch (error) {
//             dispatch(slice.actions.hasError(error));
//         }
//     };
// }

// export function searchCustomerByEmail(senderId : any,page:number, senderEmail :string) {
//     var params = {
//         "senderId":senderId,
//         'senderName':'',
//         "senderEmail":senderEmail,
//         "senderPhoneNumber":''

//       }
//     return async () => {
//         dispatch(slice.actions.startLoading());
//         try {
//             const response = await axios.get(`https://6jfjr9vffh.execute-api.ap-south-1.amazonaws.com/staging/mcus/customers/${page}`,{params});
//             dispatch(slice.actions.searchCustomerSuccess(response.data.data));
//         } catch (error) {
//             dispatch(slice.actions.hasError(error));
//         }
//     };
// }

// export function searchCustomerByPhoneNumber(senderId : any,page:number, senderPhoneNumber :string) {
//     var params = {
//         "senderId":senderId,
//         'senderName':'',
//         "senderEmail":'',
//         "senderPhoneNumber":senderPhoneNumber

//       }
//     return async () => {
//         dispatch(slice.actions.startLoading());
//         try {
//             const response = await axios.get(`https://6jfjr9vffh.execute-api.ap-south-1.amazonaws.com/staging/mcus/customers/${page}`,{params});
//             dispatch(slice.actions.searchCustomerSuccess(response.data.data));
//         } catch (error) {
//             dispatch(slice.actions.hasError(error));
//         }
//     };
// }