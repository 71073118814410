import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
// utils
import axios from '../../utils/axios';
import { EditIdentificationType } from '../../@types/editIdentificationType';


type EditIdentificationState = {
    isLoading: boolean;
    error: boolean;
    editIdentification: EditIdentificationType;
}

const initialState: EditIdentificationState = {
    isLoading: false,
    error: false,
    editIdentification: {
        "senderIdentificationId": 0,
        "senderId": 0,
        "senderIdType": "",
        "senderIdNo": "",
        "senderIdExpiry": "",
        "senderIdCountry": "",
        "createTime": new Date(),
    },
}

const slice = createSlice({
    name: 'editIdentification',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        editIdentificationSuccess(state, action) {
            state.isLoading = false;
            state.editIdentification = action.payload;
        },
    }
});

// Reducer
export default slice.reducer;

export function editIdentificationInfo(userId: number, values: any) {
    return async () => {
        console.log(values);
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.put(`https://6jfjr9vffh.execute-api.ap-south-1.amazonaws.com/staging/mcus/bo/customer/${userId}/identification`,
                {
                    "senderIdType": values.senderIdType,
                    "senderIdNo": values.senderIdNo,
                    "senderIdExpiry": values.senderIdExpiry,
                    "senderIdCountry": values.senderIdCountry,
                }
            );
            dispatch(slice.actions.editIdentificationSuccess(response.data.data));
        } catch (error :any) {
            dispatch(slice.actions.hasError(error));
            return {message : error?.message, hasError : true} 
        }
    };
}