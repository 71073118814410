import { map, filter } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
// utils
import axios from '../../utils/axios';
import { outboundOptionsType } from '../../@types/outboundOptionsType';
import URLConstants from 'constants/urlConstants';

type  OutboundOptionsState = {
    isLoading: boolean;
    error: boolean;
    outboundOptions: outboundOptionsType[];
}

const initialState: OutboundOptionsState = {
    isLoading: false,
    error: false,
    outboundOptions: [],
}

const slice = createSlice({
    name: 'outbound',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        // GET OUTBOUNT OPTIONS
        getOutboundOptionsSuccess(state, action) {
            state.isLoading = false;
            state.outboundOptions = action.payload;
        },
    }
});

// Reducer
export default slice.reducer;


export function getOutboundOptions() {
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
        const response = await axios.get(URLConstants.OUTBOUND_OPTIONS_BASE_URL);
        dispatch(slice.actions.getOutboundOptionsSuccess(response.data.data));
      } catch (error) {
        dispatch(slice.actions.hasError(error));
      }
    };
  }