import { map, filter } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
// utils
import axios from '../../utils/axios';
import { transationsType } from '../../@types/transaction';
import URLConstants from 'constants/urlConstants';

type RecentTransactionsState = {
    isLoading: boolean;
    error: boolean;
    recentTransactionOfCustomer: transationsType[];
}

const initialState: RecentTransactionsState = {
    isLoading: false,
    error: false,
    recentTransactionOfCustomer: [],
}

const slice = createSlice({
    name: 'recentTransactionOfCustomer',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        // GET TRANSACTIONS
        getRecentTransactionOfCustomerSuccess(state, action) {
            state.isLoading = false;
            state.recentTransactionOfCustomer = action.payload;
        },
    }
});

// Reducer
export default slice.reducer;


export function getRecentTransactionsOfCustomerBO(userId: number,noOfRecords:number,page:number) {
    var params = {
        'noOfRecords':noOfRecords,
      }
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(`https://6jfjr9vffh.execute-api.ap-south-1.amazonaws.com/staging/mcts/bo/transaction/recent/user/${userId}/${page}`,{params});
            dispatch(slice.actions.getRecentTransactionOfCustomerSuccess(response.data.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}