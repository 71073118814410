// material
import {

    Card,
    Table,
    TableRow,
    TableBody,
    TableCell,
    TableHead,
    TableContainer,
    Container,
} from '@material-ui/core';
// utils
import { RootState, useDispatch, useSelector } from 'redux/store';
import { useEffect } from 'react';
import { getCountry } from 'redux/slices/country';
import HeaderBreadcrumbs from 'components/HeaderBreadcrumbs';
import Page from 'components/Page';
import useLocales from 'hooks/useLocales';
// ----------------------------------------------------------------------

export default function Countrylist() {
    const { translate } = useLocales();
    const dispatch = useDispatch();
    const { countryList } = useSelector(
        (state: RootState) => state.countryData
    );


    useEffect(() => {
        dispatch(getCountry());
    }, [dispatch]);


    return (
        <>  <Page title="Country">
            <Container maxWidth="xl">

                <HeaderBreadcrumbs
                    heading={translate(`dashboard.${"Country"}`)}
                    links={[
                        // { name: 'Amount', href: PATH_DASHBOARD.user.root },
                        // { name: 'Delivery Options', href: PATH_DASHBOARD.user.root },
                    ]}
                />
                <Card sx={{ pb: 3 }}>
                    <TableContainer sx={{ minWidth: 720 }}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Country Id</TableCell>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Alpha 2</TableCell>
                                    <TableCell>Alpha 3</TableCell>
                                </TableRow>
                            </TableHead>
                            {countryList !== null &&
                                <TableBody>
                                    {countryList.map((row) => (
                                        <TableRow key={row.countryId}>
                                            <TableCell>{row.countryId}</TableCell>
                                            <TableCell>{row.name}</TableCell>
                                            <TableCell>{row.alpha2}</TableCell>
                                            <TableCell>{row.alpha3}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>}
                        </Table>

                    </TableContainer>
                </Card>
            </Container>
        </Page></>
    );
}