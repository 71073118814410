import { map, filter } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
// utils
import axios from '../../utils/axios';
import { CustomerById } from '../../@types/customerByIdType';
import URLConstants from 'constants/urlConstants';

type CustomerByIdState = {
    isLoading: boolean;
    error: boolean;
    customerById: CustomerById;
}

const initialState: CustomerByIdState = {
    isLoading: false,
    error: false,
    customerById: 
        {
            "senderId": 0,
            "userId": 0,
            "senderFirstName": "",
            "senderMiddleName": '',
            "senderLastName": "",
            'senderDOB': '',
            'senderGender': '',
            'senderNationality': '',
            'createTime': new Date(),
            'updateTime': new Date(),
            'senderAddress': [],
            'senderIdentificationDoc': [],
            'senderContact': [],
            'senderBank': [],
            'senderAdditional': [],
        }
    
}

const slice = createSlice({
    name: 'allCustomers',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        // GET CUSTOMER
        getCustomerByIdSuccess(state, action) {
            state.isLoading = false;
            state.customerById = action.payload;
        },
    }
});

// Reducer
export default slice.reducer;


export function getCustomerById(customerId:number) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(`https://6jfjr9vffh.execute-api.ap-south-1.amazonaws.com/staging/mcus/customer/${customerId}`);
            dispatch(slice.actions.getCustomerByIdSuccess(response.data.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}