import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
// utils
import axios from '../../utils/axios';
import { AddMoneyToWalletType } from '../../@types/addmoneyToWalletType';
import URLConstants from 'constants/urlConstants';

type AddMoneyToWalletState = {
    isLoading: boolean;
    error: boolean;
    addMoney: AddMoneyToWalletType;
}

const initialState: AddMoneyToWalletState = {
    isLoading: false,
    error: false,
    addMoney: {
        "inboundTransactionAuditId": "",
        "inboundTransactionId": "",
        "katakanaName": "",
        "bankCode": "",
        "accountNumber": "",
        "amount": 0,
        "remark": "",
        "inboundTransactionStatus": "",
        "createTime": new Date(),
        "updateTime": new Date()
    },
}

const slice = createSlice({
    name: 'addMoney',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        // GET ADD MONEY
        addMoneyToWalletSuccess(state, action) {
            state.isLoading = false;
            state.addMoney = action.payload;
        },
    }
});

// Reducer
export default slice.reducer;


export function addMoneyToWallet(katakanaName: string, bankCode: string, accountNumber: string, amount: number, remark: string) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.post(`https://6jfjr9vffh.execute-api.ap-south-1.amazonaws.com/staging/mtws/bo/wallet/transaction/credit`,
                { katakanaName, bankCode, accountNumber, amount, remark });
            dispatch(slice.actions.addMoneyToWalletSuccess(response.data.data));
        } catch (error :any) {
            dispatch(slice.actions.hasError(error));
            return {message : error?.message, hasError : true} 
        }
    };
}