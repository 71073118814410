// material
import {

    Card,
    Table,
    TableRow,
    TableBody,
    TableCell,
    TableHead,
    TableContainer,
    Container,
    Pagination,
    Grid,
    Box,
} from '@material-ui/core';
// utils
import { RootState, useDispatch, useSelector } from 'redux/store';
import { useEffect } from 'react';
import { getRouteBankBO } from 'redux/slices/routeBankBO';
import HeaderBreadcrumbs from 'components/HeaderBreadcrumbs';
import Page from 'components/Page';
import useLocales from 'hooks/useLocales';
// ----------------------------------------------------------------------


const style = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
    '& > *': { my: 1 }
  } as const;

export default function Banks() {
    const { translate } = useLocales();
    const dispatch = useDispatch();
    const { routeBankBO } = useSelector(
        (state: RootState) => state.routeBankBO
    );


    useEffect(() => {
        let page = 1;
        dispatch(getRouteBankBO(page));
    }, [dispatch]);

    const paginationFunction = (e: any, page: number) => {
          dispatch(getRouteBankBO(page));
      }

    return (
        <>  <Page title="Bank">
            <Container maxWidth="xl">

                <HeaderBreadcrumbs
                    heading={translate(`dashboard.${"Bank"}`)}
                    links={[
                        // { name: 'Amount', href: PATH_DASHBOARD.user.root },
                        // { name: 'Delivery Options', href: PATH_DASHBOARD.user.root },
                    ]}
                />
                <Card sx={{ pb: 3 }}>
                    <TableContainer sx={{ minWidth: 720 }}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    {/* <TableCell>Route Id</TableCell> */}
                                    <TableCell>Route Bank Id</TableCell>
                                    <TableCell>Bank name code</TableCell>
                                    <TableCell>Bank name</TableCell>
                                </TableRow>
                            </TableHead>
                            {routeBankBO !== null &&
                                <TableBody>
                                    {routeBankBO.map((row) => (
                                        <TableRow key={row.routeBankId}>
                                            {/* <TableCell>{row.routeId}</TableCell> */}
                                            <TableCell>{row.routeBankId}</TableCell>
                                            <TableCell>{row.bankNameCode}</TableCell>
                                            <TableCell>{row.bankName}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>}
                        </Table>

                    </TableContainer>
                    <Grid item xs={12}>
                        <Box sx={style}>

                            <Pagination onChange={(e, page) => paginationFunction(e, page)} count={10} color="primary" />

                        </Box>
                    </Grid>
                </Card>
            </Container>
        </Page></>
    );
}