import { map, filter } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
// utils
import axios from '../../utils/axios';
import { routeBankType } from '../../@types/routeBankType';
import URLConstants from 'constants/urlConstants';

type RouteBankState = {
    isLoading: boolean;
    error: boolean;
    routeBank: routeBankType[];
}

const initialState: RouteBankState = {
    isLoading: false,
    error: false,
    routeBank: [],
}

const slice = createSlice({
    name: 'routeBank',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        // GET ROUTE BANK
        getRouteBankSuccess(state, action) {
            state.isLoading = false;
            state.routeBank = action.payload;
        },
    }
});

// Reducer
export default slice.reducer;


export function getRouteBank(routeId: string) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(`https://6jfjr9vffh.execute-api.ap-south-1.amazonaws.com/staging/mtcs/routes/${routeId}/banks`);
            dispatch(slice.actions.getRouteBankSuccess(response.data.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}