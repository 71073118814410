import faker from 'faker';
import { orderBy } from 'lodash';
import { Icon } from '@iconify/react';
import heartFill from '@iconify/icons-eva/heart-fill';

// material
import { alpha, experimentalStyled as styled } from '@material-ui/core/styles';
import { Box, Stack, Card, Avatar, CardHeader, Typography } from '@material-ui/core';
import { LoadingButton } from "@material-ui/lab";
// utils
import { fShortenNumber } from 'utils/formatNumber';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import useLocales from 'hooks/useLocales';




// ----------------------------------------------------------------------

const AUTHORS = [
  {
    name: faker.name.findName(),
    email: faker.internet.email(),
    favourite: faker.datatype.number(),
    avatar: '/static/mock-images/avatars/avatar_2.jpg'
  },
  {
    name: faker.name.findName(),
    email: faker.internet.email(),
    favourite: faker.datatype.number(),
    avatar: '/static/mock-images/avatars/avatar_3.jpg'
  },
  {
    name: faker.name.findName(),
    email: faker.internet.email(),
    favourite: faker.datatype.number(),
    avatar: '/static/mock-images/avatars/avatar_4.jpg'
  },
  {
    name: faker.name.findName(),
    email: faker.internet.email(),
    favourite: faker.datatype.number(),
    avatar: '/static/mock-images/avatars/avatar_5.jpg'
  },
  {
    name: faker.name.findName(),
    email: faker.internet.email(),
    favourite: faker.datatype.number(),
    avatar: '/static/mock-images/avatars/avatar_6.jpg'
  }
];

const IconWrapperStyle = styled('div')(({ theme }) => ({
  width: 40,
  height: 40,
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  justifyContent: 'center',
  color: theme.palette.primary.main,
  backgroundColor: alpha(theme.palette.primary.main, 0.08)
}));

// ----------------------------------------------------------------------

type AuthorItemProps = {
  author: {
    name: string;
    email: string,
    avatar: string;
    favourite: number;
  };
  index: number;
};

function AuthorItem({ author, index }: AuthorItemProps) {
  return (
    <Stack direction="row" alignItems="center" spacing={2}>
      <Avatar alt={author.name} src={author.avatar} />
      <Box sx={{ flexGrow: 1 }}>
        <Typography variant="subtitle2">{author.name}</Typography>
        <Typography
          variant="caption"
          sx={{
            mt: 0.5,
            display: 'flex',
            alignItems: 'center',
            color: 'text.secondary'
          }}
        >
          {author.email}
        </Typography>
      </Box>
      

         <img src="/static/icons/controls/right.svg" alt=""  width='15' height='15'/>
      
    </Stack>
  );
}

export default function AppTopAuthors() {
  const displayAuthor = orderBy(AUTHORS, ['favourite'], ['desc']);
  const { translate } = useLocales();
  return (
    <Card>
      <CardHeader title=  {translate(`dashboard.${"Recent customers"}`)} sx={{paddingBottom:'18px'}}/>
      <Stack spacing={3} sx={{ p: 3 }}>
        {displayAuthor.map((author, index) => (
          <AuthorItem key={author.name} author={author} index={index} />
        ))}
       
        <LoadingButton sx={{marginTop:'10px'}}
          fullWidth
          color="inherit"
          size="large"
          type="submit"
          variant="outlined"
        >
          {translate(`dashboard.${"View All"}`)}
        </LoadingButton>
      </Stack>


    </Card>
  );
}
