import {
	TextField,
	IconButton,
	InputAdornment,
	FormControlLabel,
	Checkbox,
	Stack,
	Link,
	Alert,
} from "@material-ui/core";
import * as Yup from "yup";
import { Link as RouterLink } from "react-router-dom";
import { Icon } from "@iconify/react";
import "./LoginStyles.scss";
import { useState } from "react";
import eyeFill from "@iconify/icons-eva/eye-fill";
import eyeOffFill from "@iconify/icons-eva/eye-off-fill";
import { useFormik, Form, FormikProvider } from "formik";
import useAuth from "../../hooks/useAuth";
import useIsMountedRef from "../../hooks/useIsMountedRef";
import { LoadingButton } from "@material-ui/lab";
import useLocales from "hooks/useLocales";

type InitialValues = {
	email: string;
	password: string;
	remember: boolean;
	afterSubmit?: string;
};

const emailAddressText = "Email address"
const password  = "Password"
const forgotPasswordText  = "Forgot password"
const rememberMeText  = "Remember me"
const loginText  = "Login"

export default () => {
	const { login } = useAuth();
	const { translate } = useLocales();
	const isMountedRef = useIsMountedRef();
	const [showPassword, setShowPassword] = useState(false);
	const [checkBoxChecked, setCheckBoxChecked] = useState(false);

	const handleShowPassword = () => {
		setShowPassword((show) => !show);
	};

	const LoginSchema = Yup.object().shape({
		email: Yup.string().required(translate(`dashboard.${emailAddressText}`)),
		password: Yup.string().required(translate(`dashboard.${password}`)),
	});

	const formik = useFormik<InitialValues>({
		initialValues: {
			email: "",
			password: "",
			remember: true,
		},
		validationSchema: LoginSchema,
		onSubmit: async (values, { setErrors, setSubmitting, resetForm }) => {
			try {
				await login(values.email, values.password);

				if (isMountedRef.current) {
					setSubmitting(false);
				}
			} catch (error) {
				console.error(error);
				resetForm();
				if (isMountedRef.current) {
					setSubmitting(false);
					setErrors({ afterSubmit: error.message });
				}
			}
		},
	});

	const {
		errors,
		touched,
		values,
		isSubmitting,
		handleSubmit,
		getFieldProps,
	} = formik;
	return (
		<FormikProvider value={formik}>
			<div className="login-container">
				<div className="login-section">
					<div className="login-form">
						<Form autoComplete="off" noValidate onSubmit={handleSubmit}>
							{errors.afterSubmit && (
								<Alert severity="error">{errors.afterSubmit}</Alert>
							)}
							<fieldset className="field-wrapper">
								<TextField
									type="email"
									label={translate(`dashboard.${emailAddressText}`)}
									fullWidth
									{...getFieldProps("email")}
									error={Boolean(touched.email && errors.email)}
									helperText={touched.email && errors.email}
								/>
							</fieldset>
							<fieldset className="field-wrapper">
								<TextField
									label={translate(`dashboard.${password}`)}
									autoComplete="current-password"
									type={showPassword ? "text" : "password"}
									fullWidth
									{...getFieldProps("password")}
									InputProps={{
										endAdornment: (
											<InputAdornment position="end">
												<IconButton
													onClick={handleShowPassword}
													edge="end"
												>
													<Icon
														icon={
															showPassword ? eyeFill : eyeOffFill
														}
													/>
												</IconButton>
											</InputAdornment>
										),
									}}
									error={Boolean(touched.password && errors.password)}
									helperText={touched.password && errors.password}
								/>
							</fieldset>

							<Stack
								direction="row"
								alignItems="center"
								justifyContent="space-between"
								sx={{ my: 2 }}
							>
								<FormControlLabel
									control={<Checkbox checked={checkBoxChecked} />}
									label={translate(`dashboard.${rememberMeText}`)}
									onChange={() => {
										setCheckBoxChecked(!checkBoxChecked);
									}}
								/>
								<Link
									component={RouterLink}
									variant="subtitle2"
									to="/reset-password"
								>
								{translate(`dashboard.${forgotPasswordText}`)}
								</Link>
							</Stack>
							<LoadingButton
								fullWidth
								size="large"
								type="submit"
								variant="contained"
								loading={isSubmitting}
							>
								{translate(`dashboard.${loginText}`)}
							</LoadingButton>
						</Form>
					</div>
				</div>
			</div>
		</FormikProvider>
	);
};
