
import { capitalCase } from 'change-case';
import { useState, useEffect } from 'react';
import bellFill from '@iconify/icons-eva/bell-fill';
import shareFill from '@iconify/icons-eva/share-fill';
// import roundVpnKey from '@iconify/icons-ic/round-vpn-key';
// import roundReceipt from '@iconify/icons-ic/round-receipt';
// import roundAccountBox from '@iconify/react/icons-ic/round-account-box';
// material
import { Container, Tab, Box, Tabs } from '@material-ui/core';
// redux
import { RootState, useDispatch, useSelector } from '../../redux/store';
import {
  getCards,
  getProfile,
  getInvoices,
  getAddressBook,
  getNotifications
} from '../../redux/slices/user';
// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// components
import Page from '../../components/Page';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import AccountGeneral from './AccountGeneral';
import AccountNotifications from './AccountNotifications';
import AccountChangePassword from './AccountChangePassword';
import Icon from '@iconify/react';
// import {
//   AccountGeneral,
//   AccountBilling,
//   AccountSocialLinks,
//   AccountNotifications,
//   AccountChangePassword
// } from '../../components/_dashboard/user/account';

// ----------------------------------------------------------------------

export default function UserAccount() {
  const [currentTab, setCurrentTab] = useState('general');
  const dispatch = useDispatch();
  const { cards, invoices, myProfile, addressBook, notifications } = useSelector(
    (state: RootState) => state.user
  );

  useEffect(() => {
   // dispatch(getCards());
   // dispatch(getAddressBook());
   // dispatch(getInvoices());
    dispatch(getNotifications());
   // dispatch(getProfile());
  }, [dispatch]);

//   if (!myProfile) {
//     return null;
//   }

//   if (!cards) {
//     return null;
//   }

//   if (!notifications) {
//     return null;
//   }

  const ACCOUNT_TABS = [
    {
      value: 'general',
      //icon: <Icon icon={roundAccountBox} width={20} height={20} />,
      component: <AccountGeneral />
    },
    // {
    //   value: 'billing',
    //   icon: <Icon icon={roundReceipt} width={20} height={20} />,
    //   component: <AccountBilling cards={cards} addressBook={addressBook} invoices={invoices} />
    // },
    //  {
    //   value: 'notifications',
    //   icon: <Icon icon={bellFill} width={20} height={20} />,
    //   component: <AccountNotifications notifications={notifications} />
    // },
    // {
    //   value: 'social_links',
    //   icon: <Icon icon={shareFill} width={20} height={20} />,
    //   component: <AccountSocialLinks myProfile={myProfile} />
    // },
    {
      value: 'change_password',
     // icon: <Icon icon={roundVpnKey} width={20} height={20} />,
      component: <AccountChangePassword />
    }
  ];

  return (
    <Page title="User: Account Settings | Minimal-UI">
      <Container>
        <HeaderBreadcrumbs
          heading="Account"
          links={[
            // { name: 'Dashboard', href: PATH_DASHBOARD.root },
            // { name: 'User', href: PATH_DASHBOARD.user.root },
            // { name: 'Account Settings' }
          ]}
        />

        <Tabs
          value={currentTab}
          scrollButtons="auto"
          variant="scrollable"
          allowScrollButtonsMobile
          onChange={(e, value) => setCurrentTab(value)}
        >
          {ACCOUNT_TABS.map((tab) => (
            <Tab
              disableRipple
              key={tab.value}
              label={capitalCase(tab.value)}
             // icon={tab.icon}
              value={tab.value}
            />
          ))}
        </Tabs>

        <Box sx={{ mb: 5 }} />

        {ACCOUNT_TABS.map((tab) => {
          const isMatched = tab.value === currentTab;
          return isMatched && <Box key={tab.value}>{tab.component}</Box>;
        })}
      </Container>
    </Page>
  );
}
