// material
import {
    Card,
    Table,
    TableRow,
    TableBody,
    TableCell,
    TableHead,
    TableContainer,
    Container,
    Grid,
} from '@material-ui/core';
// utils
import { RootState, useDispatch, useSelector } from 'redux/store';
import { useEffect } from 'react';
import Page from 'components/Page';
import HeaderBreadcrumbs from 'components/HeaderBreadcrumbs';
import { getJPYRates } from 'redux/slices/ratesJPYBO';
import { getEURRates } from 'redux/slices/rateEURBO';
import { getUSDRates } from 'redux/slices/rateUSDBO';
import useLocales from 'hooks/useLocales';

// ----------------------------------------------------------------------

export default function Rates() {
    const { translate } = useLocales();
    const dispatch = useDispatch();
    const { rateJPY } = useSelector(
        (state: RootState) => state.rateJPYBO
    );
    const { rateUSD } = useSelector(
        (state: RootState) => state.rateUSDBO
    );

    const { rateEUR } = useSelector(
        (state: RootState) => state.rateEURBO
    );

    useEffect(() => {
        dispatch(getJPYRates());
        dispatch(getUSDRates());
        dispatch(getEURRates());
    }, [dispatch]);

    return (
        <>  <Page title="Rates">
            <Container maxWidth="xl">

                <HeaderBreadcrumbs
                    heading={translate(`dashboard.${"Rates"}`)}
                    links={[
                        // { name: 'Amount', href: PATH_DASHBOARD.user.root },
                        // { name: 'Delivery Options', href: PATH_DASHBOARD.user.root },
                    ]}
                />
                <Grid container >
                    <Grid item xs={12} md={4} >
                        <Card sx={{ pb: 3, marginLeft: 1, marginRight: 1 }} >
                            <h3 className='rate-heading'>JPY/X</h3>
                            <TableContainer>
                                <Table >
                                    <TableHead>
                                        <TableRow>
                                            <TableCell sx={{ textAlign: 'center' }}>{translate(`dashboard.${"Currency"}`)}</TableCell>
                                            <TableCell sx={{ textAlign: 'center' }}>{translate(`dashboard.${"Rate"}`)}</TableCell>
                                        </TableRow>
                                    </TableHead>

                                    <TableBody>
                                        {Object.entries(rateJPY.rates).map(function (value, key) {
                                            return <TableRow key={key}>
                                                <TableCell sx={{ textAlign: 'center' }}>{value[0]}</TableCell>
                                                <TableCell sx={{ textAlign: 'center' }}>{value[1]}</TableCell>
                                            </TableRow>
                                        })}

                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Card>
                    </Grid>

                    <Grid item xs={12} md={4} >

                        <Card sx={{ pb: 3, marginLeft: 1, marginRight: 1 }}>
                            <h3 className='rate-heading'>USD/X</h3>
                            <TableContainer>
                                <Table >
                                    <TableHead>
                                        <TableRow>
                                        <TableCell sx={{ textAlign: 'center' }}>{translate(`dashboard.${"Currency"}`)}</TableCell>
                                            <TableCell sx={{ textAlign: 'center' }}>{translate(`dashboard.${"Rate"}`)}</TableCell>
                                        </TableRow>
                                    </TableHead>

                                    <TableBody>
                                        {Object.entries(rateUSD.rates).map(function (value, key) {
                                            return <TableRow key={key}>
                                                <TableCell sx={{ textAlign: 'center' }}>{value[0]}</TableCell>
                                                <TableCell sx={{ textAlign: 'center' }}>{value[1]}</TableCell>
                                            </TableRow>
                                        })}

                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Card>
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <Card sx={{ pb: 3, marginLeft: 1, marginRight: 1 }}>
                            <h3 className='rate-heading'>EUR/X</h3>
                            <TableContainer>
                                <Table >
                                    <TableHead>
                                        <TableRow>
                                        <TableCell sx={{ textAlign: 'center' }}>{translate(`dashboard.${"Currency"}`)}</TableCell>
                                            <TableCell sx={{ textAlign: 'center' }}>{translate(`dashboard.${"Rate"}`)}</TableCell>
                                        </TableRow>
                                    </TableHead>

                                    <TableBody>
                                        {Object.entries(rateEUR.rates).map(function (value, key) {
                                            return <TableRow key={key}>
                                                <TableCell sx={{ textAlign: 'center' }}>{value[0]}</TableCell>
                                                <TableCell sx={{ textAlign: 'center' }}>{value[1]}</TableCell>
                                            </TableRow>
                                        })}

                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Card>
                    </Grid>
                </Grid>
            </Container>
        </Page></>
    );
}