import { map, filter } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
// utils
import axios from '../../utils/axios';
import { SearchTransationsType } from '../../@types/searchTransactionsType';
import URLConstants from 'constants/urlConstants';
import user from './user';

type AllCustomersState = {
    isLoading: boolean;
    error: boolean;
    allTransactions: SearchTransationsType[];
}

const initialState: AllCustomersState = {
    isLoading: false,
    error: false,
    allTransactions: [
        {
            "remittanceOrderId": "",
            "paymentId": null,
            "senderName": "",
            "senderEmail": "",
            "senderPhone": "",
            "receiverName": "",
            "receiverEmail": "",
            "receiverPhone": "",
            "totalOutboundSendAmount": 0,
            "netRecvAmount": 0,
            "remittanceOrderStatus": "",
            "createTime": new Date()
        }
    ]
}

const slice = createSlice({
    name: 'allTransactions',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        // GET ALL TRANSACTION
        getAllTransactionsSuccess(state, action) {
            state.isLoading = false;
            state.allTransactions = action.payload;
        },
    }
});

// Reducer
export default slice.reducer;


export function getAllTransactions(page: number, noOfRecords: number) {
    var params = {
        "senderId": null,
        "senderEmail": "",
        "senderPhoneNumber": "",
        "receiverEmail": "",
        "receiverPhoneNumber": "",
        "orderId": "",
        "startDate": "",
        "endDate": "",
        "noOfRecords": noOfRecords
    }
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(`
            https://6jfjr9vffh.execute-api.ap-south-1.amazonaws.com/staging/mcts/bo/transaction/search/${page}`, { params });
            dispatch(slice.actions.getAllTransactionsSuccess(response.data.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}
export function searchTransactions(senderId: any, page: number, senderEmail: string, senderPhoneNumber: string, receiverEmail: string, receiverPhoneNumber: string, orderId: string, noOfRecords: number) {
    if (senderId==''){
        senderId=null
    }
    var params = {
        "senderId": senderId,
        "senderEmail": senderEmail,
        "senderPhoneNumber": senderPhoneNumber,
        "receiverEmail": receiverEmail,
        "receiverPhoneNumber": receiverPhoneNumber,
        "orderId": orderId,
        "startDate": "",
        "endDate": "",
        "noOfRecords": noOfRecords
    }
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(`https://6jfjr9vffh.execute-api.ap-south-1.amazonaws.com/staging/mcts/bo/transaction/search/${page}`, { params });
            dispatch(slice.actions.getAllTransactionsSuccess(response.data.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// export function searchCustomerById(senderId : any,page:number) {
//     var params = {
//         "senderId":senderId,
//         'senderName':'',
//         "senderEmail":'',
//         "senderPhoneNumber":''

//       }
//     return async () => {
//         dispatch(slice.actions.startLoading());
//         try {
//             const response = await axios.get(`https://6jfjr9vffh.execute-api.ap-south-1.amazonaws.com/staging/mcus/customers/${page}`,{params});
//             dispatch(slice.actions.getAllCustomerSuccess(response.data.data));
//         } catch (error) {
//             dispatch(slice.actions.hasError(error));
//         }
//     };
// }

// export function searchCustomerByEmail(senderId : any,page:number, senderEmail :string) {
//     var params = {
//         "senderId":senderId,
//         'senderName':'',
//         "senderEmail":senderEmail,
//         "senderPhoneNumber":''

//       }
//     return async () => {
//         dispatch(slice.actions.startLoading());
//         try {
//             const response = await axios.get(`https://6jfjr9vffh.execute-api.ap-south-1.amazonaws.com/staging/mcus/customers/${page}`,{params});
//             dispatch(slice.actions.getAllCustomerSuccess(response.data.data));
//         } catch (error) {
//             dispatch(slice.actions.hasError(error));
//         }
//     };
// }

// export function searchCustomerByPhoneNumber(senderId : any,page:number, senderPhoneNumber :string) {
//     var params = {
//         "senderId":senderId,
//         'senderName':'',
//         "senderEmail":'',
//         "senderPhoneNumber":senderPhoneNumber

//       }
//     return async () => {
//         dispatch(slice.actions.startLoading());
//         try {
//             const response = await axios.get(`https://6jfjr9vffh.execute-api.ap-south-1.amazonaws.com/staging/mcus/customers/${page}`,{params});
//             dispatch(slice.actions.getAllCustomerSuccess(response.data.data));
//         } catch (error) {
//             dispatch(slice.actions.hasError(error));
//         }
//     };
// }