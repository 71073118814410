// material
import {

    Card,
    Table,
    TableRow,
    TableBody,
    TableCell,
    TableHead,
    TableContainer,
    Container,
} from '@material-ui/core';
// utils
import { RootState, useDispatch, useSelector } from 'redux/store';
import { useEffect } from 'react';
import { getRouteBankBO } from 'redux/slices/routeBankBO';
import { getBankTypesBO } from 'redux/slices/bankTypesBO';
import HeaderBreadcrumbs from 'components/HeaderBreadcrumbs';
import Page from 'components/Page';
import useLocales from 'hooks/useLocales';
// ----------------------------------------------------------------------

export default function BankTypes() {
    const { translate } = useLocales();
    const dispatch = useDispatch();
    const { bankTypesBO } = useSelector(
        (state: RootState) => state.bankTypesBO
    );


    useEffect(() => {
        dispatch(getBankTypesBO());
    }, [dispatch]);


    return (
        <>  <Page title="Bank account type">
            <Container maxWidth="xl">

                <HeaderBreadcrumbs
                    heading={translate(`dashboard.${"Bank account type"}`)}
                    links={[
                        // { name: 'Amount', href: PATH_DASHBOARD.user.root },
                        // { name: 'Delivery Options', href: PATH_DASHBOARD.user.root },
                    ]}
                />
                <Card sx={{ pb: 3 }}>
                    <TableContainer sx={{ minWidth: 720 }}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Route Id</TableCell>
                                    <TableCell>Route bank account type Id</TableCell>
                                    <TableCell>Account type code</TableCell>
                                    <TableCell>Account type</TableCell>
                                </TableRow>
                            </TableHead>
                            {bankTypesBO !== null &&
                                <TableBody>
                                    {bankTypesBO.map((row) => (
                                        <TableRow key={row.routeBankAccountTypeId}>
                                            <TableCell>{row.routeId}</TableCell>
                                            <TableCell>{row.routeBankAccountTypeId}</TableCell>
                                            <TableCell>{row.accountTypeCode}</TableCell>
                                            <TableCell>{row.accountType}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>}
                        </Table>

                    </TableContainer>
                </Card>
            </Container>
        </Page></>
    );
}