// material
import {
    Card,
    Table,
    TableRow,
    TableBody,
    TableCell,
    TableHead,
    TableContainer,
    Container,
} from '@material-ui/core';
// utils
import { RootState, useDispatch, useSelector } from 'redux/store';
import { useEffect } from 'react';
import { getPartnersList } from 'redux/slices/partners';
import Page from 'components/Page';
import HeaderBreadcrumbs from 'components/HeaderBreadcrumbs';
import useLocales from 'hooks/useLocales';
// ----------------------------------------------------------------------

export default function Reconciliation() {
    const { translate } = useLocales();
    // const dispatch = useDispatch();
    // const { partners } = useSelector(
    //     (state: RootState) => state.partners
    // );


    // useEffect(() => {
    //     dispatch(getPartnersList());
    // }, [dispatch]);


    return (
        <>  <Page title="Reconciliation">
            <Container maxWidth="xl">

                <HeaderBreadcrumbs
                    heading={translate(`dashboard.${"Reconciliation"}`)}
                    links={[
                        // { name: 'Amount', href: PATH_DASHBOARD.user.root },
                        // { name: 'Delivery Options', href: PATH_DASHBOARD.user.root },
                    ]}
                />

                <div className="in-progress-image">
                    <img
                        src="/static/illustrations/in-progress.svg"
                        alt="empty"
                        width='400px'
                    />
                </div>

                {/* <Card sx={{ pb: 3 }}>
                    <TableContainer sx={{ minWidth: 720 }}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Partners Id</TableCell>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Code</TableCell>
                                </TableRow>
                            </TableHead>
                            {partners !== null &&
                                <TableBody>
                                    {partners.map((row) => (
                                        <TableRow key={row.partnerId}>
                                            <TableCell>{row.partnerId}</TableCell>
                                            <TableCell>{row.partnerName}</TableCell>
                                            <TableCell>{row.partnerCode}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>}
                        </Table>

                    </TableContainer>
                </Card> */}
            </Container>
        </Page></>
    );
}