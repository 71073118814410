// material
import { Container, Grid } from '@material-ui/core';
// hooks
import useAuth from '../../hooks/useAuth';
// components
import Page from '../../components/Page';
import AppRecentTransactions from './AppRecentTransactions';
import SenderInfoModal from './SenderInfoModal/SenderInfoModal';
import { useContext, useEffect } from 'react';
import axiosInstance from "utils/axios";
import URLConstants from 'constants/urlConstants';
import React from 'react';
import { AppContext } from 'contexts/SenderInfoContext';
import TodaysTransactions from './TodaysTransactions';
import WeeksTransactions from './WeeksTransactions';
import MonthsTransactions from './MonthsTransactions';
import FinanicalTransactions from './FinanicalTransactions';
import EcommerceYearlySales from './EcommerceYearlySales';
import AppTopAuthors from './AppTopAuthors';
import { RootState, useDispatch, useSelector } from 'redux/store';
import { getTransactionCount } from 'redux/slices/transactionCountBO';



// ----------------------------------------------------------------------
//const isSenderInfo = true;

export default function Home() {
  const dispatch = useDispatch();
  const { transationsCount } = useSelector(
      (state: RootState) => state.transactionCount
  );
 
  useEffect(() => {
    dispatch(getTransactionCount());
	}, [dispatch]);
  return (

    <Page title="Dashboard: App | MTS">
       <Container maxWidth="xl">
       <Grid container spacing={3}>


         <Grid item xs={12} md={3}>
           <TodaysTransactions />
         </Grid>

         <Grid item xs={12} md={3}>
           <WeeksTransactions />
         </Grid>

         <Grid item xs={12} md={3}>
           <MonthsTransactions />
         </Grid>

         <Grid item xs={12} md={3}>
           <FinanicalTransactions />
         </Grid>

         <Grid item xs={12} md={8}>
           <EcommerceYearlySales />
         </Grid>

         <Grid item xs={12} md={4}>
           <AppTopAuthors />
         </Grid>

         <Grid item xs={12} lg={12}>
           <AppRecentTransactions />
         </Grid>
       </Grid>
     </Container> 
    </Page>
  );
}

