import Home from "pages/Home/Home";
import SendMoney from "pages/Send-Money/SendMoney";
import Receiver from "pages/Send-Money/Receiver";
import { lazy, Suspense } from "react";
import { Navigate, useRoutes, useLocation } from "react-router-dom";
import LoadingScreen from "../components/LoadingScreen";
import AuthGuard from "../guards/AuthGuard";
import GuestGuard from "../guards/GuestGuard";
import LogoOnlyLayout from "../layouts/LogoOnlyLayout";
import Confirm from "pages/Send-Money/Confirm";
import Recipients from "pages/Recipients/Recipients";
import MGCashPickupGenGen from "pages/Send-Money/RegisterReceiverForms/MGCashPickupGenGen";
import MGBankDepositJPYINR from "pages/Send-Money/RegisterReceiverForms/MGBankDepositJPYINR";
import MGBankDepositJPYBRA from "pages/Send-Money/RegisterReceiverForms/MGBankDepositJPYBRL";
import RegisterReceiver from "pages/Send-Money/RegisterReceiverForms/RegisterReceiver";
import UserAccount from "pages/UserAcccount/UserAccount";
import TermsAndCondition from "pages/Help/TermsAndCondition";
import Privacy from "pages/Help/Privacy";
import Accounts from "pages/Accounts/Accounts";
import Customers from "pages/Customers/Customers";
import CustomerDetails from "pages/Customers/CustomerDetails";
import PurposeOfTransactions from "pages/Configurations/PurposeOfTransaction";
import Countrylist from "pages/Configurations/CountryList";
import CurrencyList from "pages/Configurations/CurrencyList";
import Occupations from "pages/Configurations/Occupations";
import Routes from "pages/Configurations/Routes";
import SourceOfFunds from "pages/Configurations/SourceOfFunds";
import SourceOptions from "pages/Configurations/SourceOptions";
import Partners from "pages/Configurations/Partners";
import Banks from "pages/Configurations/Banks";
import BankTypes from "pages/Configurations/BankTypes";
import StateProvince from "pages/Configurations/StateProvince";
import DeliveryOptions from "pages/Configurations/DeliveryOptions";
import DestinationOptions from "pages/Configurations/DestinationOptions";
import IdTypes from "pages/Configurations/IdTypes";
import ReceiveTransactions from "pages/ReceiveTransactions/ReceiveTransactions";
import InboundTransfers from "pages/InboundTransfers/InboundTransfers";
import Reconciliation from "pages/Reconciliation/Reconciliation";
import Rates from "pages/Rates/Rates";
import Transactions from "pages/TransactionHistory/Transactions";
import CustomerOrderDetails from "pages/Customers/CustomerOrderDetails";
import TransactionDetails from "pages/TransactionHistory/TransactionDetails";


const Loadable = (Component: any) => (props: any) => {
	// eslint-disable-next-line react-hooks/rules-of-hooks
	const { pathname } = useLocation();
	const isDashboard = pathname.includes("/dashboard");

	return (
		<Suspense
			fallback={
				<LoadingScreen
					sx={{
						...(!isDashboard && {
							top: 0,
							left: 0,
							width: 1,
							zIndex: 9999,
							position: "fixed",
						}),
					}}
				/>
			}
		>
			<Component {...props} />
		</Suspense>
	);
};

export default function Router() {
	return useRoutes([
		{
			path: "auth",
			children: [
				{
					path: "login",
					element: (
						<GuestGuard>
							<Login />
						</GuestGuard>
					),
				},
				{
					path: "register",
					element: (
						<GuestGuard>
							<Register />
						</GuestGuard>
					),
				},
				{ path: "login-unprotected", element: <Login /> },
			],
		},
		{
			path: "dashboard",
			element: (
				<AuthGuard>
					<Dashboard />
				</AuthGuard>
			),
			children: [
				{ path: "/", element: <Navigate to="/dashboard/app" replace /> },
				{ path: "app", element: <Home /> },
				{ path: "send", element: <SendMoney /> },
				{ path: "receiver", element: <Receiver /> },
				//{ path: "confirm", element: <Confirm /> },
				{ path: "recipients", element: <Recipients /> },
				{ path: "accounts", element: <Accounts /> },
				{ path: "customer", element: <Customers /> },
				{ path: "transaction", element: <Transactions /> },
				{ path: "receive-transactions", element: <ReceiveTransactions /> },
				{ path: "inbound-transfers", element: <InboundTransfers /> },
				{ path: "reconciliation", element: <Reconciliation /> },
				{ path: "rates", element: <Rates /> },
				{ path: "configurations/purpose", element: <PurposeOfTransactions /> },
				{ path: "configurations/country", element: <Countrylist /> },
				{ path: "configurations/currency", element: <CurrencyList /> },
				{ path: "configurations/occupation", element: <Occupations /> },
				{ path: "configurations/routes", element: <Routes /> },
				{ path: "configurations/source-of-funds", element: <SourceOfFunds /> },
				{ path: "configurations/source-options", element: <SourceOptions /> },
				{ path: "configurations/partners", element: <Partners /> },
				{ path: "configurations/banks", element: <Banks /> },
				{ path: "configurations/state", element: <StateProvince /> },
				{ path: "configurations/bank-types", element: <BankTypes /> },
				{ path: "configurations/deliver-options", element: <DeliveryOptions /> },
				{ path: "configurations/destination-options", element: <DestinationOptions/> },
				{ path: "configurations/id-types", element: <IdTypes/> },
				{ path: "customerDetails/:customerId", element: <CustomerDetails /> },
				{ path: "registerreceiver/:param", element: <RegisterReceiver /> },
				{ path: "mgcashpickupgengen", element: <MGCashPickupGenGen /> },
				{ path: "mgbankdepositjpyinr", element: <MGBankDepositJPYINR /> },
				{ path: "mgbankdepositjpybra", element: <MGBankDepositJPYBRA /> },
				{
					path: 'user',
					children: [
					   { path: '/', element: <Navigate to="/dashboard/user/account" replace /> },
					//   { path: 'profile', element: <UserProfile /> },
					//   { path: 'cards', element: <UserCards /> },
					//   { path: 'list', element: <UserList /> },
					//   { path: 'new', element: <UserCreate /> },
					//   { path: '/:name/edit', element: <UserCreate /> },
					  { path: 'account', element: <UserAccount /> }
					]
				  },
				  {
					path: 'help',
					children: [
					 
					   { path: 'termsandcondition', element: <TermsAndCondition /> },
					  { path: 'privacy', element: <Privacy /> }
					]
				  },
				  { path: "orderdetails", element: <CustomerOrderDetails/> },
				  { path: "transactiondetails", element: <TransactionDetails/> },
			],
		},
		
		{ path: "*", element: <Navigate to="auth/login" replace /> },
		//{ path: "*", element: <Navigate to="/404" replace /> },
		// {
		// 	path: "*",
		// 	element: <LogoOnlyLayout />,
		// 	children: [
		// 		{ path: "404", element: <NotFound /> },
		// 		{ path: "*", element: <Navigate to="/404" replace /> },
		// 	],
		// },
	]);
}

const Login = Loadable(lazy(() => import("../pages/Login/Login")));
const Dashboard = Loadable(
	lazy(() => import("../layouts/DashboardLayout"))
);
const Register = Loadable(lazy(() => import("../pages/Register/Register")));
const NotFound = Loadable(lazy(() => import("../pages/Page404")));
