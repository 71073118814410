import { map, filter } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
// utils
import axios from '../../utils/axios';
import { BoInfoType } from '../../@types/boInfoType';
import URLConstants from 'constants/urlConstants';

type BoInfoTypeState = {
    isLoading: boolean;
    error: boolean;
    boInfo: BoInfoType;
}

const initialState: BoInfoTypeState = {
    isLoading: false,
    error: false,
    boInfo: {
        "userId": 0,
        "email": "",
        "phoneNumber": "",
        "country": "",
        "language": "",
        "emailVerified": false,
        "enabled": false,
        "createdBy": null,
        "createTime": new Date(),
        "updateTime": new Date(),
        "userRoles": [
            {
                "userRoleId": 0,
                "userId": 0,
                "roleId": 0,
                "role": {
                    "roleId": 0,
                    "roleName": "",
                    "description": "",
                    "roleAuthorities": [
                        {
                            "roleAuthorityId": 0,
                            "roleId": 0,
                            "authorityId": 0,
                            "authority": {
                                "authorityId": 0,
                                "authorityName": "",
                                "description": "0"
                            }
                        }
                    ]
                }
            }
        ]
    } 
}

const slice = createSlice({
    name: 'boInfo',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        // GET BO INFO
        getBoInfoSuccess(state, action) {
            state.isLoading = false;
            state.boInfo = action.payload;
        },
    }
});

// Reducer
export default slice.reducer;


export function getBoInfo() {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(URLConstants.BO_INFO);
            dispatch(slice.actions.getBoInfoSuccess(response.data.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}