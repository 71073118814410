// material
import {
    Card,
    Table,
    TableRow,
    TableBody,
    TableCell,
    TableHead,
    TableContainer,
    Container,
    Grid,
    Button,
    Modal,
    Stack,
    TextField,
} from '@material-ui/core';
// utils
import { RootState, useDispatch, useSelector } from 'redux/store';
import { useEffect } from 'react';
import { getPartnersList } from 'redux/slices/partners';
import Page from 'components/Page';
import HeaderBreadcrumbs from 'components/HeaderBreadcrumbs';
import { LoadingButton } from '@material-ui/lab';
import { AlignHorizontalRight } from '@material-ui/icons';
import { textAlign } from '@material-ui/system';
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
import React from 'react';
import { useFormik } from 'formik';
import * as Yup from "yup";
import { makeStyles } from '@material-ui/core/styles';
import { addMoneyToWallet } from 'redux/slices/addMoneyToWallet';
import { MIconButton } from 'components/@material-extend';
import closeFill from '@iconify/icons-eva/close-fill';
import { useSnackbar } from 'notistack';
import useLocales from 'hooks/useLocales';
// ----------------------------------------------------------------------

type InitialValues = {
    katakanaName: string;
    bankCode: string;
    accountNumber: string;
    amount: string;
    remark: string;
};


function getModalStyle() {
    const top = 50;
    const left = 50;
    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

const useStyles = makeStyles(theme => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'absolute',
        verticalAlign: 'center'
    },
    paper: {
        position: 'fixed',
        width: '50%',
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(4, 4, 4),
        outline: 'none',
    },
}));
export default function InboundTransfers() {
    const { translate } = useLocales();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const dispatch = useDispatch();
    const { addMoney } = useSelector(
        (state: RootState) => state.addMoneyToWalletBO
    );

    const [open, setOpen] = React.useState(false);
    const [modalStyle] = React.useState(getModalStyle);
    const classes = useStyles();

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false)
    }


    const formik = useFormik<InitialValues>({
        initialValues: {
            katakanaName: '',
            bankCode: '',
            accountNumber: '',
            amount: '',
            remark: '',
        },

        validationSchema: Yup.object().shape({

            katakanaName: Yup.string()
                .required("*Required"),

            bankCode: Yup.string()
                .max(20, "Must be 20 characters or less")
                .required("*Required"),

            accountNumber: Yup.string()
                .required("*Required"),

            amount: Yup.string()
                .required("Enter amount"),

            remark: Yup.string()
        }),

        onSubmit: async (values) => {
            let amount = +values.amount
          let response= await dispatch(addMoneyToWallet(values.katakanaName, values.bankCode, values.accountNumber, amount, values.remark));
            if (response?.hasError) {
                enqueueSnackbar(response.message, {
                  variant: "error",
                  action: (key) => (
                    <MIconButton size="small" onClick={() => closeSnackbar(key)}>
                      <Icon icon={closeFill} />
                    </MIconButton>
                  ),
                });
              }
              else{
                setOpen(false);
                enqueueSnackbar("Added!", {
                    variant: "success",
                    action: (key) => (
                        <MIconButton size="small" onClick={() => closeSnackbar(key)}>
                            <Icon icon={closeFill} />
                        </MIconButton>
                    ),
                });
              }
       
        },

    });


    
    const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } =
        formik;

    const handleKatakanaNameValue = (e: any) => {
        formik.values.katakanaName = e.target.value
    }

    const handleBankCodeValue = (e: any) => {
        formik.values.bankCode = e.target.value
    }
    const handleAccountNumberValue = (e: any) => {
        formik.values.accountNumber = e.target.value
    }
    const handleAmountValue = (e: any) => {
        formik.values.amount = e.target.value
    }
    const handleRemarkValue = (e: any) => {
        formik.values.remark = e.target.value
    }
    return (
        <>  <Page title="Inbound Transfers">
            <Container maxWidth="xl">

                <HeaderBreadcrumbs
                    heading={translate(`dashboard.${"Inbound transfers"}`)}
                    links={[
                        // { name: 'Amount', href: PATH_DASHBOARD.user.root },
                        // { name: 'Delivery Options', href: PATH_DASHBOARD.user.root },
                    ]}
                    action={
                        <Button
                            variant="contained"
                            // component={RouterLink}
                            // to={PATH_DASHBOARD.user.newUser}
                            onClick={handleOpen}
                            startIcon={<Icon icon={plusFill} />}
                        >
                            {translate(`dashboard.${"Add Amount"}`)}
                        </Button>
                    }
                />
                <Modal
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    open={open}
                    onClose={handleClose}
                >

                    <Card style={modalStyle} className={classes.paper}>

                        <form onSubmit={formik.handleSubmit}>
                            <Stack spacing={3}>

                                <Grid item xs={12}>
                                    <TextField
                                        type="katakanaName"
                                        //name="senderFirstName"
                                        label='Katakana Name'
                                        fullWidth
                                        // {...getFieldProps("senderFirstName")}
                                        onChange={handleKatakanaNameValue}
                                        error={Boolean(touched.katakanaName && errors.katakanaName)}
                                        helperText={touched.katakanaName && errors.katakanaName}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <TextField
                                        type="bankCode"
                                        //name="middleName"
                                        label='Bank Code'
                                        fullWidth
                                         onChange={handleBankCodeValue}
                                        // {...getFieldProps("bankCode")}
                                        error={Boolean(touched.bankCode && errors.bankCode)}
                                        helperText={touched.bankCode && errors.bankCode}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <TextField
                                        type="accountNumber"
                                        // name="lastName"
                                        label="Account number"
                                        fullWidth
                                        onChange={handleAccountNumberValue}
                                        // {...getFieldProps("accountNumber")}
                                        error={Boolean(touched.accountNumber && errors.accountNumber)}
                                        helperText={touched.accountNumber && errors.accountNumber}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <TextField
                                        type="amount"
                                        label="Amount"
                                        fullWidth
                                        onChange={handleAmountValue}
                                        //  {...getFieldProps("amount")}
                                        error={Boolean(touched.amount && errors.amount)}
                                        helperText={touched.amount && errors.amount}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <TextField
                                        type="remark"
                                        label="Remark"
                                        fullWidth
                                        onChange={handleRemarkValue}
                                        // {...getFieldProps("remark")}
                                        error={Boolean(touched.remark && errors.remark)}
                                        helperText={touched.remark && errors.remark}
                                    />
                                </Grid>
                            </Stack>


                            <Grid item marginTop={5}>
                                <LoadingButton
                                    fullWidth
                                    size="large"
                                    type="submit"
                                    variant="contained"
                                    // color={formik.values.accountType && formik.values.country && formik.values.senderGender && formik.values.senderNationality && formik.values.senderOccupation && formik.values.senderFirstName && formik.values.email && formik.values.senderLastName && formik.values.phoneNumber && formik.values.password ? "primary" : "inherit"}
                                    onClick={() => formik.handleSubmit}

                                // onClick={onSubmitValues}
                                >
                                    Add
                                </LoadingButton>
                            </Grid>
                        </form>

                    </Card>

                </Modal>

            </Container>
        </Page></>
    );
}