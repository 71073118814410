import { map, filter } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
// utils
import axios from '../../utils/axios';
import { quoteAmountType } from '../../@types/quoteAmount';
import URLConstants from 'constants/urlConstants';

type QuoteState = {
    isLoading: boolean;
    error: boolean;
    quoteAmount: quoteAmountType;
}

const initialState: QuoteState = {
    isLoading: false,
    error: false,
    quoteAmount: {
        'outboundQuoteId': '',
        'senderId': 0,
        'outboundCurrency': '',
        'outboundCountry': '',
        'recvCurrency': '',
        'recvCountry': '',
        'totalOutboundSendAmount': 0,
        'outboundSendFees': 0,
        'netOutboundSendAmount': 0,
        'rate': 0,
        'recvAmount': 0,
        'recvFees': 0,
        'netRecvAmount': 0,
    },
}

const slice = createSlice({
    name: 'quoteAmount',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        // GET QUOTE
        getQuoteAmountSuccess(state, action) {
            state.isLoading = false;
            state.quoteAmount = action.payload;
        },
    }
});

// Reducer
export default slice.reducer;


export function getQuoteAmount(
    senderId: number,
    outboundCurrency: string,
    outboundCountry: string,
    recvCurrency: string,
    recvCountry: string,
    amount: number,
    quoteType: string,
) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.post(URLConstants.QUOTE_BASE_URL, {
                "senderId":senderId,
                "outboundCurrency": outboundCurrency,
                "outboundCountry": outboundCountry,
                "recvCurrency": recvCurrency,
                "recvCountry": recvCountry,
                "amount": amount,
                "quoteType": quoteType
            });
            dispatch(slice.actions.getQuoteAmountSuccess(response.data.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}