
import { capitalCase } from "change-case";
import { Link as RouterLink } from "react-router-dom";
// material
import { alpha, experimentalStyled as styled } from "@material-ui/core/styles";
import {
    Box,
    Card,
    Stack,
    Link,
    Alert,
    Tooltip,
    Container,
    Typography,
    Grid,
    Tab,
    Avatar,
    CardContent
} from "@material-ui/core";
// routes
import { PATH_AUTH, PATH_DASHBOARD } from "../../routes/paths";
// hooks
import useAuth from "../../hooks/useAuth";
// layouts
import AuthLayout from "../../layouts/AuthLayout";
// components
import Page from "../../components/Page";
import { MHidden } from "../../components/@material-extend";

import CountryServices from "services/CountryServices";
import React, { useState } from "react";
import axios from "utils/axios";
import URLConstants from "constants/urlConstants";
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import { TabContext, TabList, TabPanel } from '@material-ui/lab';
import PhoneIcon from '@material-ui/icons/Phone';
import FavoriteIcon from '@material-ui/icons/Favorite';
import PersonPinIcon from '@material-ui/icons/PersonPin';
import { Block } from 'components/Block';
import AddIcon from '@material-ui/icons/Add';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ShouldArrive from "pages/Send-Money/ShouldArrive";
import RecipientCard from "./RecipientCard";
import useLocales from "hooks/useLocales";

// ----------------------------------------------------------------------
const style = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
    '& > *': { mx: '8px !important' }
} as const;

const RootStyle = styled(Page)(({ theme }) => ({
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(15)
}));

const PERCENT = 2.6;




const RowStyle = styled('div')({
    display: 'flex',
    justifyContent: 'space-between'
});

const SIMPLE_TAB = [
    { value: '1', icon: <PhoneIcon />, label: 'Item One', disabled: false },
    { value: '2', icon: <FavoriteIcon />, label: 'Item Two', disabled: false },
    { value: '3', icon: <PersonPinIcon />, label: 'Item Three', disabled: true }
];


export default () => {
    const [value, setValue] = useState('1');
    const { translate } = useLocales();
    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };
    return (

        <>
            <Page title="Recipients">
                <Container maxWidth="xl">
                    <HeaderBreadcrumbs
                        heading={translate(`dashboard.${"Recipients"}`)}
                        links={[
                            // { name: 'Amount', href: PATH_DASHBOARD.user.root },
                            // { name: 'Delivery Options', href: PATH_DASHBOARD.user.root },
                        ]} />

                    {/* <Grid container spacing={3} >
                        <Grid item xs={12} md={4} >
                        </Grid>
                    </Grid>
                    <RecipientCard /> */}
                    <div className="in-progress-image">
                        <img
                            src="/static/illustrations/in-progress.svg"
                            alt="empty"
                            width='400px'
                        />
                    </div>
                </Container>

            </Page>

        </>
    );
};
