import { map, filter } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
// utils
import axios from '../../utils/axios';
import { receiverById } from '../../@types/receiverByIdType';
import URLConstants from 'constants/urlConstants';

type ReceiverByIdState = {
    isLoading: boolean;
    error: boolean;
    receiverById: receiverById;
}

const initialState: ReceiverByIdState = {
    isLoading: false,
    error: false,
    receiverById: {
        "receiverId": 0,
        "senderId": 0,
        "routeId": 0,
        "receiverFirstName": "",
        "receiverMiddleName": '',
        "receiverLastName": "",
        "receiverNickName": '',
        "receiverDOB": null,
        "receiverGender": null,
        "receiverNationality": null,
        "enabled": true,
        "createTime": new Date(),
        "updateTime": new Date(),
        "receiverAddress": [{
            "receiverAddressId": 0,
            "receiverId": 0,
            "receiverAddressLine1": "",
            "receiverAddressLine2": "",
            "receiverCity": "",
            "receiverStateProvince": "",
            "receiverZipCode": "",
            "receiverCountry": "",
            "createTime": new Date(),
            "updateTime": new Date(),
        }],
        "receiverIdentificationDoc": [{
            "receiverIdentificationDocId": 0,
            "receiverId": 0,
            "receiverIdType": "",
            "receiverIdNo": "",
            "receiverIdExpiry": "",
            "receiverIdCountry": "",
            "createTime": new Date(),
            "updateTime": new Date(),
        }],
        "receiverContact": [{
            "receiverContactId": 0,
            "receiverId": 0,
            "receiverPhoneNumber": "",
            "receiverEmail": "",
            "createTime": new Date(),
            "updateTime": new Date(),
        }],


        "receiverBank": [{
            "receiverBankId": 0,
            "receiverId": 0,
            "receiverSwiftCode": "",
            "receiverSwiftBIC8Code": "",
            "receiverBankIdentifier": "",
            "receiverBankName": "",
            "receiverBankCode": "",
            "receiverBranchName": "",
            "receiverBranchCode": "",
            "receiverIBAN": "",
            "receiverIFSC": "",
            "receiverAccountNumber": "",
            "receiverAccountType": "",
            "receiverVirtualPaymentAccount": "",
            "createTime":  new Date(),
            "updateTime":  new Date(),
        }],
        "receiverBankAdditional": [],
    }
}
const slice = createSlice({
    name: 'ReceiverById',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        // GET RECEIVER BY ID
        getReceiverByIdSuccess(state, action) {
            state.isLoading = false;
            state.receiverById = action.payload;
        },
    }
});

// Reducer
export default slice.reducer;


export function getReceiverById(senderId: string, receiverId: number) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(`https://6jfjr9vffh.execute-api.ap-south-1.amazonaws.com/staging/mcus/users/me/sender/${senderId}/receiver/${receiverId}`);
            dispatch(slice.actions.getReceiverByIdSuccess(response.data.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}