import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
// utils
import axios from '../../utils/axios';
import { RateType } from '../../@types/rateType';

type RateState = {
    isLoading: boolean;
    error: boolean;
    rateUSD: RateType;
}

const initialState: RateState = {
    isLoading: false,
    error: false,
    rateUSD: {
        "baseCurrency": "",
        "date": "",
        "rates": {
          value: 0
        }
    },
}

const slice = createSlice({
    name: 'rateUSD',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        // GET RATE
        getRateUSDSuccess(state, action) {
            state.isLoading = false;
            state.rateUSD = action.payload;
        },
    }
});

// Reducer
export default slice.reducer;



  
export function getUSDRates() {
  var params = {
      "fromCurrency":'USD',
    }
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`https://6jfjr9vffh.execute-api.ap-south-1.amazonaws.com/staging/mcps/api/rates/system`,{params});
      dispatch(slice.actions.getRateUSDSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


