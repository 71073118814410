import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// slices
// import mailReducer from './slices/mail';
// import chatReducer from './slices/chat';
// import blogReducer from './slices/blog';
import userReducer from './slices/user';
// import productReducer from './slices/product';
// import calendarReducer from './slices/calendar';
// import kanbanReducer from './slices/kanban';
import countryReducer from './slices/country';
import currencyReducer from './slices/currency';
import recentTransactionsReducer from './slices/recentTransactions';
import transactionsStatsReducer from './slices/transactionStats';
import quoteAmountReducer from './slices/quoteAmount';
import outboundOptionsReducer from './slices/outboundOptions';
import receiveOptionsReducer from './slices/receiveOptions';
import receiversReducer from './slices/receivers';
import routeSpecificReceiversReducer from './slices/routeSpecificReceivers';
import createRouteSpecificReceiverReducer from './slices/createNewReceiver';
import receiverByIdReducer from './slices/receiverById';
import routeBankReducer from './slices/routeBank';
import customerReducer from './slices/customer';
import purposeReducer from './slices/purpose';
import remitanceOrderReducer from './slices/remitanceOrder';
import allCustomersBOReducer from './slices/allCustomersBO';
import customerByIdBOReducer from './slices/customerByIdBO';
import searchCustomerBOReducer from './slices/searchCustomersBO';
import receiversOfCustomerReducer from './slices/receiversOfCustomerBO';
import transactionCountReducer from './slices/transactionCountBO';
import occupationReducer from './slices/occupation';
import createNewCustomerReducer from './slices/createNewCustomerBO';
import sourceOfFundsReducer from './slices/sourceOfFunds';
import routesReducer from './slices/routes';
import partnersReducer from './slices/partners';
import routeBankBOReducer from './slices/routeBankBO';
import bankTypesBOReducer from './slices/bankTypesBO';
import stateProvinceBOReducer from './slices/routeStateProvinceBO';
import deliveryOptionsBOReducer from './slices/deliveryOptions';
import identificationDocBOReducer from './slices/identificationDoc';
import addMoneyToWalletBOReducer from './slices/addMoneyToWallet';
import rateJPYBOReducer from './slices/ratesJPYBO';
import rateUSDBOReducer from './slices/rateUSDBO';
import rateEURBOReducer from './slices/rateEURBO';
import editBasicInfoBOReducer from './slices/editBasicInfoBO';
import editAddressBOReducer from './slices/editAddressBO';
import editIdentificationBOReducer from './slices/editIdentificationBO';
import editBankBOReducer from './slices/editBankBO';
import getTransactionOrderDetails from './slices/transactionOrderDetailsBO'
import recentTransactionOfCustomerBOReducer from './slices/recentTransactionOfCustomerBO'
import boInfoBOReducer from './slices/boInfoBO'
import allTransactionsBOReducer from './slices/allTransactionsBO '






// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: []
};

const productPersistConfig = {
  key: 'product',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout']
};

const rootReducer = combineReducers({
  //   mail: mailReducer,
  //   chat: chatReducer,
  //   blog: blogReducer,
  user: userReducer,
  //   calendar: calendarReducer,
  //   kanban: kanbanReducer,
  // product: persistReducer(productPersistConfig, productReducer)
  countryData: countryReducer,
  currency: currencyReducer,
  recentTransactions: recentTransactionsReducer,
  transactionsStats: transactionsStatsReducer,
  quoteAmount: quoteAmountReducer,
  outboundOptions: outboundOptionsReducer,
  receiveOptions: receiveOptionsReducer,
  receivers: receiversReducer,
  routeSpecificReceivers: routeSpecificReceiversReducer,
  createRouteSpecificReceiver: createRouteSpecificReceiverReducer,
  receiverById: receiverByIdReducer,
  routeBank: routeBankReducer,
  customer: customerReducer,
  purpose: purposeReducer,
  remitanceOrder: remitanceOrderReducer,

  allCustomers: allCustomersBOReducer,
  customerById: customerByIdBOReducer,
  searchCustomer: searchCustomerBOReducer,
  receiversOfCustomer: receiversOfCustomerReducer,
  transactionCount: transactionCountReducer,
  occupation: occupationReducer,
  createNewCustomer: createNewCustomerReducer,
  sourceOfFunds: sourceOfFundsReducer,
  routes: routesReducer,
  partners: partnersReducer,
  routeBankBO: routeBankBOReducer,
  bankTypesBO: bankTypesBOReducer,
  stateProvinceBO: stateProvinceBOReducer,
  deliveryOptionsBO: deliveryOptionsBOReducer,
  identificationDocBO: identificationDocBOReducer,
  addMoneyToWalletBO: addMoneyToWalletBOReducer,
  rateJPYBO: rateJPYBOReducer,
  rateUSDBO: rateUSDBOReducer,
  rateEURBO: rateEURBOReducer,
  editBasicInfoBO:editBasicInfoBOReducer,
  editAddressBO:editAddressBOReducer,
  editIdentificationBO:editIdentificationBOReducer,
  editBankBO:editBankBOReducer,
  transactionOrderDetails : getTransactionOrderDetails,
  recentTransactionOfCustomerBO:recentTransactionOfCustomerBOReducer,
  boInfo:boInfoBOReducer,
  allTransactions:allTransactionsBOReducer
});

export { rootPersistConfig, rootReducer };
